import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Acon3dBanner = {
  __typename?: 'Acon3dBanner';
  /** 배너 등록일자 (복수 배너시 정렬용) */
  created?: Maybe<Scalars['DateTime']>;
  /** 상품 링크 */
  href?: Maybe<Scalars['String']>;
  /** 배너 ID */
  id?: Maybe<Scalars['Int']>;
  /** 배너 이미지 주소 */
  imageUrl?: Maybe<Scalars['String']>;
  /** 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 표시 타입 */
  type?: Maybe<Scalars['String']>;
};

export type Acon3dBrand = {
  __typename?: 'Acon3dBrand';
  /** 브랜드 코드 */
  code?: Maybe<Scalars['String']>;
  /** 브랜드 ID */
  id?: Maybe<Scalars['Int']>;
  /** 브랜드 이름(번역) */
  name?: Maybe<Scalars['String']>;
};

export type Acon3dCategory = {
  __typename?: 'Acon3dCategory';
  /** 고도 카테고리 코드 */
  code?: Maybe<Scalars['String']>;
  /** 카테고리 ID */
  id?: Maybe<Scalars['Int']>;
  /** 카테고리 이름(번역) */
  name?: Maybe<Scalars['String']>;
};

export type Acon3dFunding = {
  __typename?: 'Acon3dFunding';
  /** 펀딩 상품 전달일자 */
  deliveryDate: Scalars['DateTime'];
  /** 펀딩 종료일자 */
  endDate: Scalars['DateTime'];
  /** 펀딩 목표 금액 */
  goalAmount: Scalars['Int'];
  /** 펀딩 누적판매 금액 */
  soldAmount?: Maybe<Scalars['Int']>;
  /** 펀딩 판매량(참여자 수) */
  soldVolume?: Maybe<Scalars['Int']>;
  /** 펀딩 시작일자 */
  startDate: Scalars['DateTime'];
  status: FundingStatus;
};

export type Acon3dLinkedProduct = {
  __typename?: 'Acon3dLinkedProduct';
  /** 확장자 */
  extensions: Array<Scalars['String']>;
  /** 상품 번호 */
  id: Scalars['Int'];
};

export type Acon3dModelConfig = {
  __typename?: 'Acon3dModelConfig';
  /** 모델컨피그 ID */
  id?: Maybe<Scalars['Int']>;
  /** 이름(번역) */
  name?: Maybe<Scalars['String']>;
};

export type Acon3dPackage = {
  __typename?: 'Acon3dPackage';
  children: Array<Acon3dProduct>;
  parent?: Maybe<Acon3dProduct>;
  type: Scalars['String'];
};

export type Acon3dProduct = {
  __typename?: 'Acon3dProduct';
  /** 상품의 응용프로그램들 */
  applications?: Maybe<Array<Application>>;
  /** 상품카드 뱃지 이름들(번역) */
  badgeNames: Array<Maybe<Scalars['String']>>;
  banners?: Maybe<Array<Acon3dBanner>>;
  brand: Acon3dBrand;
  categories?: Maybe<Array<Acon3dCategory>>;
  content?: Maybe<Scalars['String']>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 상품상세 이미지 URL */
  detailImageUrl?: Maybe<Scalars['String']>;
  /** 세트 상품을 허브에 정보 전달해주기 위한 docId */
  docId?: Maybe<Scalars['String']>;
  /** 확장자 */
  extensions: Array<Scalars['String']>;
  /** 펀딩 정보. null이면 펀딩 대상이 아님 */
  funding?: Maybe<Acon3dFunding>;
  /** 유저가 상품을 주문한 적이 있는지 여부 */
  hasOrdered?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** ACON ONLY 여부 */
  isAconOnly: Scalars['Boolean'];
  /** 세일 여부 */
  isOnSale: Scalars['Boolean'];
  /** 언어 */
  languageCode: LanguageCodeEnum;
  /** 연관 상품, product_related 테이블 */
  linkedProducts?: Maybe<Array<Acon3dLinkedProduct>>;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String'];
  modelConfigs?: Maybe<Array<Acon3dModelConfig>>;
  /** 리스트 노출 여부 */
  onDisplay: Scalars['Boolean'];
  /** 판매 여부 */
  onSale: Scalars['Boolean'];
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int'];
  /** 세트 상품 정보. null이면 세트 상품에 해당되지 않음 */
  packages?: Maybe<Array<Maybe<Acon3dPackage>>>;
  /** 상품 정가 */
  price: Scalars['Int'];
  /** 진행 중인 프로모션 정보 */
  promotionEndDate?: Maybe<Scalars['DateTime']>;
  /** 우주최저가/혜성특가 등의 특수 프로모션에 해당될 경우 요청하는 필드 */
  promotions?: Maybe<Array<Acon3dPromotion>>;
  /** 상품 정가 (개정) */
  regularPrice?: Maybe<Scalars['Int']>;
  /** 리뷰 수 */
  reviewCount: Scalars['Int'];
  /** 상품 할인가 */
  salePrice: Scalars['Int'];
  /** 검색 일치 점수 */
  score?: Maybe<Scalars['Float']>;
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String'];
  /** 번역된 상품명 */
  title: Scalars['String'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']>;
  /** 조회 수 */
  viewCount: Scalars['Int'];
};

export type Acon3dPromotion = {
  __typename?: 'Acon3dPromotion';
  /** 프로모션 뱃지(번역본) */
  badges?: Maybe<Array<Acon3dPromotionBadge>>;
  /** 현재 차수 */
  currentRound?: Maybe<Scalars['Int']>;
  /** 프로모션 아이디(acon.promotion) */
  id: Scalars['Int'];
  /** 남은 일수 */
  remainingDays?: Maybe<Scalars['Int']>;
  /** 혜택 라운드별 상세 정보 */
  rounds?: Maybe<Array<Acon3dPromotionRound>>;
  /** 시작일자(예정 포함), acon.promotion.reserve_date */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type Acon3dPromotionBadge = {
  __typename?: 'Acon3dPromotionBadge';
  /** 프로모션 뱃지 내용 */
  content?: Maybe<Scalars['String']>;
  /** 프로모션 뱃지 ID */
  id?: Maybe<Scalars['Int']>;
  /** 프로모션 뱃지 언어 */
  languageCode?: Maybe<LanguageCodeEnum>;
  /** 프로모션 뱃지 타입: point | coupon | null */
  type?: Maybe<Scalars['String']>;
};

export type Acon3dPromotionRound = {
  __typename?: 'Acon3dPromotionRound';
  /** 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  roundNo?: Maybe<Scalars['Int']>;
  /** 할인가 */
  salePrice?: Maybe<Scalars['Int']>;
  /** 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type AconNotification = {
  __typename?: 'AconNotification';
  /** acon notification 고유번호 */
  id: Scalars['Int'];
  /** 고도몰 회원번호 */
  memNo: Scalars['Int'];
  /** notification message */
  message?: Maybe<Scalars['String']>;
  /** notification type */
  type: Scalars['String'];
};

export type AconSnsMemberInput = {
  accessToken: Scalars['String'];
  /** y or n */
  agreementInfoFl?: InputMaybe<Scalars['String']>;
  cellPhone?: InputMaybe<Scalars['String']>;
  countryName?: InputMaybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  /** y or n */
  maillingFl?: InputMaybe<Scalars['String']>;
  /** y or n */
  privateApprovalFl?: InputMaybe<Scalars['String']>;
  relatedJob?: InputMaybe<Array<Scalars['String']>>;
  /** y or n */
  smsFl?: InputMaybe<Scalars['String']>;
  snsType: SnsTypeEnum;
};

export type AdminMultiDocument = {
  __typename?: 'AdminMultiDocument';
  assetId?: Maybe<Scalars['Int']>;
  brandName: Scalars['String'];
  /** 최종 상태변환 일시 */
  changed?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** 아직 담당자가 지정되지 않았을 수 있으므로 nullable */
  manager?: Maybe<Scalars['String']>;
  /** 노출 시작 시각. null이면 미노출 */
  onViewFrom?: Maybe<Scalars['DateTime']>;
  /** 검토/수정요청 일시 */
  requested?: Maybe<Scalars['DateTime']>;
  status: Document_Status;
  titles: Array<DocumentTitle>;
  /** 전체 다큐먼트 수 */
  total: Scalars['Int'];
};

export type AdminSingleDocumentInput = {
  applications?: InputMaybe<Array<Scalars['Int']>>;
  /** 고도몰상품번호 */
  assetId?: InputMaybe<Scalars['Int']>;
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<SingleDocumentCategoriesInput>;
  changeHistories?: InputMaybe<Array<InputMaybe<DocumentChangeHistoryInput>>>;
  completed?: InputMaybe<Scalars['DateTime']>;
  contentBody?: InputMaybe<Scalars['String']>;
  contentHead?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<DocumentCopyrightInput>;
  created?: InputMaybe<Scalars['DateTime']>;
  extensions?: InputMaybe<Array<Scalars['Int']>>;
  fileComponents?: InputMaybe<Array<InputMaybe<DocumentFileComponentInput>>>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileUpdates?: InputMaybe<Array<InputMaybe<DocumentFileUpdateInput>>>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Language_Code>;
  mCompleted?: InputMaybe<Scalars['DateTime']>;
  mRequested?: InputMaybe<Scalars['DateTime']>;
  mainImage?: InputMaybe<Scalars['String']>;
  memos?: InputMaybe<Array<InputMaybe<DocumentMemoInput>>>;
  message?: InputMaybe<Scalars['String']>;
  originalLanguage?: InputMaybe<Language_Code>;
  price?: InputMaybe<Scalars['Float']>;
  priceChanged?: InputMaybe<Scalars['DateTime']>;
  requested?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Document_Status>;
  subImage?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type AdminSingleDocumentOutput = {
  __typename?: 'AdminSingleDocumentOutput';
  applications?: Maybe<Array<Scalars['Int']>>;
  /** 고도몰상품번호 */
  assetId?: Maybe<Scalars['Int']>;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Int']>;
  brandNames?: Maybe<Array<Maybe<SingleDocumentBrandName>>>;
  categories?: Maybe<SingleDocumentCategoriesOutput>;
  changeHistories?: Maybe<Array<Maybe<DocumentChangeHistoryOutput>>>;
  completed?: Maybe<Scalars['DateTime']>;
  contentBody?: Maybe<Scalars['String']>;
  contentHead?: Maybe<Scalars['String']>;
  copyright?: Maybe<DocumentCopyrightOutput>;
  created?: Maybe<Scalars['DateTime']>;
  extensions?: Maybe<Array<Scalars['Int']>>;
  fileComponents?: Maybe<Array<Maybe<DocumentFileComponentOutput>>>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  fileUpdates?: Maybe<Array<Maybe<DocumentFileUpdateOutput>>>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  language?: Maybe<Language_Code>;
  mCompleted?: Maybe<Scalars['DateTime']>;
  mRequested?: Maybe<Scalars['DateTime']>;
  mainImage?: Maybe<Scalars['String']>;
  memos?: Maybe<Array<Maybe<DocumentMemoOutput>>>;
  message?: Maybe<Scalars['String']>;
  originalLanguage?: Maybe<Language_Code>;
  price?: Maybe<Scalars['Float']>;
  priceChanged?: Maybe<Scalars['DateTime']>;
  requested?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Document_Status>;
  subImage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  created?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type AdminUserModel = {
  __typename?: 'AdminUserModel';
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 관리자 이메일 */
  email: Scalars['String'];
  /** 고유 ID */
  id: Scalars['String'];
  /** 관리자 명 */
  name: Scalars['String'];
  /** 수정 일자 */
  updated: Scalars['DateTime'];
};

export type Application = {
  __typename?: 'Application';
  /** 응용프로그램 ID */
  id: Scalars['Int'];
  /** 응용프로그램 이름 */
  name: Scalars['String'];
};

export type ApplyItems = {
  __typename?: 'ApplyItems';
  /** 혜택 적용 설정 값 */
  value?: Maybe<Scalars['String']>;
};

export type ApplyType = {
  __typename?: 'ApplyType';
  /** 혜택 적용 설정 코드 */
  code?: Maybe<Scalars['String']>;
  /** 혜택 적용 설정 코드 ID */
  id?: Maybe<Scalars['Float']>;
  /** 혜택 적용 설정 코드 명 */
  name?: Maybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  brand: BrandEntity;
  extensions: Array<Scalars['Int']>;
  i18n: AssetI18n;
  i18ns: Array<AssetI18n>;
  id: Scalars['Int'];
  onViewFrom?: Maybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  salePrice: Scalars['Float'];
  title: Scalars['String'];
};

export type AssetI18n = {
  __typename?: 'AssetI18n';
  copyright: DocumentCopyrightOutput;
  fileComponents: Array<AssetI18nFileComponent>;
  fileUpdates: Array<AssetI18nFileUpdate>;
  language: Language_Code;
  title: Scalars['String'];
};

export type AssetI18nFileComponent = {
  __typename?: 'AssetI18nFileComponent';
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
};

export type AssetI18nFileUpdate = {
  __typename?: 'AssetI18nFileUpdate';
  date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AuthInfoInput = {
  account: Scalars['String'];
  password: Scalars['String'];
};

export type AuthType = {
  __typename?: 'AuthType';
  type: Scalars['String'];
};

export type Badge = {
  /** 제공 혜택 금액 (EN) */
  enAmount: Scalars['String'];
  /** 제공 혜택 금액 (JA) */
  jaAmount: Scalars['String'];
  /** 제공 혜택 금액 (KO) */
  koAmount: Scalars['String'];
  /** 타입(point, coupon) */
  type: Scalars['String'];
  /** 제공 혜택 금액 (ZH) */
  zhAmount: Scalars['String'];
};

export type BadgeI18n = {
  __typename?: 'BadgeI18n';
  /** 언어 코드 */
  lang: Scalars['String'];
  /** language table 고유번호 */
  langId?: Maybe<Scalars['Float']>;
  /** 뱃지 내용 */
  text: Scalars['String'];
};

export type BadgeI18nInput = {
  /** 언어 코드 */
  lang: Scalars['String'];
  /** 뱃지 내용 */
  text: Scalars['String'];
};

export type BadgeI18nModel = {
  __typename?: 'BadgeI18nModel';
  /** badge table 고유번호 */
  badgeId: Scalars['Float'];
  /** 뱃지 고유 ID */
  id: Scalars['Float'];
  /** language table 고유번호 */
  langId: Scalars['Float'];
  language?: Maybe<Language>;
  /** 뱃지 내용 */
  text: Scalars['String'];
};

export type BadgeProductModel = {
  __typename?: 'BadgeProductModel';
  /** badge table 고유번호 */
  badgeId: Scalars['Float'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['String'];
  /** 뱃지 고유 ID */
  id: Scalars['Float'];
};

export type BadgeReturn = {
  __typename?: 'BadgeReturn';
  /** 뱃지 노출날짜 : 끝날짜 */
  endDate: Scalars['DateTime'];
  /** 고도몰 상품 번호 배열 */
  godoGoodsNos: Array<Scalars['String']>;
  /** badge table 고유번호 */
  id?: Maybe<Scalars['Float']>;
  /** 자동 생성 여부 */
  isAutoCreate?: Maybe<Scalars['Boolean']>;
  /** 뱃지 노출날짜 : 시작날짜 */
  startDate: Scalars['DateTime'];
  /** 상태 */
  status?: Maybe<Scalars['String']>;
  /** 상품 뱃지 문구 */
  texts: Array<BadgeI18n>;
};

export type BandBanner = {
  __typename?: 'BandBanner';
  /** 배경 색 */
  backgroundColor: Scalars['String'];
  /** 닫기 색 */
  closeColor: Scalars['String'];
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 띠 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 띠 배너 클릭시 새창 여부 */
  isBlankHref: Scalars['Boolean'];
  /** 띠 배너 종료 노출 타입 */
  isClosedShow: BandBannerClosed;
  /** 배너를 표시할 다국어몰 */
  language?: Maybe<Language>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 띠 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 띠 배너 타입 */
  type?: Maybe<BandBannerType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type BandBannerClosed = {
  __typename?: 'BandBannerClosed';
  /** 띠 배너 종료 노출 타입 코드 */
  code: Scalars['String'];
  /** 띠 배너 종료 노출 타입 ID */
  id: Scalars['Float'];
  /** 띠 배너 종료 노출 타입 명 */
  name: Scalars['String'];
};

export type BandBannerInfo = {
  __typename?: 'BandBannerInfo';
  /** 배경색상 */
  backgroundColor: Scalars['String'];
  /** 종료버튼색상 */
  closeButtonColor?: Maybe<Scalars['String']>;
  /** 종료일자 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 기간 마감 여부 */
  isPeriod: Scalars['Boolean'];
  /** 링크 url */
  linkUrl: Scalars['String'];
  /** 배너문구 */
  title: Scalars['String'];
};

export type BandBannerItems = {
  __typename?: 'BandBannerItems';
  /** 배경 색 */
  backgroundColor: Scalars['String'];
  /** 닫기 색 */
  closeColor: Scalars['String'];
  /** 종료 노출 타입 */
  closedShow: Scalars['String'];
  /** 종료일자 */
  endDate: Scalars['DateTime'];
  /** 링크 URL */
  href: Scalars['String'];
  /** 띠 배너 텍스트 */
  id: Scalars['Int'];
  /** 새창 띄우기 여부 */
  isBlank: Scalars['Boolean'];
  /** 시작일자 */
  startDate: Scalars['DateTime'];
  /** 띠 배너 텍스트 */
  text: Scalars['String'];
};

export type BandBannerType = {
  __typename?: 'BandBannerType';
  /** 띠 배너 타입 코드 */
  code: Scalars['Float'];
  /** 띠 배너 타입 ID */
  id: Scalars['Float'];
  /** 띠 배너 타입 명 */
  name: Scalars['String'];
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFile>;
  /** 배너를 표시할 다국어몰 */
  language?: Maybe<Language>;
  /** 배너를 표시 또는 숨길 상품들 */
  selectedProducts?: Maybe<Array<Product>>;
  /** 선택된 상품만 표시 또는 선택된 상품만 숨기기 */
  showOnlyForSelectedProducts?: Maybe<Scalars['Boolean']>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 배너타입(표시위치) */
  type?: Maybe<BannerType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type BannerClone = {
  __typename?: 'BannerClone';
  /** 배너를 표시 또는 숨길 상품들 */
  assets?: Maybe<Array<Asset>>;
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFileClone>;
  languageCode: LanguageCodeEnum;
  /** 선택된 상품만 표시 또는 선택된 상품만 숨기기 */
  showOnlyForSelectedProducts?: Maybe<Scalars['Boolean']>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 배너타입(표시위치) */
  type?: Maybe<BannerTypeClone>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type BannerInfo = {
  __typename?: 'BannerInfo';
  /** 배너 목록 */
  bannerList: Array<BannerItem>;
  /** 슬라이드 효과 */
  effect: Scalars['String'];
  /** 배너 높이 */
  height: Scalars['Int'];
  /** 네비게이션 버튼 여부 */
  isNaviButton: Scalars['Boolean'];
  /** 배너 사이즈에 따른 px or % (true : px, false: %) */
  isPx: Scalars['Boolean'];
  /** 사이드 버튼 여부 */
  isSideButton: Scalars['Boolean'];
  /** 네비게이션 버튼 활성화 되었을 때의 색상 */
  naviButtonActiveColor: Scalars['String'];
  /** 네비게이션 버튼 비활성화 되었을 때의 색상 */
  naviButtonColor: Scalars['String'];
  /** 네비게이션 버튼 크기 */
  naviButtonSize: Scalars['Int'];
  /** 네비게이션 버튼 유형 */
  naviButtonType: Scalars['String'];
  /** 사이드 버튼 색상 */
  sideButtonColor: Scalars['String'];
  /** 슬라이드 속도 */
  speed: Scalars['Int'];
  /** 슬라이드 시간 */
  time: Scalars['Int'];
  /** 배너 너비 */
  width: Scalars['Int'];
};

export type BannerItem = {
  __typename?: 'BannerItem';
  /** 색상 */
  color?: Maybe<Scalars['String']>;
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** a 태그 옵션의 _blank 여부 */
  isBlank: Scalars['Boolean'];
  /** 링크 URL */
  linkUrl: Scalars['String'];
  /** 기기 타입 */
  type: Scalars['String'];
};

export type BannerType = {
  __typename?: 'BannerType';
  /** 배너 타입 코드(b1, b2, ...) */
  code: Scalars['String'];
  /** 배너 안에 표시되는 내용의 타입(text, image, ...) */
  displayType: Scalars['String'];
  /** 배너 타입 id */
  id: Scalars['Float'];
  /** 배너 타입에 따른 표시 위치 */
  name: Scalars['String'];
};

export type BannerTypeClone = {
  __typename?: 'BannerTypeClone';
  /** 배너 타입 코드(b1, b2, ...) */
  code: Scalars['String'];
  /** 배너 안에 표시되는 내용의 타입(text, image, ...) */
  displayType: Scalars['String'];
  /** 배너 타입 id */
  id: Scalars['Int'];
  /** 배너 타입에 따른 표시 위치 */
  name: Scalars['String'];
};

export type BenefitApply = {
  __typename?: 'BenefitApply';
  /** 포인트 혜택 적용 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 적용 타입 명 */
  name: Scalars['String'];
};

export type BenefitApplyClone = {
  __typename?: 'BenefitApplyClone';
  /** 포인트 혜택 적용 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 적용 타입 명 */
  name: Scalars['String'];
};

export type BenefitBurden = {
  __typename?: 'BenefitBurden';
  /** 부담도 */
  burden?: Maybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** sno */
  sno: Scalars['String'];
};

export type BenefitLimit = {
  __typename?: 'BenefitLimit';
  /** 브랜드 */
  brand?: Maybe<Brand>;
  /** 브랜드 id */
  brandId?: Maybe<Scalars['Float']>;
  /** 카테고리 */
  category?: Maybe<Category>;
  /** 카테고리 */
  categoryId?: Maybe<Scalars['Float']>;
  /** 제한 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 상품 번호들 */
  goodsNo?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 제한 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 구분 (brand, category, goods) */
  type: Scalars['String'];
};

export type BenefitList = {
  __typename?: 'BenefitList';
  /** 할인률 */
  discountRate?: Maybe<Scalars['Int']>;
  /** 종료 일자 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 차수 */
  roundNo?: Maybe<Scalars['Int']>;
  /** 할인 가격 */
  salePrice?: Maybe<Scalars['String']>;
  /** 시작 일자 */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type BenefitType = {
  __typename?: 'BenefitType';
  /** 포인트 혜택 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 타입 명 */
  name: Scalars['String'];
};

export type BenefitTypeClone = {
  __typename?: 'BenefitTypeClone';
  /** 포인트 혜택 타입 코드 */
  code: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 혜택 타입 명 */
  name: Scalars['String'];
};

export enum BestProductType {
  All = 'all',
  Day = 'day',
  Month = 'month',
  Week = 'week'
}

export type Brand = {
  __typename?: 'Brand';
  /** 고도몰 브랜드코드 */
  godoBrandCd?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<Array<BrandI18n>>;
  name: Scalars['String'];
  /** 상품 타입 */
  productType?: Maybe<ProductType>;
};


export type BrandItemArgs = {
  langCode?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type BrandCategoryReturn = {
  __typename?: 'BrandCategoryReturn';
  code: Scalars['String'];
  isLink: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type BrandClone = {
  __typename?: 'BrandClone';
  brandPickIds: Array<Scalars['Int']>;
  godoBrandCd?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  useBrandPick: Scalars['Boolean'];
};

export type BrandEntity = {
  __typename?: 'BrandEntity';
  godoBrandCd?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type BrandI18n = {
  __typename?: 'BrandI18n';
  /** 부모 브랜드 ID  */
  brandId: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  name: Scalars['String'];
};

export type BrandPick = {
  __typename?: 'BrandPick';
  assets: Array<Asset>;
  isOn: Scalars['Boolean'];
};

export type BrandProductsWithCategoriesOutput = {
  __typename?: 'BrandProductsWithCategoriesOutput';
  /** 카테고리 정보 */
  categories: Array<Acon3dCategory>;
  /** 상품 번호(ProductNo) */
  id: Scalars['Int'];
};

export type CancelInquiryInput = {
  /** Inquiry ID */
  id: Scalars['Int'];
};

export type CartInfo = {
  __typename?: 'CartInfo';
  /** 중복 상품 여부 */
  duplicationGoods: Scalars['Boolean'];
  /** 상품 이미지 */
  goodsImage: Scalars['String'];
  /** 상품 명 */
  goodsNm: Scalars['String'];
  /** 상품 번호 */
  goodsNo: Scalars['String'];
  /** 중복 구매 상품 여부 */
  isOverlap: Scalars['Boolean'];
  /** 회원 할인 정보 */
  memberDcInfo: Scalars['String'];
  /** 포인트 정보 */
  mileageInfo: Scalars['String'];
  /** 옵션 번호 */
  optionSno: Scalars['String'];
  /** 구매 불가 여부 */
  orderPossible: Scalars['Boolean'];
  /** 결제 수단 설정 여부 */
  payLimitFl: Scalars['Boolean'];
  /** 상품 금액 정보 */
  price: OrderPrice;
  /** 금액 정보 */
  priceInfo: Scalars['String'];
  product?: Maybe<Acon3dProduct>;
  /** 장바구니 일련번호 */
  sno: Scalars['String'];
};

export type CartItem = {
  __typename?: 'CartItem';
  /** 브랜드 코드 */
  brandCd: Scalars['String'];
  /** 브랜드 명 */
  brandName: Scalars['String'];
  /** 확장자들 */
  extensions: Array<Scalars['String']>;
  /** 상품번호 */
  goodsNo: Scalars['Int'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 리스트 표시 여부 * 신규추가 */
  isEndSales: Scalars['Boolean'];
  /** 주문 여부 */
  isOrder: Scalars['Boolean'];
  /** 판매 여부 */
  isSale: Scalars['Boolean'];
  /** 지급되어야 할 최대 포인트 */
  point: Scalars['Int'];
  /** 판매가 */
  price: Scalars['Int'];
  /** 정가 */
  realPrice: Scalars['Int'];
  /** sno */
  sno: Scalars['Int'];
  /** 상품 제목 */
  title: Scalars['String'];
};

export type CartOutput = {
  __typename?: 'CartOutput';
  productNo: Scalars['Int'];
};

export type CashItem = {
  __typename?: 'CashItem';
  /** 잔여 캐시 액수 */
  afterDeposit: Scalars['Int'];
  /** 캐시 지급 내용 */
  contents: Scalars['String'];
  /** 캐시 지급 액수 */
  deposit: Scalars['Int'];
  /** 결제 수단 */
  payMethod?: Maybe<Scalars['String']>;
  /** 캐시 지급 일자 */
  regDate: Scalars['String'];
  /** 결제 상태 */
  status?: Maybe<Scalars['String']>;
  /** 가상계좌 정보 */
  vBank?: Maybe<VBank>;
};

export type CashList = {
  __typename?: 'CashList';
  /** 에이콘 캐시 내역 목록 */
  items: Array<CashItem>;
  /** 에이콘 캐시 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  creator?: Maybe<Scalars['Float']>;
  godoCateCd?: Maybe<Scalars['String']>;
  godoSno?: Maybe<Scalars['Float']>;
  i18n: Array<CategoryI18n>;
  /** 카테고리 고유 ID */
  id: Scalars['Float'];
  memo?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['Float']>;
  sortNo?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  /** 대분류 */
  type: CategoryTypeEnum;
  updater?: Maybe<Scalars['Float']>;
};

export type CategoryAtom = {
  __typename?: 'CategoryAtom';
  /** 카테고리 코드 */
  code: Scalars['String'];
  /** 카테고리 이름 */
  name: Scalars['String'];
};

export type CategoryClone = {
  __typename?: 'CategoryClone';
  /** 등록 관리자 ID */
  creator?: Maybe<Scalars['Int']>;
  /** 고도몰 카테고리 코드 */
  godoCateCd?: Maybe<Scalars['String']>;
  /** 고도몰 카테고리 sno(시리얼 넘버) */
  godoSno?: Maybe<Scalars['Int']>;
  i18n: CategoryI18nClone;
  /** 카테고리 번역 상세 */
  i18ns?: Maybe<Array<CategoryI18nClone>>;
  id: Scalars['Int'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 부모 카테고리 ID */
  parent?: Maybe<Scalars['Int']>;
  /** 정렬용 번호 */
  sortNo?: Maybe<Scalars['Int']>;
  /** 상태: liveon/standby */
  state: Scalars['String'];
  /** 대분류: toonstyle/realistic */
  type: CategoryTypeEnum;
  /** 갱신 관리자 ID */
  updater?: Maybe<Scalars['Int']>;
};

export type CategoryGoods = {
  cateCd: Scalars['String'];
  goodsNo: Array<Scalars['Float']>;
};

export type CategoryI18n = {
  __typename?: 'CategoryI18n';
  anchor?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['Float']>;
  file?: Maybe<CategoryI18nFile>;
  /** 고유 ID */
  id: Scalars['Float'];
  isBlankAnchor?: Maybe<Scalars['Float']>;
  isShowResultCount: Scalars['Float'];
  language?: Maybe<Language>;
  languageCode: Scalars['String'];
  map?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updater?: Maybe<Scalars['Float']>;
};

export type CategoryI18nClone = {
  __typename?: 'CategoryI18nClone';
  /** 허브 카테고리 배너 경로: 링크 */
  anchor?: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  /** 허브 카테고리 배너 색상 */
  color?: Maybe<Scalars['String']>;
  /** 등록 관리자 ID */
  creator?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** 링크 새 창으로 열기 여부 */
  isBlankAnchor?: Maybe<Scalars['Boolean']>;
  /** 상품 목록 결과 표시 여부 */
  isShowResultCount: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  /** 허브 카테고리 배너 경로: 이미지맵 */
  map?: Maybe<Scalars['String']>;
  /** 카테고리 이름(번역) */
  name: Scalars['String'];
  /** 갱신 관리자 ID */
  updater?: Maybe<Scalars['Int']>;
};

export type CategoryI18nFile = {
  __typename?: 'CategoryI18nFile';
  categoryI18nId: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  uploadFile?: Maybe<UploadFile>;
  uploadFileId: Scalars['Float'];
};

export type CategoryMolecule = {
  __typename?: 'CategoryMolecule';
  /** 1차 카테고리 */
  primary: CategoryAtom;
  /** 2차 카테고리  */
  secondaries: Array<CategoryAtom>;
};

export type CategoryOutput = {
  __typename?: 'CategoryOutput';
  code: Scalars['String'];
  name: Scalars['String'];
  /** 하위 카테고리 */
  subs?: Maybe<Array<Maybe<CategoryOutput>>>;
};

export type CategoryOutputV2 = {
  __typename?: 'CategoryOutputV2';
  categories: Array<CategoryMolecule>;
  /** 카테고리 분류 장르별=GENRE, 주제별=THEME */
  type: Scalars['String'];
};

export enum CategoryTypeEnum {
  Realistic = 'realistic',
  Toonstyle = 'toonstyle'
}

/** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
export type ChangePenNameInput = {
  /** null | personToTeam | teamToPerson */
  changeType?: InputMaybe<ChangeTypeEnum>;
  /** 기존 필명으로 작품을 사용함 여부 */
  isOldPenNameRetained?: InputMaybe<Scalars['Boolean']>;
  /** 필명/작품명 */
  penName: Scalars['String'];
  /** 팀/회사명 */
  teamName?: InputMaybe<Scalars['String']>;
};

export enum ChangeTypeEnum {
  PersonToTeam = 'personToTeam',
  TeamToPerson = 'teamToPerson'
}

export enum CheckAnswerType {
  ModifyContent = 'modifyContent',
  ModifyContentAnswer = 'modifyContentAnswer',
  Reject = 'reject'
}

export type CheckerAnswer = {
  __typename?: 'CheckerAnswer';
  content: Scalars['String'];
  projectId: Scalars['Int'];
  type: CheckAnswerType;
};

export type CheckerAnswerInput = {
  content: Scalars['String'];
  /** reject: 반려, modifyContent: 수정내용, modifyContentAnswer: 수정내용 답변 */
  type: CheckAnswerType;
};

export type Clip = {
  __typename?: 'Clip';
  created?: Maybe<Scalars['DateTime']>;
  godoGoodsNo: Scalars['Int'];
  godoMemNo: Scalars['Int'];
  id: Scalars['Int'];
};

export type ClipInquiryInput = {
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
  /** Is currently clipped */
  isClipped: Scalars['Boolean'];
};

export type Content = {
  __typename?: 'Content';
  contentImages?: Maybe<Array<ContentImage>>;
  /** 프로젝트 상세설명(상품 페이지 상세설명) */
  contents: Scalars['String'];
  copyright?: Maybe<Scalars['String']>;
  /** 프로젝트 부가설명(상품 페이지 부가설명) */
  description?: Maybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  projectId: Scalars['Int'];
  searchKeyword: Scalars['String'];
  title: Scalars['String'];
};

export type ContentImage = {
  __typename?: 'ContentImage';
  contentLanguageCode: LanguageCodeEnum;
  contentProjectId: Scalars['Int'];
  /** 허브 상품 이미지 크롭기능 높이값 */
  cropHeight?: Maybe<Scalars['Int']>;
  /** 허브 상품 이미지 크롭기능 너비값 */
  cropWidth?: Maybe<Scalars['Int']>;
  /** 허브 상품 이미지 크롭기능 x 좌표 */
  cropX?: Maybe<Scalars['Int']>;
  /** 허브 상품 이미지 크롭기능 y 좌표 */
  cropY?: Maybe<Scalars['Int']>;
  type: ProjectImageEnum;
  uploadFile?: Maybe<UploadFileClone>;
};

export type ContentImageInput = {
  contentId?: InputMaybe<Scalars['Int']>;
  cropInfo?: InputMaybe<ImageCropInput>;
  type: ProjectImageEnum;
  uploadImage?: InputMaybe<UploadFileInput>;
};

export type ContentInput = {
  contents?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<ContentImageInput>>;
  searchKeyword?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CouponApplyCategory = {
  __typename?: 'CouponApplyCategory';
  /** 카테고리 명 */
  name: Scalars['String'];
  /** 카테고리 번호 */
  no: Scalars['String'];
};

export type CouponApplyGoods = {
  __typename?: 'CouponApplyGoods';
  /** 상품명 */
  goodsNm: Scalars['String'];
  /** 상품 번호 */
  goodsNo: Scalars['String'];
};

export type CouponBanner = {
  __typename?: 'CouponBanner';
  /** 조건1 */
  conditionFirst: Scalars['String'];
  /** 조건2 */
  conditionSecond: Scalars['String'];
  /** 쿠폰 번호 */
  couponNo: Scalars['Int'];
  /** 노출 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFile>;
  /** 언어 정보 */
  language: Language;
  /** 노출 시작 시간 */
  startDate: Scalars['DateTime'];
  /** 제목 */
  title: Scalars['String'];
};

export type CouponBenefit = {
  __typename?: 'CouponBenefit';
  /** 쿠폰 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<CouponBenefitApply>>;
  /** 쿠폰 혜택 적용 설정 */
  applyType?: Maybe<BenefitApply>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<CouponBenefitBoardInfo>>;
  /** 쿠폰 혜택 생성일 */
  created: Scalars['DateTime'];
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 쿠폰 혜택을 적용할 다국어몰 */
  language?: Maybe<Language>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<CouponBenefitOrderInfo>>;
  /** 쿠폰 헤택 유형 */
  type?: Maybe<BenefitType>;
  /** 쿠폰 혜택 수정일 */
  updated: Scalars['DateTime'];
};

export type CouponBenefitApply = {
  __typename?: 'CouponBenefitApply';
  couponBenefitId: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 적용 설정 값 */
  value: Scalars['String'];
};

export type CouponBenefitBoardInfo = {
  __typename?: 'CouponBenefitBoardInfo';
  couponBenefitId: Scalars['Float'];
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type CouponBenefitItem = {
  __typename?: 'CouponBenefitItem';
  /** 쿠폰 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<ApplyItems>>;
  /** 쿠폰 혜택 적용 설정 */
  applyType?: Maybe<ApplyType>;
  /** 고도몰 쿠폰 명 */
  godoCouponName?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 등록일 */
  godoCouponRegDt?: Maybe<Scalars['String']>;
  /** 고유 ID */
  id: Scalars['Int'];
  /** 쿠폰 혜택을 적용할 다국어몰 */
  language?: Maybe<Lang>;
  /** 쿠폰 헤택 유형 */
  type?: Maybe<Type>;
};

export type CouponBenefitOrderInfo = {
  __typename?: 'CouponBenefitOrderInfo';
  couponBenefitId: Scalars['Float'];
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type CouponBurden = {
  __typename?: 'CouponBurden';
  /** 부담도 */
  burden?: Maybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** sno */
  sno: Scalars['String'];
};

export type CouponItem = {
  __typename?: 'CouponItem';
  /** 쿠폰 사용 가능 카테고리 목록 */
  couponApplyCategory?: Maybe<Array<CouponApplyCategory>>;
  /** 쿠폰 사용 가능 상품 목록 */
  couponApplyGoods?: Maybe<Array<CouponApplyGoods>>;
  /** 쿠폰 사용 가능 타입 (category, brand, goods) */
  couponApplyProductType: Scalars['String'];
  /** 쿠폰 혜택 */
  couponBenefit: Scalars['Int'];
  /** 쿠폰 혜택 타입 (percent, fix) */
  couponBenefitType: Scalars['String'];
  /** 쿠폰 종류(sale, add, deposit) */
  couponKindType: Scalars['String'];
  /** 쿠폰 최대 혜택 */
  couponMaxBenefit: Scalars['Int'];
  /** 최소 주문 금액 */
  couponMinOrderPrice: Scalars['Int'];
  /** 최소 주문 금액 기준 (product, order), default: product */
  couponMinOrderType?: Maybe<Scalars['String']>;
  /** 쿠폰 ID */
  couponNo: Scalars['Int'];
  /** 쿠폰 사용 가능 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 쿠폰 중복 사용 가능 여부 */
  isCouponApplyDuplicate: Scalars['Boolean'];
  /** 쿠폰 최대 혜택 제한 존재 여부 */
  isCouponMaxBenefit: Scalars['Boolean'];
  /** 유저 쿠폰 ID */
  memberCouponNo: Scalars['Int'];
  /** 쿠폰 사용가능 상태 (YES, USE_CART, USE_ORDER, EXPIRATION_START_PERIOD, EXPIRATION_END_PERIOD) */
  memberCouponUsable?: Maybe<Scalars['String']>;
  /** 쿠폰명 */
  name: Scalars['String'];
  /** 쿠폰 발급된 시간 */
  regDt: Scalars['DateTime'];
  /** 쿠폰 사용 가능 시작 시간 */
  startDate: Scalars['DateTime'];
};

export type CouponItems = {
  __typename?: 'CouponItems';
  /** 고도몰 쿠폰 명 */
  godoCouponName?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 등록일 */
  godoCouponRegDt?: Maybe<Scalars['String']>;
  /** 고도몰 쿠폰 남은 수량 */
  remaining?: Maybe<Scalars['Int']>;
};

export type CouponList = {
  __typename?: 'CouponList';
  /** 쿠폰 내역 목록 */
  items: Array<CouponItem>;
  /** 쿠폰 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type CreateApplicationInput = {
  name: Scalars['String'];
};

export type CreateBrandInput = {
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']>;
  /** 중국어 표시이름 */
  cnName?: InputMaybe<Scalars['String']>;
  /** 영어 표시이름 */
  enName?: InputMaybe<Scalars['String']>;
  /** 고도몰 브랜드 코드 */
  godoBrandCd?: InputMaybe<Scalars['String']>;
  /** 일본어 표시이름 */
  jpName?: InputMaybe<Scalars['String']>;
  /** 한국어 표시이름 */
  koName?: InputMaybe<Scalars['String']>;
};

export type CreateClipInput = {
  godoGoodsNo: Scalars['Int'];
};

export type CreateEventInput = {
  endDate: Scalars['DateTime'];
  /** 이벤트가 진행되는 몰들을 ,로 구분하여 입력합니다. ex) ko,en,zh */
  malls: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CreateExtensionConfigInput = {
  name: Scalars['String'];
};

export type CreateInquiryAnswerInput = {
  /** Answer content */
  answer: Scalars['String'];
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
};

export type CreateInquiryMemoInput = {
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
  /** Memo content */
  memo: Scalars['String'];
};

export type CreateOrderMemoInput = {
  memo: Scalars['String'];
  orderId: Scalars['String'];
};

export type CreatePopupInput = {
  /** 버튼 문구 */
  buttonText?: InputMaybe<Scalars['String']>;
  /** 연결 URL */
  connectedUrl?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean'];
  /** 팝업 이미지 아이디 */
  image?: InputMaybe<PopupUploadFileInput>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** 일시 정지용 */
  isStopped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<LanguageCodeEnum>;
  location?: InputMaybe<PopupLocationV2>;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations: Array<PopupSubLocationInput>;
  subText?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  urls: Array<CreatePopupUrlInput>;
};

export type CreatePopupUrlInput = {
  id?: InputMaybe<Scalars['Int']>;
  popupId?: InputMaybe<Scalars['Int']>;
  subLocation?: InputMaybe<PopupSubLocationEnum>;
  urls: Array<UrlsInput>;
};

export type CurrentPackageOutput = {
  __typename?: 'CurrentPackageOutput';
  brand: Scalars['String'];
  children: PackageChildren;
  parent: Asset;
};

export enum Document_Status {
  Complete = 'COMPLETE',
  CompleteOnOpen = 'COMPLETE_ON_OPEN',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  DraftOnOpen = 'DRAFT_ON_OPEN',
  InReview = 'IN_REVIEW',
  InReviewOnOpen = 'IN_REVIEW_ON_OPEN',
  OnSale = 'ON_SALE',
  OnSaleOnOpen = 'ON_SALE_ON_OPEN',
  Rejected = 'REJECTED',
  RejectedOnOpen = 'REJECTED_ON_OPEN',
  Requested = 'REQUESTED',
  RequestedOnOpen = 'REQUESTED_ON_OPEN'
}

export type DeleteExtensionConfigInput = {
  id: Scalars['Int'];
};

export type DeletePopupInput = {
  id: Scalars['Int'];
};

export type DepositItem = {
  __typename?: 'DepositItem';
  /** 남은 예치금 액수 */
  afterDeposit: Scalars['Int'];
  /** 예치금 지급 내용 */
  contents: Scalars['String'];
  /** 예치금 지급 액수 */
  deposit: Scalars['Int'];
  /** 예치금 지급 일자 */
  regDate: Scalars['String'];
};

export type DepositList = {
  __typename?: 'DepositList';
  /** 예치금 지급 내역 목록 */
  items: Array<DepositItem>;
  /** 지급 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type DisplayCouponBanner = {
  __typename?: 'DisplayCouponBanner';
  /** 조건1 */
  conditionFirst: Scalars['String'];
  /** 조건2 */
  conditionSecond: Scalars['String'];
  /** 쿠폰 번호 */
  couponNo: Scalars['Float'];
  /** 노출 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 배너 바디 이미지 */
  imageUrl?: Maybe<Scalars['String']>;
  /** 언어 */
  lang: Scalars['String'];
  /** 노출 시작 시간 */
  startDate: Scalars['DateTime'];
  /** 제목 */
  title: Scalars['String'];
};

export type DisplayPromotionItem = {
  __typename?: 'DisplayPromotionItem';
  /** 작가명 */
  brandName: Scalars['String'];
  /** 항목 ID */
  id: Scalars['Int'];
  /** 진행기간 */
  periods: Array<Periods>;
  status: Array<Scalars['String']>;
  /** 작품명 */
  title: Scalars['String'];
};

export type DocumentChangeHistoryInput = {
  date: Scalars['DateTime'];
  person: Scalars['String'];
  status: Document_Status;
};

export type DocumentChangeHistoryOutput = {
  __typename?: 'DocumentChangeHistoryOutput';
  date: Scalars['DateTime'];
  person: Scalars['String'];
  status: Document_Status;
};

export type DocumentCommonInput = {
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};

export type DocumentCopyrightInput = {
  commercialSources?: InputMaybe<Scalars['String']>;
  hasRealLogo?: InputMaybe<Scalars['String']>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  warehouseSources?: InputMaybe<Scalars['String']>;
};

export type DocumentCopyrightOutput = {
  __typename?: 'DocumentCopyrightOutput';
  commercialSources?: Maybe<Scalars['String']>;
  hasRealLogo?: Maybe<Scalars['String']>;
  isOriginal?: Maybe<Scalars['Boolean']>;
  warehouseSources?: Maybe<Scalars['String']>;
};

export type DocumentFileComponentInput = {
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
};

export type DocumentFileComponentOutput = {
  __typename?: 'DocumentFileComponentOutput';
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type DocumentFileUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DocumentFileUpdateOutput = {
  __typename?: 'DocumentFileUpdateOutput';
  date?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentMemoInput = {
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manager?: InputMaybe<Scalars['String']>;
  memoId?: InputMaybe<Scalars['Int']>;
};

export type DocumentMemoOutput = {
  __typename?: 'DocumentMemoOutput';
  content?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  memoId?: Maybe<Scalars['Int']>;
};

export type DocumentTitle = {
  __typename?: 'DocumentTitle';
  language?: Maybe<Language_Code>;
  title?: Maybe<Scalars['String']>;
};

export type DownloadCount = {
  __typename?: 'DownloadCount';
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 부여된 다운로드 횟수 */
  givenCount: Scalars['Int'];
  /** 상품 번호 */
  goodsNo: Scalars['Int'];
  /** id */
  id: Scalars['Int'];
  logs?: Maybe<Array<DownloadLog>>;
  /** 주문 번호 */
  orderNo: Scalars['String'];
  /** 수정자 */
  updator: Scalars['Int'];
  /** 유저 id */
  userId: Scalars['String'];
  /** 유저 고유 번호 */
  userNo: Scalars['Int'];
  /** 유저 분류 (personal, enterprise, admin) */
  userType: Scalars['String'];
};

export type DownloadCountInfo = {
  __typename?: 'DownloadCountInfo';
  /** goods number */
  goodsNo: Scalars['Int'];
  /** maximum download count */
  limit: Scalars['Int'];
  /** order number */
  orderNo: Scalars['Float'];
  /** currently remained download count */
  remained: Scalars['Int'];
  /** user ID */
  userId: Scalars['String'];
};

export type DownloadLog = {
  __typename?: 'DownloadLog';
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 생성자 */
  creator?: Maybe<Scalars['Int']>;
  /** 다운로드 카운트 id */
  downloadCountId: Scalars['Float'];
  /** 제공된 다운로드 url */
  downloadUrl: Scalars['String'];
  /** 상품 번호 */
  goodsNo: Scalars['Int'];
  /** id */
  id: Scalars['Int'];
  /** 다운로드 횟수 차감 여부 */
  isDeduction: Scalars['Boolean'];
  /** 제공된 모델 파일 id */
  modelFileId: Scalars['Int'];
  /** 주문 번호 */
  orderNo: Scalars['String'];
  /** 요청 ip */
  requestIp: Scalars['String'];
  /** 유저 id */
  userId: Scalars['String'];
  /** 유저 고유 번호 */
  userNo: Scalars['Int'];
};

export type DownloadLogResponse = {
  __typename?: 'DownloadLogResponse';
  /** 다운로드 카운터 객체 */
  downloadCounts: Array<DownloadCount>;
  /** 가져온 갯수 */
  totalCount: Scalars['Int'];
};

export type EventBannerItems = {
  __typename?: 'EventBannerItems';
  /** 첫번째 배너 내용 텍스트 */
  bannerTextFirst: Scalars['String'];
  /** 두번쨰 배너 내용 텍스트 */
  bannerTextSecond: Scalars['String'];
  /** 세번째 배너 내용 텍스트 */
  bannerTextThird: Scalars['String'];
  /** 노출 종료 일자 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Int'];
  /** 배너 이미지 */
  image: UploadFile;
  /** 언어 */
  language: Language;
  /** 정렬순서 */
  order: Scalars['Int'];
  /** 링크 설정 텍스트 */
  settingLink: Scalars['String'];
  /** 노출 시작 일자 */
  startDate: Scalars['DateTime'];
};

export type ExtensionConfig = {
  __typename?: 'ExtensionConfig';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Faq = {
  __typename?: 'Faq';
  /** FAQ 내용(바디) */
  body?: Maybe<Scalars['String']>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 고정글인 경우 고정 순서(번호) */
  fixed?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** FAQ를 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** FAQ 제목 */
  title?: Maybe<Scalars['String']>;
  /** FAQ 타입(노하우, 가이드, ...) */
  type?: Maybe<FaqType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type FaqAconModel = {
  __typename?: 'FaqAconModel';
  /** ACONFAQ 내용(바디) */
  body: Scalars['String'];
  /** 생성일 */
  created?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** ACONFAQ를 표시할 다국어몰 */
  language: Language;
  /** 표시 순서(번호) */
  order: Scalars['Float'];
  /** ACONFAQ 질문형 제목 (에이콘 상세 페이지에서 노출) */
  subtitle: Scalars['String'];
  /** ACONFAQ 제목 */
  title: Scalars['String'];
  /** ACONFAQ 타입(노하우, 가이드, ...) */
  type: FaqAconTypeModel;
  /** 수정일 */
  updated?: Maybe<Scalars['DateTime']>;
};

export type FaqAconTypeI18nModel = {
  __typename?: 'FaqAconTypeI18nModel';
  id: Scalars['Float'];
  language: Language;
  name?: Maybe<Scalars['String']>;
};

export type FaqAconTypeModel = {
  __typename?: 'FaqAconTypeModel';
  /** ACONFAQ 타입 코드(usage, product, ...) */
  code: Scalars['String'];
  /** ACONFAQ 타입의 각 언어별 이름 */
  i18n?: Maybe<Array<FaqAconTypeI18nModel>>;
  /** ACONFAQ 타입 id */
  id: Scalars['Float'];
};

export type FaqCreatorModel = {
  __typename?: 'FaqCreatorModel';
  /** 내용 */
  body: Scalars['String'];
  /** 생성일 */
  created?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 언어 */
  lang: Scalars['String'];
  /** 표시 순서(번호) */
  order: Scalars['Float'];
  /** 질문형 제목 */
  subtitle: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
  /** ACONFAQ 타입(노하우, 가이드, ...) */
  type: FaqCreatorTypeModel;
  /** 수정일 */
  updated?: Maybe<Scalars['DateTime']>;
};

export type FaqCreatorTypeI18nModel = {
  __typename?: 'FaqCreatorTypeI18nModel';
  id: Scalars['Float'];
  lang: Scalars['String'];
  name: Scalars['String'];
};

export type FaqCreatorTypeModel = {
  __typename?: 'FaqCreatorTypeModel';
  /** code */
  code: Scalars['String'];
  /** ACONFAQ 타입의 각 언어별 이름 */
  i18n?: Maybe<Array<FaqCreatorTypeI18nModel>>;
  /** id */
  id: Scalars['Float'];
};

export type FaqType = {
  __typename?: 'FaqType';
  /** FAQ 타입 코드(knowhow, guide, ...) */
  code: Scalars['String'];
  /** FAQ 타입 id */
  id: Scalars['Float'];
  /** FAQ 타입 이름(노하우, 가이드, ...) */
  name: Scalars['String'];
};

export type FileInput = {
  key: Scalars['String'];
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
};

export type FileUpdateInput = {
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  path?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
};

export enum FundingStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Unready = 'UNREADY'
}

export type Get = {
  __typename?: 'Get';
  id: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  userId: Scalars['Float'];
};

export type GodoBanner = {
  __typename?: 'GodoBanner';
  /** 링크 */
  anchor?: Maybe<Scalars['String']>;
  /** 링크 새 창으로 열기 여부 */
  anchorTarget: Scalars['Boolean'];
  /** 색상 */
  color?: Maybe<Scalars['String']>;
  /** 이미지 경로 */
  image?: Maybe<Scalars['String']>;
  /** 이미지 맵 */
  map?: Maybe<Scalars['String']>;
};

export type GodoCategory = {
  __typename?: 'GodoCategory';
  /** 고도몰 카테고리 코드 */
  id: Scalars['String'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 다국어 데이터 */
  trans: Array<GodoTrans>;
  /** 카테고리 대분류 */
  type?: Maybe<Scalars['String']>;
};

export type GodoCosmicDeal = {
  __typename?: 'GodoCosmicDeal';
  /** 혜택명 */
  benefitNm: Scalars['String'];
  /** 할인금액 */
  goodsPrice: Scalars['Int'];
  /** 혜택할인 종료기간 */
  periodDiscountEnd: Scalars['DateTime'];
  /** 혜택할인 시작기간 */
  periodDiscountStart: Scalars['DateTime'];
  /** sno */
  sno: Scalars['Int'];
};

export type GodoDisplayCategory = {
  __typename?: 'GodoDisplayCategory';
  /** 카테고리 코드 */
  code: Scalars['String'];
  /** 링크 여부 */
  isLink: Scalars['Boolean'];
  /** 표시 여부 */
  isVisible: Scalars['Boolean'];
  /** 카테고리 이름 */
  name: Scalars['String'];
};

export type GodoDisplayProductModel = {
  __typename?: 'GodoDisplayProductModel';
  /** 고도 표시 ID  */
  godoDisplayId: Scalars['Float'];
  /** 상품 번호 */
  goodsNo: Scalars['Int'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 정렬 순서 */
  sortNo: Scalars['Int'];
};

export type GodoGoodsListCategoryResult = {
  __typename?: 'GodoGoodsListCategoryResult';
  /** 번역된 카테고리 이름 */
  name: Scalars['String'];
};

export type GodoMainGropTrans = {
  __typename?: 'GodoMainGropTrans';
  /** 상태 */
  lang: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
};

export type GodoMainGroup = {
  __typename?: 'GodoMainGroup';
  /** 이름 */
  name: Scalars['String'];
  /** 상태 */
  sno: Scalars['Int'];
  /** 하위 항목들 */
  trans: Array<GodoMainGropTrans>;
};

export type GodoPopupInfo = {
  __typename?: 'GodoPopupInfo';
  /** 오늘 하루 보지 않음 기능 사용여부 */
  content: Scalars['String'];
  /** 팝업 높이(ex: 100px / 50%) */
  height: Scalars['String'];
  /** 오늘 하루 보지 않음 기능 사용여부 */
  isUseTodayHide: Scalars['Boolean'];
  /** 오늘하루 보지않음 텍스트 위치 */
  todayHideAlign: Scalars['String'];
  /** 오늘하루 보지않음 배경색상 */
  todayHideBgColor: Scalars['String'];
  /** 오늘하루 보지않음 폰트색상 */
  todayHideTextColor: Scalars['String'];
  /** 팝업 너비 (ex: 100px / 50%) */
  width: Scalars['String'];
};

export type GodoProductBrand = {
  __typename?: 'GodoProductBrand';
  brandCd: Scalars['String'];
  /** 정렬 순서 (거의 쓰지 않음) */
  fixSort: Scalars['Int'];
  godoProductId: Scalars['Int'];
  /** 상품 정렬 순서 */
  goodsSort: Scalars['Int'];
  id: Scalars['Int'];
};

export type GodoProductBrandModel = {
  __typename?: 'GodoProductBrandModel';
  brandCd: Scalars['String'];
  fixSort: Scalars['Float'];
  /** 고도 상품 ID  */
  godoProductId: Scalars['Float'];
  goodsSort: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
};

export type GodoProductCategory = {
  __typename?: 'GodoProductCategory';
  cateCd: Scalars['String'];
  categoryDetail?: Maybe<CategoryClone>;
  fixSort: Scalars['Int'];
  godoProductId: Scalars['Int'];
  goodsSort: Scalars['Int'];
  id: Scalars['Int'];
};

export type GodoProductCategoryModel = {
  __typename?: 'GodoProductCategoryModel';
  cateCd: Scalars['String'];
  categoryDetail?: Maybe<Category>;
  fixSort: Scalars['Float'];
  /** 고도 상품 ID  */
  godoProductId: Scalars['Float'];
  goodsSort: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
};

export type GodoTrans = {
  __typename?: 'GodoTrans';
  /** 배너 데이터 */
  banner: GodoBanner;
  /** 언어 코드 */
  lang: Scalars['String'];
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 검색 결과 창 표시 여부 */
  visibleResultStats: Scalars['Boolean'];
};

export type GodoUserCartReturn = {
  __typename?: 'GodoUserCartReturn';
  godoGoodsNo: Scalars['String'];
};

export type GodoUserFriend = {
  __typename?: 'GodoUserFriend';
  /** 추천 코드 */
  code: Scalars['String'];
  /** 고도몰 사용자 ID */
  godoMemNo: Scalars['Int'];
  /** 코드 */
  godoUserInvitationCode: GodoUserInvitationCode;
  /** UUID */
  id: Scalars['String'];
  /** 구매여부 */
  isOrder: Scalars['Boolean'];
  /** 사용자 언어 */
  langCode: Scalars['String'];
};

export type GodoUserInfo = {
  __typename?: 'GodoUserInfo';
  /** 장바구니 개수 */
  cartCount?: Maybe<Scalars['Int']>;
  /** hash */
  hash?: Maybe<Scalars['String']>;
  /** 쿠폰 알림 여부 */
  isCouponNotification?: Maybe<Scalars['String']>;
  memNo?: Maybe<Scalars['String']>;
  /** 사용자 아이디 */
  userId?: Maybe<Scalars['String']>;
  /** 스크랩 개수 */
  wishCount?: Maybe<Scalars['Int']>;
};

export type GodoUserInvitationCode = {
  __typename?: 'GodoUserInvitationCode';
  /** 추천 코드 */
  code: Scalars['String'];
  /** 친구들 */
  friends?: Maybe<Array<GodoUserFriend>>;
  /** 고도몰 사용자 ID */
  godoMemNo: Scalars['Int'];
  /** 언어코드 */
  langCode: Scalars['String'];
};

export type Goods = {
  __typename?: 'Goods';
  /** 프로젝트 항목(옵션) 수수료 */
  commission?: Maybe<Scalars['Int']>;
  compositions?: Maybe<Array<GoodsFileComposition>>;
  dynamicElement?: Maybe<Scalars['String']>;
  fileExtensions?: Maybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  /** 프로젝트 항목(옵션) 금액(정가) */
  listPrice: Scalars['Int'];
  modelCompositions?: Maybe<Array<ModelComposition>>;
  projectId: Scalars['Int'];
  /** 프로젝트 항목(옵션) 할인가 */
  salePrice?: Maybe<Scalars['Int']>;
  updateHistories?: Maybe<Array<GoodsUpdateHistory>>;
  uploadFile?: Maybe<UploadFileClone>;
  uploadFileId?: Maybe<UploadFileClone>;
};

export type GoodsBuyers = {
  __typename?: 'GoodsBuyers';
  /** 상품 id */
  goodsNo: Scalars['Float'];
  /** 주문자 그룹 이름 */
  groupNm: Scalars['String'];
  /** 주문 정보 메일 */
  orderEmail: Scalars['String'];
  /** 주문 정보 이름 */
  orderName: Scalars['String'];
  /** 주문 id */
  orderNo: Scalars['String'];
};

export type GoodsFileComposition = {
  __typename?: 'GoodsFileComposition';
  id: Scalars['Int'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type GoodsFileCompositionInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type GoodsInput = {
  commission?: InputMaybe<Scalars['Int']>;
  compositions?: InputMaybe<Array<GoodsFileCompositionInput>>;
  dynamicElement?: InputMaybe<Scalars['String']>;
  goodsFileExtensions?: InputMaybe<Scalars['String']>;
  goodsFileName?: InputMaybe<Scalars['String']>;
  listPrice: Scalars['Int'];
  modelCompositions?: InputMaybe<Array<Scalars['Int']>>;
  salePrice?: InputMaybe<Scalars['Int']>;
  /** azure storage에 올라간 프로젝트 파일 임시경로 */
  temporaryFilePath?: InputMaybe<Scalars['String']>;
  updateHistories?: InputMaybe<Array<GoodsUpdateHistoryInput>>;
};

export type GoodsUpdateHistory = {
  __typename?: 'GoodsUpdateHistory';
  date: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type GoodsUpdateHistoryInput = {
  date: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  auth: Array<GroupAuth>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type GroupAuth = {
  __typename?: 'GroupAuth';
  authType: AuthType;
  id: Scalars['Float'];
  menu: Menu;
};

export type HideAssetsInput = {
  /** asset id를 배열로 */
  assetIds: Array<Scalars['Int']>;
};

export type HubAdminPost = {
  __typename?: 'HubAdminPost';
  /** 고정글인 경우 순서 */
  anchoringOrder?: Maybe<Scalars['Int']>;
  body: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  next?: Maybe<HubAdminPost>;
  prev?: Maybe<HubAdminPost>;
  title: Scalars['String'];
  typeId: Scalars['Int'];
  typeName: Scalars['String'];
};

export type HubAdminPosts = {
  __typename?: 'HubAdminPosts';
  /** 고정글 */
  anchors: Array<HubAdminPost>;
  /** 일반글 */
  regulars: Array<HubAdminPost>;
};

export type ImageCropInput = {
  height: Scalars['Int'];
  left: Scalars['Int'];
  top: Scalars['Int'];
  width: Scalars['Int'];
};

export type ImageKey = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type ImageKeys = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type ImgCropType = {
  /** 높이 */
  cropHeight: Scalars['Float'];
  /** 너비 */
  cropWidth: Scalars['Float'];
  /** x축 위치 */
  cropX: Scalars['Float'];
  /** y축 위치 */
  cropY: Scalars['Float'];
  /** 업로드 된 파일 key */
  key?: InputMaybe<Scalars['String']>;
};

export type Inquiry = {
  __typename?: 'Inquiry';
  /** 문의 답변 */
  answer?: Maybe<InquiryAnswer>;
  /** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
  changePenName?: Maybe<InquiryChangePenName>;
  content: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  godoMemNo?: Maybe<Scalars['String']>;
  godoUserId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inquiryAcon3dProduct?: Maybe<Acon3dProduct>;
  /** 결제-주문/결제(G1) */
  inquiryOrders?: Maybe<Array<Maybe<InquiryOrder>>>;
  /** 상품상세문의(F1) */
  inquiryProduct?: Maybe<TempAconProduct>;
  /** 스크랩 여부 */
  isClipped?: Maybe<Scalars['Boolean']>;
  /** 회원 여부 */
  isMember: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  /** 관리자 메모 */
  memos: Array<InquiryMemo>;
  refundAccount?: Maybe<InquiryRefundAccount>;
  /** 상태: unread | read | answered */
  status: InquiryStatusEnum;
  /** 문의유형 소분류 */
  subType?: Maybe<InquirySubTypeEnum>;
  /** 문의유형 대분류 */
  type: InquiryTypeEnum;
  updated?: Maybe<Scalars['DateTime']>;
  uploadFiles: Array<UploadFile>;
};


export type InquiryInquiryAcon3dProductArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type InquiryAnswer = {
  __typename?: 'InquiryAnswer';
  adminUser: AdminUser;
  answer: Scalars['String'];
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type InquiryChangePenName = {
  __typename?: 'InquiryChangePenName';
  /** 변경할 사용권 유형: null(ChangeMyself) | personToTeam | teamToPerson */
  changeType?: Maybe<ChangeTypeEnum>;
  /** 기존 필명으로 작품을 사용함 여부 */
  isOldPenNameRetained: Scalars['Boolean'];
  /** 필명/작품명 */
  penName: Scalars['String'];
  /** 팀/회사명 */
  teamName?: Maybe<Scalars['String']>;
};

export type InquiryInput = {
  /** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
  changePenName?: InputMaybe<ChangePenNameInput>;
  /** 기본값은 빈 문자열 */
  content?: InputMaybe<Scalars['String']>;
  /** 답변 받을 이메일 */
  email?: InputMaybe<Scalars['String']>;
  /** 결제-주문/결제(G1), 해당하지 않으면 null */
  inquiryOrders?: InputMaybe<Array<InputMaybe<InquiryOrderInput>>>;
  /** 상품상세문의(F1) */
  inquiryProduct?: InputMaybe<InquiryProductInput>;
  languageCode: LanguageCodeEnum;
  /** 결제-취소/환불(H1-H3) */
  refundAccount?: InputMaybe<RefundAccountInput>;
  /** 문의 소분류 */
  subType?: InputMaybe<InquirySubTypeEnum>;
  /** 세금계산서(B2) */
  taxes?: InputMaybe<TaxesInput>;
  /** 문의 대분류 */
  type: InquiryTypeEnum;
  /** 첨부파일. 없으면 빈 배열을 보냅니다 */
  uploadFiles: Array<Scalars['Int']>;
};

export type InquiryMemo = {
  __typename?: 'InquiryMemo';
  adminUser: AdminUser;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  memo: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type InquiryOrder = {
  __typename?: 'InquiryOrder';
  godoGoodsDetails: Array<InquiryOrderGoodsDetail>;
  godoOrderNo: Scalars['String'];
  id: Scalars['Int'];
};

export type InquiryOrderGoodsDetail = {
  __typename?: 'InquiryOrderGoodsDetail';
  godoGoodsNo: Scalars['String'];
  id: Scalars['Int'];
  orderStatus: Scalars['Float'];
};

/** Subtype of InquiryOrderInput */
export type InquiryOrderGoodsDetailInput = {
  godoGoodsNo: Scalars['String'];
  orderStatus: OrderStatusEnum;
};

/** 결제-주문/결제(G1) */
export type InquiryOrderInput = {
  godoGoodsDetails: Array<InquiryOrderGoodsDetailInput>;
  godoOrderNo: Scalars['String'];
};

/** 상품상세문의(F1) */
export type InquiryProductInput = {
  /** 상품 고도몰 번호 */
  goodsNo: Scalars['Int'];
};

export type InquiryRefundAccount = {
  __typename?: 'InquiryRefundAccount';
  accountHolder: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
};

export enum InquiryStatusEnum {
  Answered = 'answered',
  Cancelled = 'cancelled',
  Read = 'read',
  Unread = 'unread'
}

export enum InquirySubTypeEnum {
  Abler = 'abler',
  CancelAndRefund = 'cancelAndRefund',
  ChangeOrderLicense = 'changeOrderLicense',
  ChangePenName = 'changePenName',
  CopyrightAndOrderLicense = 'copyrightAndOrderLicense',
  Improvement = 'improvement',
  OrderAndPayment = 'orderAndPayment',
  Other = 'other',
  ProductError = 'productError',
  ProductInfo = 'productInfo',
  SiteError = 'siteError'
}

export enum InquiryTypeEnum {
  CollaborationProposal = 'collaborationProposal',
  Payment = 'payment',
  Product = 'product',
  Service = 'service',
  Taxes = 'taxes',
  Utilization = 'utilization'
}

export type IssueEventCouponInput = {
  couponNumber: Scalars['Float'];
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};

export type IssuedCount = {
  __typename?: 'IssuedCount';
  /** 허브 차감 개수 */
  deductionCount: Scalars['Int'];
  /** 고도몰 차감 개수 */
  godoDeductionCount: Scalars['Int'];
  /** 고도몰 지급 개수 */
  godoPaymentCount: Scalars['Int'];
  /** 허브 지급 개수 */
  paymentCount: Scalars['Int'];
};

export enum KeywordTypeEnum {
  Contents = 'contents',
  Goods = 'goods',
  User = 'user'
}

export enum Language_Code {
  En = 'EN',
  Ja = 'JA',
  Ko = 'KO',
  Zh = 'ZH'
}

export type Lang = {
  __typename?: 'Lang';
  /** 언어 코드 */
  code?: Maybe<Scalars['String']>;
  /** 언어 ID */
  id?: Maybe<Scalars['Float']>;
};

export type Language = {
  __typename?: 'Language';
  /** 언어 코드(ko, en, cn, jp) */
  code: Scalars['String'];
  /** 언어 id */
  id: Scalars['Float'];
  /** 언어 이름(한국어, 영어, ...) */
  name: Scalars['String'];
};

export type LanguageClone = {
  __typename?: 'LanguageClone';
  /** 언어 코드(ko, en, cn, jp) */
  code: Scalars['String'];
  /** 언어 id */
  id: Scalars['Float'];
  /** 언어 이름(한국어, 영어, ...) */
  name: Scalars['String'];
};

export enum LanguageCodeEnum {
  En = 'en',
  Ja = 'ja',
  Ko = 'ko',
  Zh = 'zh'
}

export type LicenseClone = {
  __typename?: 'LicenseClone';
  companyName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  linkedOrderProductItems?: Maybe<Array<OrderProductItemClone>>;
  memNo: Scalars['Int'];
  name: Scalars['String'];
  order: OrderProductClone;
  orderId: Scalars['String'];
  status: LicenseStatus;
  type: LicenseType;
};

export type LicenseInput = {
  companyName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  linkedOrderProductItems?: InputMaybe<Array<LinkedOrderProductItemInput>>;
  name: Scalars['String'];
  /** personal: 개인사용권, company: 공동사용권 */
  type: LicenseType;
};

export enum LicenseStatus {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Ready = 'ready'
}

export enum LicenseType {
  Company = 'company',
  Personal = 'personal'
}

export type LicenseUpdateHistory = {
  __typename?: 'LicenseUpdateHistory';
  adminUser: AdminUser;
  /** 데이터 전체 JSON */
  contents?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  orderId: Scalars['String'];
};

export type LinkedOrderProductItemInput = {
  godoGoodsNo: Scalars['Int'];
};

export type ListDocumentOutput = {
  __typename?: 'ListDocumentOutput';
  assetId?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  id: Scalars['String'];
  language: Language_Code;
  mainImage?: Maybe<Scalars['String']>;
  requested?: Maybe<Scalars['DateTime']>;
  status: Partner_Document_Status;
  title?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type MainGroup = {
  __typename?: 'MainGroup';
  /** 고도몰 sno */
  godoSno: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<Array<MainGroupI18n>>;
  name: Scalars['String'];
};

export type MainGroupGoods = {
  goodsNo: Array<Scalars['Float']>;
  sno: Scalars['Float'];
};

export type MainGroupI18n = {
  __typename?: 'MainGroupI18n';
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  /** 부모 메인그룹 ID  */
  mainGroupId: Scalars['Float'];
  name: Scalars['String'];
};

export type MainProductListV2Return = {
  __typename?: 'MainProductListV2Return';
  /** 상품 리스트 */
  productList: Array<Scalars['Int']>;
  /** 고도몰 메인구좌 관리번호 */
  sno: Scalars['String'];
  /** 경로 */
  source: Scalars['String'];
  title: Scalars['String'];
};

export type Menu = {
  __typename?: 'Menu';
  name: Scalars['String'];
};

export type ModelComposition = {
  __typename?: 'ModelComposition';
  id: Scalars['Int'];
  modelCompositionNames: Array<ModelCompositionName>;
  /** 여러국가 모델구성 중 대표 모델구성 */
  title: Scalars['String'];
};

export type ModelCompositionName = {
  __typename?: 'ModelCompositionName';
  languageCode: LanguageCodeEnum;
  modelCompositionId: Scalars['Int'];
  name: Scalars['String'];
};

export type ModelConfig = {
  __typename?: 'ModelConfig';
  i18n: Array<ModelConfigI18n>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 이름 */
  name: Scalars['String'];
};

export type ModelConfigI18n = {
  __typename?: 'ModelConfigI18n';
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  /** 모델구성 id */
  modelConfigId: Scalars['Float'];
  name: Scalars['String'];
};

export type ModelConfigI18nClone = {
  __typename?: 'ModelConfigI18nClone';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  abort: Scalars['Boolean'];
  aprilAttendanceEvent: Scalars['Boolean'];
  attendanceEvent: Scalars['Boolean'];
  authenticate: Scalars['Boolean'];
  /** Cancel one inquiry */
  cancelInquiry: Inquiry;
  changePasswordUser: User;
  changeReviewStatus: Scalars['Boolean'];
  changeUserSettleStatus: Scalars['Boolean'];
  /** Clip/Unclip an Inquiry */
  clipInquiry: Inquiry;
  complete: Scalars['Boolean'];
  copyPopup: PopupV2;
  createAconNotification?: Maybe<AconNotification>;
  createAconfaq: FaqAconModel;
  createAdmin: Scalars['Boolean'];
  createApplication: Application;
  createBadge: Scalars['Boolean'];
  createBandBanner: BandBanner;
  createBanner: BannerClone;
  /** 사업자 정보가 맞는지 확인하고 등록하는 메소드 입니다. */
  createBusiness: Scalars['Boolean'];
  createCategory: Category;
  createClip?: Maybe<Clip>;
  createCouponBenefit: CouponBenefit;
  createEvent: Scalars['Boolean'];
  /** 이벤트 배너를 생성하는 메소드입니다. */
  createEventBanner: Scalars['Boolean'];
  createExtensionConfig: ExtensionConfig;
  createFaq: Faq;
  createFaqAcon: FaqAconModel;
  createFaqCreator: FaqCreatorModel;
  /** 친구를 추가하는 뮤테이션 */
  createFriend: Scalars['Boolean'];
  createInquiry: Inquiry;
  createInquiryAnswer: Inquiry;
  createInquiryMemo: Inquiry;
  createItem: CouponBanner;
  createModelConfig: Scalars['Boolean'];
  createNotice: Notice;
  /** 주문 저작권 정보를 생성하는 함수입니다. */
  createOrderLicense: Scalars['Boolean'];
  createOrderMemo: OrderAdminMemo;
  createPointBenefit: PointBenefit;
  createPointLimitPolicy: BenefitLimit;
  createPopup: PopupV2;
  createTermAgree: Scalars['Boolean'];
  createUser: Scalars['Boolean'];
  createUserPlan: Scalars['Boolean'];
  deleteAconNotification: Scalars['Int'];
  deleteAconfaq: Scalars['Boolean'];
  deleteBadge: Scalars['Boolean'];
  deleteBandBanner: Scalars['Boolean'];
  deleteBanner: Scalars['Boolean'];
  deleteCartCache: Scalars['Boolean'];
  deleteCategory: Scalars['Boolean'];
  deleteCouponBenefit: Scalars['Boolean'];
  /** 이벤트 배너를 삭제하는 메소드 입니다. */
  deleteEventBanner: Scalars['Boolean'];
  deleteExtensionConfig: Scalars['Int'];
  deleteFaq: Scalars['Boolean'];
  deleteFaqAcon: Scalars['Boolean'];
  deleteFaqCreator: Scalars['Boolean'];
  deleteItem: Scalars['Boolean'];
  deleteMainGroup: Scalars['Boolean'];
  deleteMemo: Scalars['Boolean'];
  deleteModelConfig: Scalars['Boolean'];
  deleteMyReview: Scalars['Boolean'];
  deleteNotice: Scalars['Boolean'];
  deletePointBenefit: Scalars['Boolean'];
  deletePointLimitPolicy: Scalars['Boolean'];
  deletePopup: Scalars['Int'];
  deleteReviewReply: Scalars['Boolean'];
  deny: Scalars['Boolean'];
  editUser: Scalars['Boolean'];
  fixFaq: Faq;
  fixNotice: Notice;
  godoPasswordChange: Scalars['Boolean'];
  hideAssets: Scalars['Boolean'];
  increaseDownloadCount?: Maybe<DownloadCount>;
  issueEventCoupon: Scalars['Boolean'];
  memo: Scalars['Boolean'];
  modify: Scalars['Boolean'];
  modifyMemo: Scalars['Boolean'];
  moveCategory: Array<Category>;
  pack: Scalars['Boolean'];
  passwordChange: Scalars['Boolean'];
  passwordChangeForAnon: Scalars['Boolean'];
  passwordChangeSendEmail: Scalars['Boolean'];
  /** 장바구니엝 제품을 추가하는 메소드 */
  productAddCart: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  registUserMemo: Scalars['Boolean'];
  registerReview?: Maybe<Review>;
  registerReviewReply: ReviewReply;
  registerReviewV2?: Maybe<Review>;
  registerSnsMember: Scalars['Boolean'];
  reject: Scalars['Boolean'];
  removeClip: Scalars['Int'];
  removeClips: Scalars['Int'];
  removePromotions: Scalars['Boolean'];
  reorderAconfaq: FaqAconModel;
  /** 이벤트 배너 순서를 변경하는 메소드 입니다. */
  reorderEventBanner: Scalars['Boolean'];
  reorderFaqAcon: FaqAconModel;
  reorderFaqCreator: FaqCreatorModel;
  request: Scalars['Boolean'];
  requestUserRevision: Scalars['Boolean'];
  requestUserSettleStatus: Scalars['Boolean'];
  retry: Scalars['Boolean'];
  revise: Scalars['Boolean'];
  save: Scalars['Boolean'];
  saveConfig: Scalars['Boolean'];
  saveContentBody: Scalars['Boolean'];
  saveContentHead: Scalars['Boolean'];
  saveCopyright: Scalars['Boolean'];
  saveFile: Scalars['Boolean'];
  saveMainImage: Scalars['Boolean'];
  saveProject?: Maybe<Project>;
  savePromotion: Promotion;
  saveSubImage: Scalars['Boolean'];
  saveTitle: Scalars['Boolean'];
  scrapReview?: Maybe<Review>;
  sendAuthenticationCode: Scalars['Boolean'];
  setBenefitBurdenItem: Scalars['Boolean'];
  setBrand: Scalars['Boolean'];
  setCouponBurdenItem: Scalars['Boolean'];
  setFavoriteReview?: Maybe<Scalars['Int']>;
  setMainGroup: Scalars['Boolean'];
  /** 최초 구매 여부를 설정하는 뮤테이션 */
  setOrderFriend: Scalars['Boolean'];
  /** 생품의 생세페이지 - 파일엝서 띰이터 기록용으로 호출하는 mutation 메소드입니다. */
  setProductFileView: Scalars['Boolean'];
  setSearchKeyword: Scalars['Boolean'];
  showAssets: Scalars['Boolean'];
  syncBrands: Scalars['Boolean'];
  syncCategory: Array<Category>;
  syncExchangeRate: Scalars['Boolean'];
  syncMainGroups: Scalars['Boolean'];
  updateAconfaq: FaqAconModel;
  updateApplication: Scalars['Int'];
  updateBadge: Scalars['Boolean'];
  updateBandBanner: BandBanner;
  updateBanner: BannerClone;
  updateBrandPick: BrandClone;
  updateBrandUser: Scalars['Boolean'];
  updateCategory: Scalars['Boolean'];
  updateCouponBenefit: CouponBenefit;
  /** 이벤트 배너를 수정하는 메소드입니다. */
  updateEventBanner: Scalars['Boolean'];
  updateExtensionConfig: ExtensionConfig;
  updateFaq: Faq;
  updateFaqAcon: FaqAconModel;
  updateFaqCreator: FaqCreatorModel;
  updateFile: Scalars['Boolean'];
  updateFiles: Scalars['Boolean'];
  /** 상품 번호를 이용해 상품의 조회수를 늘리는 메소드 입니다. */
  updateGodoHitCount: Scalars['Boolean'];
  /** Modify the inquiry answer */
  updateInquiryAnswer: InquiryAnswer;
  updateItem: Scalars['Boolean'];
  updateLicense: Array<LicenseClone>;
  updateModelConfig: Scalars['Boolean'];
  updateNotice: Notice;
  /** 주문 저작권 정보를 수정하는 함수입니다. */
  updateOrderLicense: Scalars['Boolean'];
  updatePointBenefit: Scalars['Boolean'];
  updatePopup: PopupV2;
  updateProductViewCount: Scalars['Boolean'];
  updateReview?: Maybe<Review>;
  updateReviewReply: ReviewReply;
  updateReviewV2?: Maybe<Review>;
  updateUser: Scalars['Boolean'];
  updateUserV2: Scalars['Boolean'];
  uploadBlobStorage: UploadFile;
  uploadEditorImage: Scalars['String'];
  uploadGoodsAtCategoryBatch?: Maybe<Scalars['String']>;
  uploadGoodsAtMainGroupBatch?: Maybe<Scalars['String']>;
};


export type MutationAbortArgs = {
  input: DocumentCommonInput;
};


export type MutationAprilAttendanceEventArgs = {
  languageCode: Scalars['String'];
};


export type MutationAttendanceEventArgs = {
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
};


export type MutationAuthenticateArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCancelInquiryArgs = {
  input: CancelInquiryInput;
};


export type MutationChangePasswordUserArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationChangeReviewStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['String'];
};


export type MutationChangeUserSettleStatusArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  status: UserSettleStatusEnum;
};


export type MutationClipInquiryArgs = {
  input: ClipInquiryInput;
};


export type MutationCompleteArgs = {
  input: DocumentCommonInput;
};


export type MutationCopyPopupArgs = {
  id: Scalars['Int'];
};


export type MutationCreateAconNotificationArgs = {
  memNo?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export type MutationCreateAconfaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateAdminArgs = {
  account: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};


export type MutationCreateBadgeArgs = {
  endDate: Scalars['DateTime'];
  godoGoodsNos: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  isAutoCreate?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  texts: Array<BadgeI18nInput>;
};


export type MutationCreateBandBannerArgs = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  closeColor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  isBlankHref?: InputMaybe<Scalars['Boolean']>;
  isClosedShowId?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateBannerArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  imageKeys?: InputMaybe<ImageKeys>;
  langId?: InputMaybe<Scalars['Int']>;
  selectedProductsId?: InputMaybe<Array<Scalars['Int']>>;
  showOnlyForSelectedProducts?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateBusinessArgs = {
  bizName: Scalars['String'];
  bizNum: Scalars['String'];
  openDate: Scalars['String'];
  representativeName: Scalars['String'];
};


export type MutationCreateCategoryArgs = {
  creator?: InputMaybe<Scalars['Float']>;
  memo: Scalars['String'];
  name: Scalars['String'];
  parentCategoryId?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  type: Scalars['String'];
  updater?: InputMaybe<Scalars['Float']>;
};


export type MutationCreateClipArgs = {
  createClipInput: CreateClipInput;
};


export type MutationCreateCouponBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  godoCouponNo?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};


export type MutationCreateEventBannerArgs = {
  bannerTextFirst: Scalars['String'];
  bannerTextSecond: Scalars['String'];
  bannerTextThird: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<ImageKeys>;
  langId: Scalars['Int'];
  settingLink: Scalars['String'];
  startDate: Scalars['DateTime'];
};


export type MutationCreateExtensionConfigArgs = {
  input: CreateExtensionConfigInput;
};


export type MutationCreateFaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateFaqAconArgs = {
  body?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};


export type MutationCreateFaqCreatorArgs = {
  body?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};


export type MutationCreateFriendArgs = {
  code: Scalars['String'];
  godoMemNo: Scalars['Int'];
  langCode: Scalars['String'];
};


export type MutationCreateInquiryArgs = {
  input: InquiryInput;
};


export type MutationCreateInquiryAnswerArgs = {
  input: CreateInquiryAnswerInput;
};


export type MutationCreateInquiryMemoArgs = {
  input: CreateInquiryMemoInput;
};


export type MutationCreateItemArgs = {
  conditionFirst?: InputMaybe<Scalars['String']>;
  conditionSecond?: InputMaybe<Scalars['String']>;
  couponNo: Scalars['Int'];
  creator?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<ImageKey>;
  langId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updater?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateModelConfigArgs = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
};


export type MutationCreateNoticeArgs = {
  body?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateOrderLicenseArgs = {
  names: Array<Scalars['String']>;
  orderNo: Scalars['String'];
  projects?: InputMaybe<Array<OrderLicenseProject>>;
  type: Scalars['String'];
};


export type MutationCreateOrderMemoArgs = {
  input: CreateOrderMemoInput;
};


export type MutationCreatePointBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  defaultApply?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  langId?: InputMaybe<Scalars['Int']>;
  payPoint?: InputMaybe<Scalars['String']>;
  pointUnit?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationCreatePointLimitPolicyArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  goodsNo?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['DateTime'];
  type: Scalars['String'];
};


export type MutationCreatePopupArgs = {
  createPopupInput: CreatePopupInput;
};


export type MutationCreateTermAgreeArgs = {
  sign: FileInput;
  term: FileInput;
};


export type MutationCreateUserArgs = {
  brand: CreateBrandInput;
  file?: InputMaybe<UserFileInput>;
  user: UserInput;
};


export type MutationCreateUserPlanArgs = {
  isExclusive: Scalars['Boolean'];
  type: Scalars['Float'];
};


export type MutationDeleteAconNotificationArgs = {
  type: Array<Scalars['String']>;
};


export type MutationDeleteAconfaqArgs = {
  id: Scalars['Int'];
  oldOrder?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteBadgeArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteBandBannerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteBannerArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['Float'];
};


export type MutationDeleteCouponBenefitArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteEventBannerArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteExtensionConfigArgs = {
  input: DeleteExtensionConfigInput;
};


export type MutationDeleteFaqArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFaqAconArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFaqCreatorArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteMainGroupArgs = {
  mainGroupId: Scalars['Int'];
};


export type MutationDeleteMemoArgs = {
  input: DocumentMemoInput;
};


export type MutationDeleteModelConfigArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMyReviewArgs = {
  id: Scalars['Int'];
  languageCode: Scalars['String'];
};


export type MutationDeleteNoticeArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePointBenefitArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeletePointLimitPolicyArgs = {
  id: Array<Scalars['Int']>;
};


export type MutationDeletePopupArgs = {
  deletePopupInput: DeletePopupInput;
};


export type MutationDeleteReviewReplyArgs = {
  id: Scalars['Int'];
};


export type MutationDenyArgs = {
  input: DocumentCommonInput;
};


export type MutationEditUserArgs = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankBook?: InputMaybe<FileInput>;
  bankId?: InputMaybe<Scalars['Int']>;
  bankName?: InputMaybe<Scalars['String']>;
  businessType?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  idCard?: InputMaybe<FileInput>;
  limitedTaxApl?: InputMaybe<FileInput>;
  memo: Scalars['String'];
};


export type MutationFixFaqArgs = {
  fixed?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationFixNoticeArgs = {
  fixed?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationGodoPasswordChangeArgs = {
  key: Scalars['String'];
  password: Scalars['String'];
};


export type MutationHideAssetsArgs = {
  input: HideAssetsInput;
};


export type MutationIncreaseDownloadCountArgs = {
  id: Scalars['Int'];
};


export type MutationIssueEventCouponArgs = {
  issueEventCouponInput: IssueEventCouponInput;
};


export type MutationMemoArgs = {
  input: DocumentMemoInput;
};


export type MutationModifyArgs = {
  input: DocumentCommonInput;
};


export type MutationModifyMemoArgs = {
  input: DocumentMemoInput;
};


export type MutationMoveCategoryArgs = {
  categoryId: Scalars['Float'];
  parentCategoryId?: InputMaybe<Scalars['Float']>;
  sortNo: Scalars['Float'];
};


export type MutationPackArgs = {
  input: PackageInput;
};


export type MutationPasswordChangeArgs = {
  key?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationPasswordChangeForAnonArgs = {
  account: Scalars['String'];
  brandName: Scalars['String'];
};


export type MutationPasswordChangeSendEmailArgs = {
  lang: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationProductAddCartArgs = {
  goodsNo: Scalars['String'];
};


export type MutationReadNotificationArgs = {
  type?: InputMaybe<Scalars['String']>;
};


export type MutationRegistUserMemoArgs = {
  id: Scalars['Int'];
  memo: Scalars['String'];
};


export type MutationRegisterReviewArgs = {
  contents: Scalars['String'];
  goodsNo: Scalars['Int'];
  goodsPt: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isSecret: Scalars['String'];
  languageCode: Scalars['String'];
  memNo: Scalars['Int'];
  orderNo?: InputMaybe<Scalars['String']>;
  writerId: Scalars['String'];
};


export type MutationRegisterReviewReplyArgs = {
  contents: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  languageCode: Scalars['String'];
  reviewId: Scalars['Int'];
};


export type MutationRegisterReviewV2Args = {
  input: RegisterReviewInput;
};


export type MutationRegisterSnsMemberArgs = {
  input: AconSnsMemberInput;
};


export type MutationRejectArgs = {
  input: DocumentCommonInput;
};


export type MutationRemoveClipArgs = {
  removeClipInput: RemoveClipInput;
};


export type MutationRemoveClipsArgs = {
  removeClipsInput: RemoveClipsInput;
};


export type MutationRemovePromotionsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationReorderAconfaqArgs = {
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  newOrder?: InputMaybe<Scalars['Int']>;
  oldOrder?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationReorderEventBannerArgs = {
  id: Scalars['Int'];
  newOrder: Scalars['Int'];
  oldOrder: Scalars['Int'];
};


export type MutationReorderFaqAconArgs = {
  id: Scalars['Int'];
  lang?: InputMaybe<Scalars['String']>;
  newOrder: Scalars['Int'];
  oldOrder: Scalars['Int'];
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationReorderFaqCreatorArgs = {
  id: Scalars['Int'];
  lang?: InputMaybe<Scalars['String']>;
  newOrder: Scalars['Int'];
  oldOrder: Scalars['Int'];
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationRequestArgs = {
  input: DocumentCommonInput;
};


export type MutationRequestUserRevisionArgs = {
  user: UserRevisionInput;
};


export type MutationRequestUserSettleStatusArgs = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankBook?: InputMaybe<FileInput>;
  bankId?: InputMaybe<Scalars['Int']>;
  bankName?: InputMaybe<Scalars['String']>;
  idCard?: InputMaybe<FileInput>;
  limitedTaxApl?: InputMaybe<FileInput>;
};


export type MutationRetryArgs = {
  input: DocumentCommonInput;
};


export type MutationReviseArgs = {
  input: DocumentCommonInput;
};


export type MutationSaveArgs = {
  input: SingleDocumentInput;
};


export type MutationSaveConfigArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveContentBodyArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveContentHeadArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveCopyrightArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveFileArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveMainImageArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveProjectArgs = {
  input: ProjectInput;
};


export type MutationSavePromotionArgs = {
  badgeList?: InputMaybe<Array<Badge>>;
  godoGoodsNo: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  imgCropInfos: Array<ImgCropType>;
  isAutoCreateReviewBenefit?: InputMaybe<Scalars['Boolean']>;
  languages: Array<Scalars['String']>;
  originImgKeys: Array<Scalars['String']>;
  reserveDate: Scalars['DateTime'];
  reserveImgCropInfo: ImgCropType;
  reserveOriginImgKey: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export type MutationSaveSubImageArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationSaveTitleArgs = {
  input: AdminSingleDocumentInput;
};


export type MutationScrapReviewArgs = {
  id: Scalars['Int'];
};


export type MutationSendAuthenticationCodeArgs = {
  email: Scalars['String'];
  lang: Scalars['String'];
};


export type MutationSetBenefitBurdenItemArgs = {
  burden?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSetBrandArgs = {
  brandId: Scalars['Int'];
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
};


export type MutationSetCouponBurdenItemArgs = {
  burden?: InputMaybe<Scalars['Int']>;
  sno?: InputMaybe<Scalars['Int']>;
};


export type MutationSetFavoriteReviewArgs = {
  reviewId: Scalars['Int'];
};


export type MutationSetMainGroupArgs = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
  mainGroupId: Scalars['Int'];
};


export type MutationSetProductFileViewArgs = {
  goodsNo: Scalars['String'];
  lang: Scalars['String'];
};


export type MutationSetSearchKeywordArgs = {
  creator?: InputMaybe<Scalars['Float']>;
  keyword: Scalars['String'];
  lang: Scalars['String'];
  updater?: InputMaybe<Scalars['Float']>;
};


export type MutationShowAssetsArgs = {
  input: ShowAssetsInput;
};


export type MutationUpdateAconfaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};


export type MutationUpdateBadgeArgs = {
  endDate: Scalars['DateTime'];
  godoGoodsNos: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['Float']>;
  isAutoCreate?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  texts: Array<BadgeI18nInput>;
};


export type MutationUpdateBandBannerArgs = {
  backgroundColor?: InputMaybe<Scalars['String']>;
  closeColor?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isBlankHref?: InputMaybe<Scalars['Boolean']>;
  isClosedShowId?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateBannerArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  href?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  imageKeys?: InputMaybe<ImageKeys>;
  langId?: InputMaybe<Scalars['Int']>;
  selectedProductsId?: InputMaybe<Array<Scalars['Int']>>;
  showOnlyForSelectedProducts?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateBrandPickArgs = {
  assetIds: Array<Scalars['Int']>;
  isOn: Scalars['Boolean'];
};


export type MutationUpdateBrandUserArgs = {
  account?: InputMaybe<Scalars['String']>;
  birth?: InputMaybe<Scalars['DateTime']>;
  brand?: InputMaybe<UpdateBrandInput>;
  changeLog?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contactEmails?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Float'];
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<UpdateUserPlanInput>;
  settle?: InputMaybe<UpdateUserSettleInput>;
  settlementEmails?: InputMaybe<Array<Scalars['String']>>;
  subType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCategoryArgs = {
  creator?: InputMaybe<Scalars['Float']>;
  i18n: Array<SetCategoryI18nInput>;
  id: Scalars['Float'];
  memo: Scalars['String'];
  parent?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  type?: InputMaybe<CategoryTypeEnum>;
  updater?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateCouponBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  godoCouponNo?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  langId?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateEventBannerArgs = {
  bannerTextFirst: Scalars['String'];
  bannerTextSecond: Scalars['String'];
  bannerTextThird: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<ImageKeys>;
  langId: Scalars['Int'];
  settingLink: Scalars['String'];
  startDate: Scalars['DateTime'];
};


export type MutationUpdateExtensionConfigArgs = {
  input: UpdateExtensionConfigInput;
};


export type MutationUpdateFaqArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateFaqAconArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};


export type MutationUpdateFaqCreatorArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lang: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  typeId: Scalars['Int'];
};


export type MutationUpdateFileArgs = {
  data: FileUpdateInput;
};


export type MutationUpdateFilesArgs = {
  data?: InputMaybe<Array<FileUpdateInput>>;
};


export type MutationUpdateGodoHitCountArgs = {
  goodsNo: Scalars['String'];
};


export type MutationUpdateInquiryAnswerArgs = {
  input: UpdateInquiryAnswerInput;
};


export type MutationUpdateItemArgs = {
  conditionFirst?: InputMaybe<Scalars['String']>;
  conditionSecond?: InputMaybe<Scalars['String']>;
  couponNo: Scalars['Int'];
  creator?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<ImageKey>;
  langId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updater?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateLicenseArgs = {
  input: UpdateLicenseInput;
};


export type MutationUpdateModelConfigArgs = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateNoticeArgs = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  langId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateOrderLicenseArgs = {
  adminEmail?: InputMaybe<Scalars['String']>;
  names: Array<Scalars['String']>;
  orderNo: Scalars['Float'];
  projects?: InputMaybe<Array<OrderLicenseProjects>>;
  type: Scalars['String'];
};


export type MutationUpdatePointBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']>>;
  applyTypeId?: InputMaybe<Scalars['Int']>;
  cnPointValue?: InputMaybe<Scalars['String']>;
  defaultApply?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  enPointValue?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  jpPointValue?: InputMaybe<Scalars['String']>;
  koPointValue?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  payPoint?: InputMaybe<Scalars['String']>;
  pointUnit?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdatePopupArgs = {
  updatePopupInput: UpdatePopupInput;
};


export type MutationUpdateProductViewCountArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateReviewArgs = {
  contents: Scalars['String'];
  goodsNo: Scalars['Int'];
  goodsPt: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isSecret: Scalars['String'];
  languageCode: Scalars['String'];
  memNo: Scalars['Int'];
  orderNo?: InputMaybe<Scalars['String']>;
  writerId: Scalars['String'];
};


export type MutationUpdateReviewReplyArgs = {
  contents: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  languageCode: Scalars['String'];
  reviewId: Scalars['Int'];
};


export type MutationUpdateReviewV2Args = {
  input: UpdateReviewInput;
};


export type MutationUpdateUserArgs = {
  id?: InputMaybe<Scalars['Float']>;
  user: UserUpdateInput;
};


export type MutationUpdateUserV2Args = {
  id?: InputMaybe<Scalars['Float']>;
  user: UserUpdateInput;
};


export type MutationUploadBlobStorageArgs = {
  inputs: UploadBlobStorageInput;
};


export type MutationUploadEditorImageArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUploadGoodsAtCategoryBatchArgs = {
  data: Array<CategoryGoods>;
};


export type MutationUploadGoodsAtMainGroupBatchArgs = {
  data: Array<MainGroupGoods>;
};

export type MyOrderReturn = {
  __typename?: 'MyOrderReturn';
  /** 최대/잔여 다운로드 횟수 정보 */
  downloadCountInfo?: Maybe<DownloadCountInfo>;
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Int'];
  /** 고도몰 주문번호 */
  godoOrderNo: Scalars['Float'];
  /** 몰 언어 정보 */
  languageCode: LanguageCodeEnum;
  /** 회원 번호 */
  memNo: Scalars['Int'];
  /** 상품 주문 상태 */
  orderGoodsStatus: OrderDisplayStatusEnum;
  /** 결제 가격 */
  price: Scalars['Int'];
  /** 상품 카드 정보 */
  product?: Maybe<Acon3dProduct>;
  /** 주문 체결 날짜 */
  regDt: Scalars['String'];
  /** 리뷰 작성 여부 */
  reviewId?: Maybe<Scalars['Int']>;
  /** 회원 아이디 */
  userId: Scalars['String'];
};

export type Notice = {
  __typename?: 'Notice';
  /** 공지사항 내용(바디) */
  body?: Maybe<Scalars['String']>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 고정글인 경우 고정 순서(번호) */
  fixed?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 공지사항을 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** 공지사항 제목 */
  title?: Maybe<Scalars['String']>;
  /** 공지사항 타입(공지, 이벤트, 일반, ...) */
  type?: Maybe<NoticeType>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type NoticeType = {
  __typename?: 'NoticeType';
  /** 공지사항 타입 코드(notice, event, normal, ...) */
  code: Scalars['String'];
  /** 공지사항 타입 id */
  id: Scalars['Float'];
  /** 공지사항 타입 이름(공지, 이벤트, 일반, ...) */
  name: Scalars['String'];
};

export type OrderAdminMemo = {
  __typename?: 'OrderAdminMemo';
  adminUser: AdminUser;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  memo: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type OrderAdminRefundModalReturn = {
  __typename?: 'OrderAdminRefundModalReturn';
  orderNo: Scalars['String'];
  refundCash: Scalars['Int'];
  refundPoint: Scalars['Int'];
  refundPrice: Scalars['Int'];
  settleKind: Scalars['String'];
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderCouponItems = {
  __typename?: 'OrderCouponItems';
  /** 쿠폰 사용 가능 카테고리 목록 */
  couponApplyCategory?: Maybe<Array<CouponApplyCategory>>;
  /** 쿠폰 사용 가능 상품 목록 */
  couponApplyGoods?: Maybe<Array<CouponApplyGoods>>;
  /** 쿠폰 사용 가능 타입 (category, brand, goods) */
  couponApplyProductType: Scalars['String'];
  /** 쿠폰 혜택 */
  couponBenefit: Scalars['Int'];
  /** 쿠폰 혜택 타입 (percent, fix) */
  couponBenefitType: Scalars['String'];
  /** 쿠폰 종류(sale, add, deposit) */
  couponKindType: Scalars['String'];
  /** 쿠폰 최대 혜택 */
  couponMaxBenefit: Scalars['Int'];
  /** 최소 주문 금액 */
  couponMinOrderPrice: Scalars['Int'];
  /** 최소 주문 금액 기준 (product, order), default: product */
  couponMinOrderType?: Maybe<Scalars['String']>;
  /** 쿠폰 ID */
  couponNo: Scalars['Int'];
  /** 쿠폰 할인 가격 (주문 쿠폰) */
  discountPrice?: Maybe<Scalars['Int']>;
  /** 쿠폰 사용 가능 종료 시간 */
  endDate: Scalars['DateTime'];
  /** 사용 가능 여부 */
  isAvailable: Scalars['Boolean'];
  /** 쿠폰 중복 사용 가능 여부 */
  isCouponApplyDuplicate: Scalars['Boolean'];
  /** 쿠폰 최대 혜택 제한 존재 여부 */
  isCouponMaxBenefit: Scalars['Boolean'];
  /** 상품 적용 쿠폰 여부 */
  isProductCoupon: Scalars['Boolean'];
  /** 유저 쿠폰 ID */
  memberCouponNo: Scalars['Int'];
  /** 쿠폰명 */
  name: Scalars['String'];
  /** 적용 가능한 상품 목록 */
  products?: Maybe<Array<OrderCouponProducts>>;
  /** 쿠폰 발급된 시간 */
  regDt: Scalars['DateTime'];
  /** 쿠폰 사용 가능 시작 시간 */
  startDate: Scalars['DateTime'];
};

export type OrderCouponProducts = {
  __typename?: 'OrderCouponProducts';
  /** 상품 할인 가격 */
  discountPrice: Scalars['String'];
  /** 상품 고유 번호 */
  godoGoodsNo: Scalars['String'];
};

export type OrderCouponReturn = {
  __typename?: 'OrderCouponReturn';
  appliedProductName?: Maybe<Scalars['String']>;
  couponBenefit: Scalars['String'];
  couponName: Scalars['String'];
  couponType: Scalars['String'];
};

export type OrderData = {
  __typename?: 'OrderData';
  /** 추가 필드 사용 여부 (y, n) */
  addFieldConf: Scalars['String'];
  /** 장바구니 추가 상품 개수 */
  cartAddGoodsCnt: Scalars['Int'];
  /** 장바구니 상품 개수 */
  cartGoodsCnt: Scalars['Int'];
  /** 주문 상품 정보 */
  cartInfo: Array<CartInfo>;
  /** 쿠폰 적용 타입 (all, member, coupon ...) */
  chooseCouponMemberUseType: Scalars['String'];
  /** 마일리지 / 쿠폰 선택 (y, n) */
  chooseMileageCoupon: Scalars['String'];
  /** 이메일 타입 */
  emailDomain: Array<Scalars['String']>;
  isGlobal: Scalars['Boolean'];
  /** 상품쿠폰 주문서 제한여부 */
  productCouponChangeLimitType: Scalars['String'];
  /** token */
  token: Scalars['String'];
  /** 상품 쿠폰 총 할인 금액 */
  totalCouponGoodsDcPrice: Scalars['Int'];
  /** 상품 쿠폰 총 적립 마일리지 */
  totalCouponGoodsMileage: Scalars['Int'];
  /** 회원 총 할인 금액 */
  totalMemberDcPrice: Scalars['Int'];
  /** 그룹별 회원 중복 총 할인 금액 */
  totalMemberOverlapDcPrice: Scalars['Int'];
  /** 총 결제 금액 (예정) */
  totalSettlePrice: Scalars['Int'];
  /** 회원 정보 */
  userInfo: UserInfo;
};

export enum OrderDisplayStatusEnum {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Completed = 'COMPLETED',
  DepositStandby = 'DEPOSIT_STANDBY',
  InPreparation = 'IN_PREPARATION',
  OnDelivery = 'ON_DELIVERY',
  PaymentAborted = 'PAYMENT_ABORTED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentTried = 'PAYMENT_TRIED',
  RefundAsked = 'REFUND_ASKED',
  RefundCompleted = 'REFUND_COMPLETED',
  ReturnAsked = 'RETURN_ASKED'
}

export type OrderDto = {
  __typename?: 'OrderDto';
  /** 상품 금액 */
  amount: Scalars['Int'];
  /** 주문 시 입력한 전화번호 */
  cellPhone?: Maybe<Scalars['String']>;
  /** 주문 시 입력한 이메일 */
  email?: Maybe<Scalars['String']>;
  /** 고도몰 장바구니 sno 배열 */
  godoCartSno: Array<Scalars['String']>;
  /** 고도몰 주문번호 */
  godoOrderId: Scalars['String'];
  /** 주문번호 */
  id: Scalars['String'];
  /** 주문 몰 */
  langCode: Scalars['String'];
  /** 주문 몰 */
  memNo: Scalars['Int'];
  /** 주문 시 입력한 이름 */
  name?: Maybe<Scalars['String']>;
  /** 결제 정보 */
  payment?: Maybe<OrderPaymentDto>;
  /** 주문 상태 */
  status?: Maybe<Scalars['String']>;
};

export type OrderEventHistory = {
  __typename?: 'OrderEventHistory';
  createdAt: Scalars['DateTime'];
  event: OrderEventTypeEnum;
  godoGoodsNo?: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
};

export enum OrderEventTypeEnum {
  Confirm = 'confirm',
  Download = 'download',
  Refund = 'refund',
  Settle = 'settle'
}

export type OrderGoodsPriceInfoArgs = {
  goodsNos: Array<Scalars['Int']>;
  orderNo: Scalars['String'];
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  /** 에러 메세지 */
  errorMessage?: Maybe<Scalars['String']>;
  /** 성공 여부 */
  isSuccess?: Maybe<Scalars['Boolean']>;
  /** 주문 데이터 */
  orderData?: Maybe<OrderData>;
};

export type OrderLicenseModel = {
  __typename?: 'OrderLicenseModel';
  /** 고유 ID */
  id: Scalars['Float'];
  /** 필명 정보 */
  names?: Maybe<Array<OrderLicenseNameModel>>;
  /** 주문번호 */
  orderNo: Scalars['String'];
  /** 프로젝트 정보 */
  projects?: Maybe<Array<OrderLicenseProjectModel>>;
  /** 유형 */
  type: Scalars['String'];
  /** 사용권 버전 */
  versions?: Maybe<Array<OrderLicenseVersionModel>>;
};

export type OrderLicenseNameModel = {
  __typename?: 'OrderLicenseNameModel';
  /** 고유 ID */
  id: Scalars['Float'];
  /** 필명 OR 작품 / 프로젝트명 */
  name: Scalars['String'];
  /** 주문의 사용권 ID  */
  orderLicenseId: Scalars['Float'];
};

export type OrderLicenseProject = {
  /** 상품번호 */
  goodsNo: Array<Scalars['String']>;
  /** 필명 */
  name: Scalars['String'];
};

export type OrderLicenseProjectGoodsModel = {
  __typename?: 'OrderLicenseProjectGoodsModel';
  /** 상품번호 */
  goodsNo: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 주문 사용권 프로젝트의 ID */
  orderLicenseProjectId: Scalars['Float'];
};

export type OrderLicenseProjectModel = {
  __typename?: 'OrderLicenseProjectModel';
  /** 프로젝트 상품들 */
  goods?: Maybe<Array<OrderLicenseProjectGoodsModel>>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 작품 / 프로젝트 명 */
  name: Scalars['String'];
  /** 주문 사용권 ID */
  orderLicenseId: Scalars['Float'];
};

export type OrderLicenseProjects = {
  /** 상품번호 */
  goodsNo: Array<Scalars['String']>;
  /** 필명 */
  name: Scalars['String'];
};

export type OrderLicenseVersionModel = {
  __typename?: 'OrderLicenseVersionModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 데이터 전체 JSON */
  contents?: Maybe<Scalars['String']>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 버전 Major */
  major?: Maybe<Scalars['Float']>;
  /** 버전 Minor */
  minor?: Maybe<Scalars['Float']>;
  /** 주문 사용권 ID */
  orderLicenseId: Scalars['Float'];
  /** 수정 일자 */
  updated: Scalars['DateTime'];
};

export type OrderPaymentDto = {
  __typename?: 'OrderPaymentDto';
  /** 입금 완료 일자 */
  confirmDate?: Maybe<Scalars['DateTime']>;
  /** 결제 통화 단위 정보 */
  currency: Scalars['String'];
  /** 결제 수단 */
  payMethod: Scalars['String'];
  /** 영수증 확인 URL (현금영수증, 매출전표) */
  receiptUrl?: Maybe<Scalars['String']>;
  /** 가상계좌 은행 코드 */
  vbankCode?: Maybe<Scalars['String']>;
  /** 가상계좌 예금자 명 */
  vbankHolder?: Maybe<Scalars['String']>;
  /** 가상계좌 송금 일자 */
  vbankIssuedAt?: Maybe<Scalars['String']>;
  /** 가상계좌 입금 은행 */
  vbankName?: Maybe<Scalars['String']>;
  /** 가상계좌 입금 계좌 번호 */
  vbankNum?: Maybe<Scalars['String']>;
};

export type OrderPaymentReturn = {
  __typename?: 'OrderPaymentReturn';
  /** 에이콘 자체 할인 금액 */
  aconDiscountPrice: Scalars['Int'];
  couponDiscountPrice: Scalars['Int'];
  /** 결제 금액 */
  payPrice: Scalars['Int'];
  /** 총 할인금액 */
  totalDiscountPrice: Scalars['Int'];
  /** 상품금액 */
  totalPrice: Scalars['Int'];
  useCash: Scalars['Int'];
  usePoint: Scalars['Int'];
};

export type OrderPrice = {
  __typename?: 'OrderPrice';
  /** 쿠폰 할인 금액 */
  couponGoodsDcPrice: Scalars['Float'];
  /** 정가 */
  fixedPrice: Scalars['Float'];
  /** 상품 할인 금액 */
  goodsDcPrice: Scalars['Float'];
  /** 상품별 가격 (상품 가격 + 옵션 가격 + 텍스트 옵션 가격 + 추가 상품 가격) */
  goodsPriceSubtotal: Scalars['Float'];
  /** 상품 합계 금액 */
  goodsPriceSum: Scalars['Float'];
  /** 회원 할인 금액 */
  memberDcPrice: Scalars['Float'];
  /** 회원 중복 할인 금액 */
  memberOverlapDcPrice: Scalars['Float'];
};

export type OrderProductClone = {
  __typename?: 'OrderProductClone';
  amount: Scalars['Float'];
  cellPhone: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  godoOrderId: Scalars['String'];
  id: Scalars['String'];
  langCode: LanguageCodeEnum;
  licenseUpdateHistories: Array<LicenseUpdateHistory>;
  licenses?: Maybe<Array<LicenseClone>>;
  memId: Scalars['String'];
  memNo: Scalars['Int'];
  memos?: Maybe<Array<OrderAdminMemo>>;
  name: Scalars['String'];
  orderProductItems: Array<OrderProductItemClone>;
  status: OrderStatus;
  useAconCash: Scalars['Int'];
  usePoint: Scalars['Int'];
};

export type OrderProductItemClone = {
  __typename?: 'OrderProductItemClone';
  acon3dProduct: Acon3dProduct;
  fixedPrice: Scalars['Int'];
  godoGoodsNo: Scalars['Int'];
  goodsDcPrice: Scalars['Int'];
  id: Scalars['Int'];
  isCanceled: Scalars['Boolean'];
  isDownloaded: Scalars['Boolean'];
  isRefunded: Scalars['Boolean'];
  price: Scalars['Int'];
  salePrice: Scalars['Int'];
};

export type OrderProductReturn = {
  __typename?: 'OrderProductReturn';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['Int'];
  /** 상품 명 */
  title: Scalars['String'];
};

export type OrderReturn = {
  __typename?: 'OrderReturn';
  /** 회원 ID */
  memId: Scalars['String'];
  /** 회원번호 */
  memNo: Scalars['String'];
  /** 주문 시 입력한 이메일 */
  orderEmail: Scalars['String'];
  /** 주문번호 */
  orderNo: Scalars['Float'];
  /** 주문 상품 정보들 */
  products: Scalars['String'];
};

export enum OrderStatus {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Fail = 'fail',
  PartialCancel = 'partialCancel',
  Ready = 'ready',
  Settled = 'settled',
  Waiting = 'waiting'
}

export enum OrderStatusEnum {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Completed = 'COMPLETED',
  DepositStandby = 'DEPOSIT_STANDBY',
  InPreparation = 'IN_PREPARATION',
  OnDelivery = 'ON_DELIVERY',
  PaymentAborted = 'PAYMENT_ABORTED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentTried = 'PAYMENT_TRIED',
  RefundAsked = 'REFUND_ASKED',
  RefundCompleted = 'REFUND_COMPLETED',
  ReturnAsked = 'RETURN_ASKED'
}

export type OrderViewDto = {
  __typename?: 'OrderViewDto';
  /** 은행 */
  bank: Scalars['String'];
  /** 계좌 */
  bankAccount: Scalars['String'];
  /** 예금주 */
  bankHolder: Scalars['String'];
  /** 입금자 */
  bankSender: Scalars['String'];
  cash?: Maybe<Scalars['String']>;
  /** 주문 상세 사용권들 */
  license?: Maybe<OrderLicenseModel>;
  /** 주문 정보 */
  order?: Maybe<OrderDto>;
  /** 주문일자 */
  orderDate: Scalars['DateTime'];
  /** 주문 상세 상품들 */
  orderProducts: Array<OrderViewProductDto>;
  /** pg사 실패사유 */
  pgFailReason: Scalars['String'];
  pgSettleCd: Array<Scalars['String']>;
  pgSettleNm: Array<Scalars['String']>;
  /** 영수증 */
  receipt?: Maybe<OrderViewReceiptDto>;
  receiptFl: Scalars['String'];
  settleKind: Scalars['String'];
  /** 결제방법 */
  settleMethod: Scalars['String'];
  /** 결제 */
  settleName: Scalars['String'];
  /** 결제금액 */
  settlePrice: Scalars['Int'];
  /** 영수증 */
  settleReceipt: Scalars['String'];
  /** 주문상태 */
  status: Scalars['String'];
  tax?: Maybe<Scalars['String']>;
  /** 배송비-쿠폰 할인금 */
  totalCouponDeliveryDcPrice: Scalars['Int'];
  /** 주문-쿠폰 할인금 */
  totalCouponGoodsDcPrice: Scalars['Int'];
  /** 상품-쿠폰 할인금 */
  totalCouponOrderDcPrice: Scalars['Int'];
  /** 총 할인금액 */
  totalGoodsDcPrice: Scalars['Int'];
  /** 상품 총 합계 */
  totalGoodsPrice: Scalars['Int'];
  /** 사용된 예치금 */
  useDeposit: Scalars['Int'];
  /** 사용된 마일리지 */
  useMileage: Scalars['Int'];
  /** 사용자 정보 */
  user: OrderViewUserInfoDto;
};

export type OrderViewProductDto = {
  __typename?: 'OrderViewProductDto';
  /** 상품번호 */
  goodsNo: Scalars['Float'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 판매 금액 */
  price: Scalars['Float'];
  product?: Maybe<Acon3dProduct>;
  /**
   * 정가
   * @deprecated realPrice -> price, price -> salePrice로 대체
   */
  realPrice: Scalars['Float'];
  /** 판매가 */
  salePrice: Scalars['Int'];
  /** 상태 */
  status: Scalars['String'];
  statusStr: Scalars['String'];
};

export type OrderViewReceiptDto = {
  __typename?: 'OrderViewReceiptDto';
  cashFl?: Maybe<Scalars['String']>;
  limitDateFl?: Maybe<Scalars['String']>;
  particularFl?: Maybe<Scalars['String']>;
  periodDay?: Maybe<Scalars['String']>;
  periodFl?: Maybe<Scalars['String']>;
  taxFl?: Maybe<Scalars['String']>;
  taxInvoiceLimitDate?: Maybe<Scalars['String']>;
};

export type OrderViewUserInfoDto = {
  __typename?: 'OrderViewUserInfoDto';
  /** 휴대폰 번호 */
  cellPhone: Scalars['String'];
  /** 이메일 */
  email: Scalars['String'];
  /** 사용자 이름 */
  name: Scalars['String'];
};

export enum Partner_Document_Status {
  Denied = 'DENIED',
  Draft = 'DRAFT',
  DraftOnOpen = 'DRAFT_ON_OPEN',
  InReview = 'IN_REVIEW',
  InReviewOnOpen = 'IN_REVIEW_ON_OPEN',
  OnSale = 'ON_SALE',
  Rejected = 'REJECTED',
  RejectedOnOpen = 'REJECTED_ON_OPEN',
  Suspended = 'SUSPENDED'
}

export type PackageChildren = {
  __typename?: 'PackageChildren';
  selected: Array<Asset>;
  unselected: Array<Asset>;
};

export type PackageInput = {
  childrenIds: Array<Scalars['Int']>;
  parentId: Scalars['Int'];
};

export type PackageOutput = {
  __typename?: 'PackageOutput';
  children: Array<Asset>;
  parent: Asset;
};

export type PaginatedDetailReview = {
  __typename?: 'PaginatedDetailReview';
  nextCursor?: Maybe<Scalars['Int']>;
  node?: Maybe<Review>;
  nth?: Maybe<Scalars['Int']>;
  prevCursor?: Maybe<Scalars['Int']>;
};

export type PaginatedReview = {
  __typename?: 'PaginatedReview';
  edge?: Maybe<ReviewEdge>;
  nodes?: Maybe<Array<Review>>;
};

export type PaymentPoint = {
  __typename?: 'PaymentPoint';
  /** 구매 완료 기본 지급 포인트 */
  defaultPaymentPoint: Scalars['Int'];
  /** 리뷰 작성 기본 지급 포인트 */
  defaultWriteReviewPoint: Scalars['Int'];
  /** 총 포인트 혜택 */
  godoGoodsNo: Scalars['Int'];
  /** 총 포인트 혜택 */
  point: Scalars['Int'];
};

export type Periods = {
  __typename?: 'Periods';
  /** 종료일자 */
  endDate: Scalars['DateTime'];
  /** 시작일자 */
  startDate: Scalars['DateTime'];
};

export type PointBenefit = {
  __typename?: 'PointBenefit';
  /** 포인트 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<PointBenefitApply>>;
  /** 포인트 혜택 적용 설정 */
  applyType?: Maybe<BenefitApply>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<PointBenefitBoardInfo>>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 기본 설정 여부 */
  defaultApply?: Maybe<Scalars['Boolean']>;
  /** 지급 내용 */
  description?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 프로모션에서 자동 생성한 혜택 여부 */
  isAutoCreatedByPromotion?: Maybe<Scalars['Boolean']>;
  /** 포인트 혜택을 적용할 다국어몰 */
  language?: Maybe<Language>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<PointBenefitOrderInfo>>;
  /** 포인트 지급 */
  payPoint: Scalars['String'];
  /** 포인트 지급 설정 */
  pointUnit: Scalars['String'];
  /** 포인트 혜택 수량 */
  quantity?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 포인트 헤택 타입 */
  type?: Maybe<BenefitType>;
  typeId: Scalars['Float'];
  /** 수정일 */
  updated: Scalars['DateTime'];
  /** 포인트 혜택 사용 개수 */
  useCount?: Maybe<Scalars['Float']>;
};

export type PointBenefitApply = {
  __typename?: 'PointBenefitApply';
  /** 고유 ID */
  id: Scalars['Float'];
  pointBenefitId: Scalars['Float'];
  /** 적용 설정 값 */
  value: Scalars['String'];
};

export type PointBenefitApplyClone = {
  __typename?: 'PointBenefitApplyClone';
  /** 고유 ID */
  id: Scalars['Float'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float'];
  /** 적용 설정 값 */
  value: Scalars['String'];
};

export type PointBenefitBoardInfo = {
  __typename?: 'PointBenefitBoardInfo';
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointBenefitBoardInfoClone = {
  __typename?: 'PointBenefitBoardInfoClone';
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointBenefitClone = {
  __typename?: 'PointBenefitClone';
  /** 포인트 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<PointBenefitApplyClone>>;
  /** 포인트 혜택 적용 설정 */
  applyType?: Maybe<BenefitApplyClone>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<PointBenefitBoardInfoClone>>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 기본 설정 여부 */
  defaultApply?: Maybe<Scalars['Boolean']>;
  /** 지급 내용 */
  description?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  /** 프로모션에서 자동 생성한 혜택 여부 */
  isAutoCreatedByPromotion?: Maybe<Scalars['Boolean']>;
  /** 포인트 혜택을 적용할 다국어몰 */
  language?: Maybe<LanguageClone>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<PointBenefitOrderInfoClone>>;
  /** 포인트 지급 */
  payPoint: Scalars['String'];
  /** 포인트 지급 설정 */
  pointUnit: Scalars['String'];
  /** 포인트 혜택 수량 */
  quantity?: Maybe<Scalars['String']>;
  /** 포인트 혜택 적용 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 포인트 헤택 타입 */
  type?: Maybe<BenefitTypeClone>;
  typeId: Scalars['Float'];
  /** 수정일 */
  updated: Scalars['DateTime'];
  /** 포인트 혜택 사용 개수 */
  useCount?: Maybe<Scalars['Float']>;
};

export type PointBenefitOrderInfo = {
  __typename?: 'PointBenefitOrderInfo';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointBenefitOrderInfoClone = {
  __typename?: 'PointBenefitOrderInfoClone';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean'];
  /** 지급 포인트 */
  payPoint: Scalars['Float'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float'];
  /** 회원 ID */
  userId: Scalars['String'];
};

export type PointItem = {
  __typename?: 'PointItem';
  /** 남은 포인트 액수 */
  afterPoint: Scalars['Int'];
  /** 포인트 지급 내용 */
  contents: Scalars['String'];
  /** 포인트 만료 일자 */
  deleteScheduleDate: Scalars['String'];
  /** 포인트 지급 액수 */
  point: Scalars['Int'];
  /** 포인트 지급 일자 */
  regDate: Scalars['String'];
};

export type PointList = {
  __typename?: 'PointList';
  /** 포인트 지급 내역 목록 */
  items: Array<PointItem>;
  /** 지급 내역 전체 갯수 */
  totalCount: Scalars['Int'];
};

export type Popup = {
  __typename?: 'Popup';
  /** 팝업 오늘하루 숨기기 버튼 표시 여부 */
  allowHide: Scalars['Boolean'];
  /** 팝업 내용(바디) */
  body?: Maybe<Scalars['String']>;
  /** 생성일 */
  created: Scalars['DateTime'];
  /** 팝업 표시 종료일 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 팝업를 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** 팝업 표시 url */
  location?: Maybe<Array<PopupLocation>>;
  /** 팝업 표시 시작일 */
  startDate: Scalars['DateTime'];
  /** 팝업 제목 */
  title?: Maybe<Scalars['String']>;
  /** 수정일 */
  updated: Scalars['DateTime'];
};

export type PopupHistory = {
  __typename?: 'PopupHistory';
  status: PopupStatus;
  updated: Scalars['DateTime'];
  updater: Scalars['String'];
};

export type PopupLocation = {
  __typename?: 'PopupLocation';
  /** 고유 id */
  id: Scalars['Float'];
  /** 팝업 id */
  popupId: Scalars['Float'];
  /** 팝업을 표시할 url */
  url: Scalars['String'];
};

export enum PopupLocationV2 {
  Acon3d = 'acon3d',
  PartnerHub = 'partnerHub'
}

export enum PopupOrderCol {
  Created = 'created',
  EndDate = 'endDate',
  StartDate = 'startDate'
}

export type PopupOutput = {
  __typename?: 'PopupOutput';
  /** 버튼 문구 */
  buttonText?: Maybe<Scalars['String']>;
  /** 연결 URL */
  connectedUrl?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  /** 등록자 */
  creator: AdminUser;
  /** 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean'];
  id: Scalars['Int'];
  /** 팝업 이미지 아이디 */
  image?: Maybe<UploadFile>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen: Scalars['Boolean'];
  /** 일시 정지용 */
  isStopped?: Maybe<Scalars['Boolean']>;
  /** 언어 */
  language: LanguageCodeEnum;
  /** 위치 */
  location: PopupLocationV2;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean'];
  popupHistories: Array<PopupHistory>;
  /** 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations: Array<PopupSubLocation>;
  /** 서브 텍스트 */
  subText?: Maybe<Scalars['String']>;
  /** 팝업 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 팝업 제목(관리용) */
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  /** 수정자 */
  updater: AdminUser;
  /** 하위 페이지 url */
  urls: Array<PopupUrlOutput>;
};

export enum PopupStatus {
  End = 'end',
  Open = 'open',
  Pause = 'pause',
  Standby = 'standby',
  TemporarySaved = 'temporarySaved',
  UnPause = 'unPause'
}

export type PopupSubLocation = {
  __typename?: 'PopupSubLocation';
  id: Scalars['Int'];
  /** 소속된 팝업 */
  popup: PopupV2;
  /** 하위 위치(메인 구분용) */
  subLocation: PopupSubLocationEnum;
};

export enum PopupSubLocationEnum {
  Game = 'game',
  Toon = 'toon'
}

export type PopupSubLocationInput = {
  id?: InputMaybe<Scalars['Int']>;
  popupId?: InputMaybe<Scalars['Int']>;
  subLocation: PopupSubLocationEnum;
};

export type PopupUploadFileInput = {
  id: Scalars['Int'];
};

export type PopupUrl = {
  __typename?: 'PopupUrl';
  id: Scalars['Int'];
  /** 하위페이지 노출여부 */
  isSubPageIncluded: Scalars['Boolean'];
  /** 소속된 팝업 */
  popup: PopupV2;
  /** 하위 위치(메인 구분용) */
  subLocation?: Maybe<PopupSubLocationEnum>;
  /** 하위 페이지 url */
  url: Scalars['String'];
};

export type PopupUrlOutput = {
  __typename?: 'PopupUrlOutput';
  id?: Maybe<Scalars['Int']>;
  popupId?: Maybe<Scalars['Int']>;
  subLocation?: Maybe<PopupSubLocationEnum>;
  urls: Array<UrlsOutput>;
};

export type PopupV2 = {
  __typename?: 'PopupV2';
  /** 버튼 문구 */
  buttonText?: Maybe<Scalars['String']>;
  /** 버튼 연결 링크 */
  connectedUrl?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  /** 등록자 */
  creator: AdminUser;
  /** 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean'];
  id: Scalars['Int'];
  /** 팝업 이미지 아이디 */
  image?: Maybe<UploadFile>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen: Scalars['Boolean'];
  /** 일시 정지용 */
  isStopped?: Maybe<Scalars['Boolean']>;
  /** 언어 */
  language: LanguageCodeEnum;
  /** 팝업을 노출할 서비스 */
  location: PopupLocationV2;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean'];
  popupHistories?: Maybe<Array<PopupHistory>>;
  /** 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations?: Maybe<Array<PopupSubLocation>>;
  /** 서브 텍스트 */
  subText?: Maybe<Scalars['String']>;
  /** 팝업 텍스트 */
  text?: Maybe<Scalars['String']>;
  /** 팝업 제목(관리용) */
  title: Scalars['String'];
  updated: Scalars['DateTime'];
  /** 수정자 */
  updater: AdminUser;
  /** 하위 페이지 url */
  urls?: Maybe<Array<PopupUrl>>;
};

export type Product = {
  __typename?: 'Product';
  /** 상품 응용프로그램 필드 */
  applications?: Maybe<Array<Application>>;
  /** 해당 상품에 표시(또는 숨기기)할 배너들 */
  banners?: Maybe<Array<Banner>>;
  brand: Brand;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Float']>;
  /** 상품 카테고리 */
  categories?: Maybe<Array<Category>>;
  /** 상품 의견 리스트 */
  comments?: Maybe<Array<ProductComment>>;
  /** 수수료 */
  commission?: Maybe<Scalars['Float']>;
  /** 상품 본문 */
  contents?: Maybe<Scalars['String']>;
  /** 저작권 */
  copyright?: Maybe<Scalars['String']>;
  /** 상품 설명 */
  description?: Maybe<Scalars['String']>;
  /** 문서 ID */
  docId: Scalars['String'];
  /** 동적 요소 */
  dynamicItem?: Maybe<Scalars['String']>;
  /** 상품 파일 구성 */
  fileConfigList?: Maybe<Array<ProductFileConfig>>;
  /** 상품 파일 확장자 */
  fileExtensionList?: Maybe<Array<ProductFileExtension>>;
  /** 상품 파일 업데이트 내역 */
  fileUpdateList?: Maybe<Array<ProductFileUpdate>>;
  /** 고도몰 상품번호 */
  godoGoodsNo?: Maybe<Scalars['Int']>;
  /** 고유 ID */
  id: Scalars['Float'];
  /** 상품 이미지 리스트 */
  imageList?: Maybe<Array<Maybe<ProductImage>>>;
  /** 삭제여부 */
  isDelete: Scalars['Boolean'];
  /** 수정요청 여부 */
  isEdit: Scalars['Boolean'];
  /** 검색 키워드 */
  keywords?: Maybe<Scalars['String']>;
  language: Language;
  languageCode: Scalars['String'];
  /** 업로드 한 파일 */
  model?: Maybe<UploadFile>;
  /** 모델구성 */
  modelConfigList?: Maybe<Array<ModelConfig>>;
  /** 모델 파일 ID */
  modelFileId?: Maybe<Scalars['Float']>;
  /** 모델 파일 명 */
  modelFileName?: Maybe<Scalars['String']>;
  /** 상품 오픈일 */
  openDate?: Maybe<Scalars['DateTime']>;
  originLanguage: Language;
  /** 상품 금액 */
  price?: Maybe<Scalars['Float']>;
  /** 연관상품 */
  related?: Maybe<Array<ProductRelated>>;
  /** 검토 요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 상품 할인가 */
  salePrice?: Maybe<Scalars['Float']>;
  /** 상품 상태 */
  status: Scalars['String'];
  /** 상품 제목 */
  title: Scalars['String'];
  /** 파일 버전 */
  versions?: Maybe<Array<ProductVersion>>;
};

export type ProductCardsSummary = {
  __typename?: 'ProductCardsSummary';
  /** 상품 정보 */
  products: Array<Acon3dProduct>;
  sort?: Maybe<ProductListSort>;
  /** 사용 여부 */
  toggle?: Maybe<Scalars['Boolean']>;
};

export type ProductComment = {
  __typename?: 'ProductComment';
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 메세지 */
  message?: Maybe<Scalars['String']>;
  productId: Scalars['Float'];
  /** 메세지 유형 */
  type?: Maybe<Scalars['String']>;
};

export type ProductFileConfig = {
  __typename?: 'ProductFileConfig';
  /** 고유 ID */
  id: Scalars['Float'];
  name: Scalars['String'];
  productId: Scalars['Float'];
  size: Scalars['Float'];
};

export type ProductFileExtension = {
  __typename?: 'ProductFileExtension';
  extensionConfig: ExtensionConfig;
  extensionConfigId: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  productId: Scalars['Int'];
};

export type ProductFileUpdate = {
  __typename?: 'ProductFileUpdate';
  created: Scalars['DateTime'];
  /** 업데이트 일자 */
  date?: Maybe<Scalars['DateTime']>;
  /** 고유 ID */
  id: Scalars['Float'];
  name: Scalars['String'];
  productId: Scalars['Float'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  created: Scalars['DateTime'];
  /** 크롭 높이 */
  cropHeight?: Maybe<Scalars['Float']>;
  /** 크롭 너비 */
  cropWidth?: Maybe<Scalars['Float']>;
  /** 크롭 x 위치 */
  cropX?: Maybe<Scalars['Float']>;
  /** 크롭 y 위치 */
  cropY?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<UploadFile>;
  productId: Scalars['Float'];
  /** 이미지 유형 */
  type: Scalars['String'];
  uploadFileId?: Maybe<Scalars['Float']>;
};

export type ProductInquiries = {
  __typename?: 'ProductInquiries';
  count: Scalars['Float'];
  data: Array<ProductInquiry>;
  total: Scalars['Float'];
};

export type ProductInquiry = {
  __typename?: 'ProductInquiry';
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  memNo: Scalars['Float'];
  sno: Scalars['Float'];
  status: Scalars['String'];
  subject: Scalars['String'];
};

export type ProductLang = {
  __typename?: 'ProductLang';
  /** 브랜드 명 */
  creator: Scalars['String'];
  /** 브랜드 명 */
  itemNum: Scalars['Int'];
  /** 제목 */
  title: Scalars['String'];
};

export type ProductListPageReturn = {
  __typename?: 'ProductListPageReturn';
  badgeName: Scalars['String'];
  /** 혜택 종료 일자 */
  benefitEndDate?: Maybe<Scalars['DateTime']>;
  /** 혜택 시작 일자 */
  benefitStartDate?: Maybe<Scalars['DateTime']>;
  /** 상품 브랜드 */
  brand?: Maybe<Array<GodoProductBrandModel>>;
  brandCd: Scalars['String'];
  brandName: Scalars['String'];
  cateCd?: Maybe<Scalars['String']>;
  /** 상품 카테고리들 */
  category?: Maybe<Array<GodoProductCategoryModel>>;
  /** 상품 상세 이미지 */
  detailImageUrl: Scalars['String'];
  extension: Array<ExtensionConfig>;
  godoCreated: Scalars['DateTime'];
  goodsNo: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  isAconOnly: Scalars['Boolean'];
  /** 판매 종료 여부 */
  isEndSales: Scalars['Boolean'];
  /** 프로모션 여부 */
  isPromotion: Scalars['Boolean'];
  /** 품절 여부 */
  isSoldOut: Scalars['Boolean'];
  mainImageUrl: Scalars['String'];
  orderCount: Scalars['Float'];
  price: Scalars['Float'];
  /** 상품들 */
  products?: Maybe<Array<Product>>;
  /** 판매 종료 여부 */
  promotionEndDate?: Maybe<Scalars['DateTime']>;
  reviewCount: Scalars['Float'];
  salePrice: Scalars['Float'];
  subImageUrl: Scalars['String'];
  title: Scalars['String'];
  viewCount: Scalars['Float'];
};

export type ProductListReturn = {
  __typename?: 'ProductListReturn';
  /** 카테고리 코드 */
  cateCd?: Maybe<Scalars['String']>;
  /** 개수 */
  count?: Maybe<Scalars['Float']>;
  /** 비슷한 작품 항목들 */
  items?: Maybe<Array<ProductViewCardList>>;
};

export enum ProductListSort {
  Newest = 'NEWEST',
  Popularity = 'POPULARITY',
  Recommended = 'RECOMMENDED'
}

export type ProductRelated = {
  __typename?: 'ProductRelated';
  from: Scalars['Float'];
  to: Scalars['Float'];
};

export enum ProductType {
  Game = 'GAME',
  Webtoon = 'WEBTOON'
}

export type ProductVersion = {
  __typename?: 'ProductVersion';
  /** 데이터 전체 */
  contents?: Maybe<Scalars['String']>;
  /** 버전 생성일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 버전 Major */
  major?: Maybe<Scalars['Float']>;
  /** 버전 Minor */
  minor?: Maybe<Scalars['Float']>;
  /** 오픈된 상품정보 */
  openProductInfo?: Maybe<Product>;
  /** 상품 ID */
  productId?: Maybe<Scalars['Float']>;
  /** 선택된 상품정보 */
  productInfo?: Maybe<Product>;
};

export type ProductViewCardList = {
  __typename?: 'ProductViewCardList';
  /** 브랜드 명 */
  brand: Scalars['String'];
  /** 브랜드 코드 */
  brandCd: Scalars['String'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 가격 */
  price: Scalars['String'];
  /** 가격 */
  realPrice: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  brand: BrandClone;
  brandId: Scalars['Int'];
  checkerAnswers?: Maybe<Array<CheckerAnswer>>;
  contents: Array<Content>;
  /** doc ID / 국가별 여러쌍 상품들을 하나로 묶어준 컬럼 */
  docId?: Maybe<Scalars['String']>;
  godoGoodsNo?: Maybe<Scalars['Int']>;
  goodsItems?: Maybe<Array<Goods>>;
  id: Scalars['Int'];
  openDate?: Maybe<Scalars['DateTime']>;
  projectCategories?: Maybe<Array<CategoryClone>>;
  /** 프로젝트 검토 요청일 */
  requestDate?: Maybe<Scalars['DateTime']>;
  /** 프로젝트 검토 상태 */
  status: ProjectStatusEnum;
};

export enum ProjectImageEnum {
  Main = 'main',
  SubImageBottomRight = 'subImageBottomRight',
  SubImageLeft = 'subImageLeft',
  SubImageTopRight = 'subImageTopRight',
  Thumbnail = 'thumbnail'
}

export type ProjectInput = {
  brandId: Scalars['Int'];
  categories?: InputMaybe<Array<Scalars['Int']>>;
  checkerAnswer?: InputMaybe<CheckerAnswerInput>;
  content: ContentInput;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  goods: GoodsInput;
  id?: InputMaybe<Scalars['Int']>;
  languageCode: LanguageCodeEnum;
};

export enum ProjectStatusEnum {
  Approved = 'approved',
  Draft = 'draft',
  Inprogress = 'inprogress',
  Open = 'open',
  Reject = 'reject',
  Request = 'request'
}

export type Promotion = {
  __typename?: 'Promotion';
  /** 프로모션 뱃지 리스트 */
  badgeList?: Maybe<Array<PromotionBadge>>;
  /** 프로모션 혜택 리스트 */
  benefitList?: Maybe<Array<PromotionBenefit>>;
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 프로모션 이미지 리스트 */
  imageList?: Maybe<Array<PromotionImage>>;
  /** 리뷰 작성 시 포인트 지급 혜택을 자동 생성할 지 여부 */
  isAutoCreateReviewBenefit?: Maybe<Scalars['Boolean']>;
  /** 프로모션 언어 리스트 */
  languageList?: Maybe<Array<PromotionLang>>;
  /** 프로모션이 저장되는 시점에 해당되는 상품의 브랜드 명입니다. */
  productBrandName: Scalars['String'];
  /** 프로모션이 저장되는 시점에 해당되는 상품의 제목입니다. */
  productTitle: Scalars['String'];
  /** 예약날짜 */
  reserveDate?: Maybe<Scalars['DateTime']>;
  /** 프로모션의 타입입니다. (cosmic, comet) */
  type: Scalars['String'];
};

export type PromotionBadge = {
  __typename?: 'PromotionBadge';
  /** 고유 ID */
  id: Scalars['Float'];
  item?: Maybe<Array<PromotionBadgeI18n>>;
  promotionId: Scalars['Float'];
  /** 혜택 종류(point, coupon) */
  type: Scalars['String'];
};

export type PromotionBadgeI18n = {
  __typename?: 'PromotionBadgeI18n';
  /** 제공 가격 */
  amount: Scalars['String'];
  /** 고유 ID */
  id: Scalars['Float'];
  language?: Maybe<Language>;
  /** 프로모션 뱃지 id */
  promotionBadgeId: Scalars['Float'];
};

export type PromotionBenefit = {
  __typename?: 'PromotionBenefit';
  amount: Scalars['Float'];
  /** 할인된 결과물에 대한 금액 */
  discount: Scalars['Float'];
  /** 스냅샷된 할인 단위 */
  discountUnit: Scalars['String'];
  /** 스냅샷된 혜택 종료일자 */
  endDate: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 스냅샷된 혜택 명 */
  name: Scalars['String'];
  promotionId: Scalars['Float'];
  /** 스냅샷된 혜택 시작일자 */
  startDate: Scalars['DateTime'];
  /** 혜택 유형 (1,2,3이 존재) */
  type: Scalars['Int'];
};

export type PromotionImage = {
  __typename?: 'PromotionImage';
  created: Scalars['DateTime'];
  /** 크롭 높이 */
  cropHeight?: Maybe<Scalars['Float']>;
  /** 크롭 너비 */
  cropWidth?: Maybe<Scalars['Float']>;
  /** 크롭 x 위치 */
  cropX?: Maybe<Scalars['Float']>;
  /** 크롭 y 위치 */
  cropY?: Maybe<Scalars['Float']>;
  /** 고유 ID */
  id: Scalars['Float'];
  originUploadFile?: Maybe<UploadFile>;
  originUploadFileId: Scalars['Float'];
  promotionId: Scalars['Float'];
  resizeUploadFile?: Maybe<UploadFile>;
  resizeUploadFileId: Scalars['Float'];
  /** 이미지 유형 */
  type: Scalars['Float'];
};

export type PromotionLang = {
  __typename?: 'PromotionLang';
  /** 고유 ID */
  id: Scalars['Float'];
  language: Language;
  promotionId: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  GODO_EVENT_BOARD_DELETE: Scalars['Boolean'];
  GODO_EVENT_BOARD_MODIFY: Scalars['Boolean'];
  GODO_EVENT_BOARD_WRITE: Scalars['Float'];
  GODO_EVENT_CHECK_BENEFIT: Scalars['Boolean'];
  GODO_EVENT_ORDER_COMPLETE: Scalars['Boolean'];
  GODO_EVENT_ORDER_REFUND: Scalars['Boolean'];
  GODO_EVENT_ORDER_SETTLE: Scalars['Boolean'];
  GODO_ORDER_LOG: Scalars['Boolean'];
  GODO_UPDATE_ISREFUNDED: Scalars['Boolean'];
  GetCouponIssueCount?: Maybe<Scalars['Float']>;
  aconNotifications: Array<AconNotification>;
  adminDocument?: Maybe<AdminSingleDocumentOutput>;
  adminDocuments: Array<AdminMultiDocument>;
  adminDownload: Scalars['String'];
  adminInquiries: Array<Inquiry>;
  adminInquiry: Inquiry;
  adminInquiryCount: Scalars['Int'];
  adminOrderCouponInfo: Array<OrderCouponReturn>;
  adminOrderEventHistory: Array<OrderEventHistory>;
  adminOrderGoodsPriceInfo: OrderAdminRefundModalReturn;
  adminOrderPaymentInfo: OrderPaymentReturn;
  allPopups: Array<PopupV2>;
  assetFileInfo: Asset;
  assets: Array<Asset>;
  banks: Array<Bank>;
  benefitProducts: Array<Acon3dProduct>;
  bestProducts: Array<Scalars['Int']>;
  brand?: Maybe<Brand>;
  brandAssets: Array<Asset>;
  brandPick: BrandPick;
  brandPickSummary: ProductCardsSummary;
  brandProductsWithCategories: Array<BrandProductsWithCategoriesOutput>;
  /** 유저의 장바구니 갯수를 가져오는 쿼리. 장바구니 아이콘에 숫자를 표시하고, 상품에서 장바구니에 담았는지 여부를 표현하기 위해 사용합니다.이전의 query godoUserCart()를 대체하는 쿼리입니다. */
  cart: Array<CartOutput>;
  /** 장바구니 상품 목록을 가져오는 메소드 입니다. */
  carts: Array<CartItem>;
  categories: Array<GodoCategory>;
  categoriesV2: Array<CategoryOutput>;
  categoriesV3: Array<CategoryOutputV2>;
  checkAuthentication: Scalars['Boolean'];
  checkExceptionWorking: SentryTestResponse;
  checkExistMember: Scalars['Boolean'];
  checkIssuanceTempPassword: Scalars['Boolean'];
  checkQueryDataWorking: SentryTestResponse;
  /** 회원이 사업자인지 확인하는 메소드 입니다. */
  checkedBusiness: Scalars['Boolean'];
  checkedPasswordChange: Scalars['Boolean'];
  clips: Array<Clip>;
  compare: Array<AdminSingleDocumentOutput>;
  /** 우주최저가 누적 판매 금액, 누적 탑승자 수를 가져오는 메소드 입니다. */
  cosmicDealsStatisticsV2: CosmicDealsStatistics;
  couponTest?: Maybe<Scalars['Boolean']>;
  document: SingleDocumentOutput;
  documents: Array<ListDocumentOutput>;
  exchangeRate: Scalars['Float'];
  existsPenName: Scalars['Boolean'];
  existsUser: Scalars['Boolean'];
  faq?: Maybe<HubAdminPost>;
  faqAcon?: Maybe<FaqAconModel>;
  faqAconCount: Scalars['Float'];
  faqAconTypes: Array<FaqAconTypeModel>;
  faqAcons: Array<FaqAconModel>;
  /** 입점 faq 항목들을 가져오는 쿼리 */
  faqCreator?: Maybe<FaqCreatorModel>;
  /** 입점 faq 카테고리 항목들을 가져오는 쿼리 */
  faqCreatorCategories?: Maybe<Array<FaqCreatorModel>>;
  /** 입점 faq 항목들을 개수를 가져오는 쿼리 */
  faqCreatorCount: Scalars['Int'];
  /** 입점 faq 유형들을 가져오는 메소드입니다. */
  faqCreatorTypes: Array<FaqCreatorTypeModel>;
  /** 입점 faq 항목들을 가져오는 쿼리 */
  faqCreators: Array<FaqCreatorModel>;
  faqs: HubAdminPosts;
  file: UploadFile;
  /** 친구 수를 가져오는 쿼리 */
  friendsCount: Scalars['Int'];
  fundingProductNos: Array<Scalars['Int']>;
  getAconfaq?: Maybe<FaqAconModel>;
  getAconfaqTypes: Array<FaqAconTypeModel>;
  getAconfaqs: Array<FaqAconModel>;
  getAconfaqsTotalCount: Scalars['Float'];
  getAdminOrder: OrderProductClone;
  getAdminOrders: Array<OrderProductClone>;
  getAdminOrdersCount: Scalars['Int'];
  getAdminReview: Review;
  getAdminReviews: Array<Review>;
  getAdminReviewsCount: Scalars['Int'];
  getApplications: Array<Application>;
  getAprilAttendanceEventDates: Array<Scalars['Float']>;
  getAttendanceEventDates: Array<Scalars['Float']>;
  getBadge?: Maybe<BadgeReturn>;
  getBadgeCount: Scalars['Float'];
  getBadges: Array<BadgeReturn>;
  getBandBanner?: Maybe<BandBanner>;
  getBandBannerClosed: Array<BandBannerClosed>;
  getBandBannerForPage?: Maybe<Array<BandBannerItems>>;
  getBandBannerTypes: Array<BandBannerType>;
  getBandBanners: Array<BandBanner>;
  getBandBannersTotalCount: Scalars['Float'];
  getBanner?: Maybe<BannerClone>;
  getBannerTypes: Array<BannerTypeClone>;
  getBanners: Array<BannerClone>;
  getBannersByGodoNo?: Maybe<Array<BannerClone>>;
  getBannersTotalCount: Scalars['Float'];
  getBenefitBurdenCount: Scalars['Float'];
  getBenefitBurdenList: Array<BenefitBurden>;
  getBrands: Array<Brand>;
  getBrandsNotHavingUser: Array<Brand>;
  getCashList?: Maybe<CashList>;
  getCategories: Array<Category>;
  getCategoriesByLang: Array<Category>;
  getCategory?: Maybe<GodoCategory>;
  getCounts: Scalars['Int'];
  getCouponBenefit?: Maybe<CouponBenefitItem>;
  getCouponBenefits?: Maybe<Array<CouponBenefitItem>>;
  getCouponBenefitsTotalCount: Scalars['Float'];
  getCouponBurdenCount: Scalars['Float'];
  getCouponBurdenList?: Maybe<Array<CouponBurden>>;
  getCouponItems?: Maybe<Array<CouponItems>>;
  getCouponList: CouponList;
  getDepositList: DepositList;
  getDisplayItems?: Maybe<Array<DisplayCouponBanner>>;
  getDownloadLogs: DownloadLogResponse;
  /** 이벤트 배너 항목을 가져오는 메소드 입니다. */
  getEventBannerItem?: Maybe<EventBannerItems>;
  /** 이벤트 배너 목록을 가져오는 메소드 입니다. */
  getEventBannerItems?: Maybe<Array<EventBannerItems>>;
  /** 이벤트 배너 총 갯수를 가져오는 메소드입니다. */
  getEventBannerTotalCount: Scalars['Float'];
  getExtensionConfigs?: Maybe<Array<ExtensionConfig>>;
  getExtensions: Array<Category>;
  getFaq?: Maybe<Faq>;
  getFaqTypes: Array<FaqType>;
  getFaqs: Array<Faq>;
  getFaqsTotalCount: Scalars['Float'];
  getFilterTransGodoCategoryChildItems: Array<GodoDisplayCategory>;
  getGodoBandBannerInfo: BandBannerInfo;
  getGodoBannerInfo: BannerInfo;
  getGodoBrandName: Scalars['String'];
  getGodoPopup?: Maybe<Array<GodoPopupInfo>>;
  getGoodsBuyers: Array<GoodsBuyers>;
  getIssuedCount: IssuedCount;
  getItem?: Maybe<CouponBanner>;
  getItems?: Maybe<Array<CouponBanner>>;
  getLanguages: Array<Language>;
  getMainGroups: Array<MainGroup>;
  getModelConfigs?: Maybe<Array<ModelConfig>>;
  getMyClips: Array<Clip>;
  getMyOrders: Array<MyOrderReturn>;
  getMyReview: Review;
  getMyReviews?: Maybe<Array<Review>>;
  getMyReviewsByGodoGoodsNo?: Maybe<Array<Review>>;
  getNotice?: Maybe<Notice>;
  getNoticeTypes: Array<NoticeType>;
  getNotices: Array<Notice>;
  getNoticesTotalCount: Scalars['Float'];
  getOrderCouponItems?: Maybe<Array<OrderCouponItems>>;
  getOrderInfo: OrderInfo;
  getOrderNoWithoutReview?: Maybe<Scalars['String']>;
  getPaymentPoint?: Maybe<Array<PaymentPoint>>;
  getPhotoReview: PaginatedDetailReview;
  getPhotoReviewTotalCount: Scalars['Int'];
  getPhotoReviews: PaginatedReview;
  getPointBenefit?: Maybe<PointBenefit>;
  getPointBenefitApply: Array<BenefitApply>;
  getPointBenefitTypes: Array<BenefitType>;
  getPointBenefits?: Maybe<Array<PointBenefit>>;
  getPointBenefitsTotalCount: Scalars['Float'];
  getPointLimitPolicy: BenefitLimit;
  getPointLimitPolicyCount: Scalars['Int'];
  getPointLimitPolicyList: Array<BenefitLimit>;
  getPointList: PointList;
  getPopupsHere: Array<PopupV2>;
  getProductLang: Array<ProductLang>;
  getProjectCount: Scalars['Int'];
  getPromotion: Promotion;
  getPromotionCount: Scalars['Int'];
  getPromotions?: Maybe<Array<DisplayPromotionItem>>;
  getReceivePointByReview?: Maybe<Scalars['Int']>;
  getReviewPointItems?: Maybe<Array<PointBenefit>>;
  getSearchKeywords: Array<SearchKeyword>;
  getSettle: SettleDetail;
  getSettleAllList?: Maybe<Array<Settle>>;
  getSettleAllListV2?: Maybe<Array<Settle>>;
  getSettleAllPrice: Scalars['Float'];
  getSettleCount: SettleCount;
  getSettleList?: Maybe<Array<Settle>>;
  getSettleListV2?: Maybe<Array<Settle>>;
  getSettleV2: SettleDetail;
  getTerm: Term;
  getTermAll: Array<Term>;
  /** 포인트 합산을 가져오는 쿼리 */
  getTotalInvitationPoint: Scalars['Int'];
  getTransGodoCategoryChildItems: Array<GodoDisplayCategory>;
  getTransGodoCategoryItems: Array<GodoDisplayCategory>;
  getUserApprovalDt: Scalars['DateTime'];
  getUserSearch: UserPaginatedResponse;
  getUsers: UserPaginatedResponse;
  /** 고띄몰의 정의띘어 있는 혜성특가 리스트를 가져오는 메소드입니다. */
  godoCometDealList?: Maybe<Array<GodoCosmicDeal>>;
  /** 고띄몰의 정의띘어 있는 우주최저가 리스트를 가져오는 메소드입니다. */
  godoCosmicDealList?: Maybe<Array<GodoCosmicDeal>>;
  /** 카테고리 번호를 이용하여 카테고리 명, 상품 갯수를 가져오는 메소드입니다. */
  godoGoodsListCategoryResult: GodoGoodsListCategoryResult;
  /** 문의 페이지에서 자기 자신의 주문별 상품 조회에 사용될 메소드 입니다. */
  godoMyOrders: Array<GodoOrderGoodsNo>;
  /** 장바구니 정보를 가져오는 쿼리 */
  godoUserCart: Array<GodoUserCartReturn>;
  godoUserInfo?: Maybe<GodoUserInfo>;
  /** 사용자 초대코드를 가져오는 쿼리 */
  godoUserInvitationCode: Scalars['String'];
  /** 사용자 명을 가져오는 쿼리 */
  godoUserName: Scalars['String'];
  hasNewCoupon: Scalars['Boolean'];
  hasNewPoint: Scalars['Boolean'];
  /** hot 모델 구좌에 표시될 상품 목록, 가장 적합한 상품부터 상품 id를 count 개수만큼 반환 */
  hotProducts: Array<Scalars['Int']>;
  hubDownload: Scalars['String'];
  inquiries: Array<Inquiry>;
  inquiry: Inquiry;
  inquiryCount: Scalars['Int'];
  /** 초대 코드로 해당하는 초대코드의 언어를 가져오는 쿼리 */
  invitationLangCode?: Maybe<Scalars['String']>;
  isCouponNotification: Scalars['Boolean'];
  isDuplicateBrandName: Scalars['Boolean'];
  isEqualPassword: Scalars['Boolean'];
  isExistPromotion: Scalars['Boolean'];
  isFirstDownload: Scalars['Boolean'];
  isParticipatedEvent: Scalars['Boolean'];
  /** 상품의 상세페이지에서 업데이트 불릿 여부를 가져오는 메소드입니다. */
  isUpdate: Scalars['Boolean'];
  /** 초대 코드가 유효한지 여부를 가져오는 쿼리 */
  isValidInvitationCode: Scalars['Boolean'];
  mainGroup?: Maybe<MainGroup>;
  mainGroups: Array<GodoMainGroup>;
  /** 메인페이지 구좌의 상품 목록을 가져오는 메소드 입니다. */
  mainProductListV2: MainProductListV2Return;
  myProject: Project;
  myProjects: Array<Project>;
  notice?: Maybe<HubAdminPost>;
  notices: HubAdminPosts;
  notifications: Array<Get>;
  ongoingCometPromotionProductNos: Array<Scalars['Int']>;
  ongoingCosmicPromotionProductNos: Array<Scalars['Int']>;
  orderGoodsWithExchangeRate: Scalars['String'];
  /** 주문 시 입력한 필명 정보를 가져오는 쿼리 */
  orderLicense?: Maybe<OrderLicenseModel>;
  orderProduct: OrderProductClone;
  /** 주문 상품을 가져오는 쿼리 */
  orderProducts?: Maybe<Array<OrderProductReturn>>;
  orderViewV2: OrderViewDto;
  /** 주문 목록을 가져오는 쿼리 */
  orders?: Maybe<Array<OrderReturn>>;
  /** 주문 목록의 개수를 가져오는 쿼리 */
  ordersCount?: Maybe<Scalars['Int']>;
  /** 다른 상품들을 가져오는 메소드입니다. */
  otherProductList: RelateProductListOutput;
  package: CurrentPackageOutput;
  packages?: Maybe<Array<Maybe<PackageOutput>>>;
  participationPointResult?: Maybe<Scalars['Float']>;
  popup: PopupOutput;
  popupCount: Scalars['Int'];
  popups: Array<PopupOutput>;
  productCards: Array<Maybe<Acon3dProduct>>;
  productCardsSummary: ProductCardsSummary;
  productInquiries: ProductInquiries;
  project: Project;
  projects: Array<Project>;
  recentOrdersV2: Array<RecentOrder>;
  refresh: User;
  review?: Maybe<Review>;
  reviewCountByGodoGoodsNo: Scalars['Int'];
  /** 제품의 리뷰 상세를 가져오는 메소드 */
  reviewDetailV2: ReviewDetail;
  reviews: Array<Review>;
  /** 제품의 리뷰를 가져오는 메소드 */
  reviewsV2: Array<ReviewV2>;
  sasUrl: Scalars['String'];
  search: SearchSummary;
  searchKeywordList: Array<SearchKeywordListReturn>;
  /** hot 모델 구좌에 표시될 상품 목록을 갱신 */
  setHotProducts: Array<Scalars['Int']>;
  settle?: Maybe<UserSettle>;
  settlement: Scalars['String'];
  settlementExcelDownloadHistory: Array<SettlementExcelDownloadHistory>;
  sign: ResultWithUser;
  signIn: User;
  signInWidthToken: User;
  signedUser: User;
  /** 비슷한 작품들을 가져오는 메소드입니다. */
  similarProductList: RelateProductListOutput;
  syncCategory: Array<BrandCategoryReturn>;
  termAgree?: Maybe<UserTermAgree>;
  /** 비슷한 작품들을 가져오는 메소드입니다. */
  togetherProductListRecommendation: ProductListReturn;
  /** 일정 기간동안 회원의 로그인 횟수 총합을 가져오는 메소드 입니다. */
  totalLoginCount: Scalars['Int'];
  /** 일정 기간동안 회원의 쿠폰 및 포인트를 제외한 결제 금액 총합을 가져오는 메소드 입니다. */
  totalSettlePrice: Scalars['Int'];
  twoDepthCategories: Array<TwoDepthCategory>;
  user: User;
  userAcceptStatusNumber: Scalars['Float'];
  userAdmin: User;
  userEventPeriodTotalPayment: Scalars['Float'];
  userPlan?: Maybe<UserPlanResponse>;
  userSettle: User;
  userV2: User;
  users: UserPaginatedResponse;
  usersV2: UserPaginatedResponse;
};


export type QueryGodo_Event_Board_DeleteArgs = {
  bdId: Scalars['String'];
  commentSno?: InputMaybe<Scalars['Int']>;
  lang: Scalars['String'];
  sno?: InputMaybe<Scalars['Int']>;
};


export type QueryGodo_Event_Board_ModifyArgs = {
  bdId: Scalars['String'];
  commentSno?: InputMaybe<Scalars['Int']>;
  lang: Scalars['String'];
  sno?: InputMaybe<Scalars['Int']>;
};


export type QueryGodo_Event_Board_WriteArgs = {
  bdId: Scalars['String'];
  commentSno?: InputMaybe<Scalars['Int']>;
  lang: Scalars['String'];
  sno?: InputMaybe<Scalars['Int']>;
};


export type QueryGodo_Event_Check_BenefitArgs = {
  bdId: Scalars['String'];
  commentSno?: InputMaybe<Scalars['Int']>;
  lang: Scalars['String'];
  sno?: InputMaybe<Scalars['Int']>;
};


export type QueryGodo_Event_Order_CompleteArgs = {
  lang: Scalars['String'];
  orderNo: Scalars['String'];
};


export type QueryGodo_Event_Order_RefundArgs = {
  goodsNos: Array<Scalars['String']>;
  orderNo: Scalars['String'];
};


export type QueryGodo_Event_Order_SettleArgs = {
  goodsNo: Array<Scalars['String']>;
  lang: Scalars['String'];
  orderNo: Scalars['String'];
};


export type QueryGodo_Order_LogArgs = {
  errorMessage?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  orderId?: InputMaybe<Scalars['String']>;
  paypalStatus?: InputMaybe<Scalars['String']>;
  requestParameter?: InputMaybe<Scalars['String']>;
};


export type QueryGodo_Update_IsrefundedArgs = {
  goodsNos: Array<Scalars['String']>;
  orderNo: Scalars['String'];
};


export type QueryGetCouponIssueCountArgs = {
  couponNo?: InputMaybe<Scalars['Int']>;
};


export type QueryAdminDocumentArgs = {
  id: Scalars['String'];
  language: Language_Code;
};


export type QueryAdminDocumentsArgs = {
  assetId?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  manager?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  onView?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<InputMaybe<Document_Status>>>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryAdminDownloadArgs = {
  id: Scalars['String'];
};


export type QueryAdminInquiriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};


export type QueryAdminInquiryArgs = {
  id: Scalars['Int'];
};


export type QueryAdminInquiryCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};


export type QueryAdminOrderCouponInfoArgs = {
  orderNo: Scalars['String'];
};


export type QueryAdminOrderEventHistoryArgs = {
  orderId: Scalars['String'];
};


export type QueryAdminOrderGoodsPriceInfoArgs = {
  input: OrderGoodsPriceInfoArgs;
};


export type QueryAdminOrderPaymentInfoArgs = {
  orderNo: Scalars['String'];
};


export type QueryAssetFileInfoArgs = {
  id: Scalars['Int'];
  language: Language_Code;
};


export type QueryAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  brandName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryBenefitProductsArgs = {
  lang: Scalars['String'];
};


export type QueryBestProductsArgs = {
  categoryCode?: InputMaybe<Scalars['String']>;
  isFreeProductInclude?: InputMaybe<Scalars['Boolean']>;
  type: BestProductType;
};


export type QueryBrandAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']>;
  brandName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryBrandPickSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<ProductListSort>;
};


export type QueryBrandProductsWithCategoriesArgs = {
  brandCode: Scalars['String'];
  languageCode: LanguageCodeEnum;
};


export type QueryCartsArgs = {
  lang: Scalars['String'];
};


export type QueryCategoriesV2Args = {
  languageCode: LanguageCodeEnum;
};


export type QueryCategoriesV3Args = {
  languageCode: LanguageCodeEnum;
};


export type QueryCheckAuthenticationArgs = {
  email: Scalars['String'];
};


export type QueryCheckExistMemberArgs = {
  accessToken: Scalars['String'];
  snsType: SnsTypeEnum;
};


export type QueryCheckIssuanceTempPasswordArgs = {
  aconMemberId: Scalars['Int'];
  snsType: SnsTypeEnum;
};


export type QueryCheckedPasswordChangeArgs = {
  key: Scalars['String'];
};


export type QueryCompareArgs = {
  id: Scalars['String'];
  language: Language_Code;
};


export type QueryCosmicDealsStatisticsV2Args = {
  lang: Scalars['String'];
};


export type QueryDocumentArgs = {
  id: Scalars['String'];
};


export type QueryDocumentsArgs = {
  assetId?: InputMaybe<Scalars['Int']>;
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  content?: InputMaybe<Scalars['String']>;
  createdInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  requestedInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<Array<InputMaybe<Partner_Document_Status>>>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryExchangeRateArgs = {
  languageCode: LanguageCodeEnum;
};


export type QueryExistsPenNameArgs = {
  penName: Scalars['String'];
};


export type QueryExistsUserArgs = {
  account: Scalars['String'];
};


export type QueryFaqArgs = {
  id: Scalars['Int'];
};


export type QueryFaqAconArgs = {
  id: Scalars['Int'];
};


export type QueryFaqAconCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type QueryFaqAconTypesArgs = {
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryFaqAconsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type QueryFaqCreatorArgs = {
  id: Scalars['Int'];
};


export type QueryFaqCreatorCategoriesArgs = {
  id: Scalars['Int'];
};


export type QueryFaqCreatorCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type QueryFaqCreatorTypesArgs = {
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryFaqCreatorsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type QueryFileArgs = {
  id: Scalars['Float'];
};


export type QueryGetAconfaqArgs = {
  id: Scalars['Int'];
};


export type QueryGetAconfaqTypesArgs = {
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryGetAconfaqsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAconfaqsTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  langId?: InputMaybe<Scalars['Int']>;
  searchType?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAdminOrderArgs = {
  orderNo: Scalars['String'];
};


export type QueryGetAdminOrdersArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  godoMemId?: InputMaybe<Scalars['String']>;
  godoOrderEmail?: InputMaybe<Scalars['String']>;
  godoOrderNo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetAdminOrdersCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  godoMemId?: InputMaybe<Scalars['String']>;
  godoOrderEmail?: InputMaybe<Scalars['String']>;
  godoOrderNo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryGetAdminReviewArgs = {
  id: Scalars['Int'];
};


export type QueryGetAdminReviewsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  isCollectScrap?: InputMaybe<Scalars['Boolean']>;
  isImageUrl?: InputMaybe<Scalars['Boolean']>;
  isWithDeleted?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordType?: InputMaybe<KeywordTypeEnum>;
  originalLanguageCode?: InputMaybe<LanguageCodeEnum>;
  rating?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAdminReviewsCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  isCollectScrap?: InputMaybe<Scalars['Boolean']>;
  isImageUrl?: InputMaybe<Scalars['Boolean']>;
  isWithDeleted?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  keywordType?: InputMaybe<KeywordTypeEnum>;
  originalLanguageCode?: InputMaybe<LanguageCodeEnum>;
  rating?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAttendanceEventDatesArgs = {
  eventId: Scalars['Float'];
};


export type QueryGetBadgeArgs = {
  id?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBadgesArgs = {
  id?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBandBannerArgs = {
  id: Scalars['Int'];
};


export type QueryGetBandBannerForPageArgs = {
  lang: Scalars['String'];
};


export type QueryGetBandBannersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBannerArgs = {
  id: Scalars['Float'];
};


export type QueryGetBannersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetBannersByGodoNoArgs = {
  godoGoodsNo: Scalars['Float'];
  lang: Scalars['String'];
};


export type QueryGetBenefitBurdenListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCashListArgs = {
  endDate: Scalars['String'];
  page: Scalars['Int'];
  startDate: Scalars['String'];
  type: Scalars['String'];
};


export type QueryGetCategoriesByLangArgs = {
  lang: Scalars['String'];
};


export type QueryGetCategoryArgs = {
  godoCateCd: Scalars['String'];
};


export type QueryGetCountsArgs = {
  lang: Scalars['String'];
  viewOnlyActiveCoupons: Scalars['Boolean'];
};


export type QueryGetCouponBenefitArgs = {
  id: Scalars['Int'];
};


export type QueryGetCouponBenefitsArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCouponBenefitsTotalCountArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCouponBurdenListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCouponItemsArgs = {
  couponName?: InputMaybe<Scalars['String']>;
  couponNos?: InputMaybe<Array<Scalars['String']>>;
  couponRegDt?: InputMaybe<Scalars['String']>;
};


export type QueryGetCouponListArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  page: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};


export type QueryGetDepositListArgs = {
  endDate: Scalars['String'];
  lang: Scalars['String'];
  page: Scalars['Int'];
  startDate: Scalars['String'];
};


export type QueryGetDisplayItemsArgs = {
  lang: Scalars['String'];
};


export type QueryGetDownloadLogsArgs = {
  goodsNo?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderNo?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetEventBannerItemArgs = {
  id: Scalars['Int'];
};


export type QueryGetEventBannerItemsArgs = {
  isInprogressEvent: Scalars['Boolean'];
  lang: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetEventBannerTotalCountArgs = {
  isInprogressEvent: Scalars['Boolean'];
  lang: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetFaqArgs = {
  id: Scalars['Int'];
};


export type QueryGetFaqsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetFaqsTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetFilterTransGodoCategoryChildItemsArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  containsEtc?: InputMaybe<Scalars['Boolean']>;
  extensions?: InputMaybe<Array<Scalars['String']>>;
  godoGoodsNos?: InputMaybe<Array<Scalars['Int']>>;
  lang: Scalars['String'];
};


export type QueryGetGodoBannerInfoArgs = {
  bannerCode: Scalars['Float'];
};


export type QueryGetGodoBrandNameArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryGetGodoPopupArgs = {
  url: Scalars['String'];
};


export type QueryGetGoodsBuyersArgs = {
  goodsNo: Scalars['Float'];
};


export type QueryGetItemArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetItemsArgs = {
  lang: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  viewOnlyActiveCoupons: Scalars['Boolean'];
};


export type QueryGetMyOrdersArgs = {
  languageCode: LanguageCodeEnum;
};


export type QueryGetMyReviewArgs = {
  id: Scalars['Int'];
};


export type QueryGetMyReviewsByGodoGoodsNoArgs = {
  godoGoodsNo: Scalars['String'];
};


export type QueryGetNoticeArgs = {
  id: Scalars['Int'];
};


export type QueryGetNoticesArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetNoticesTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrderCouponItemsArgs = {
  couponApplyOrderNo?: InputMaybe<Scalars['String']>;
  godoCartSnos?: InputMaybe<Array<Scalars['String']>>;
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrderInfoArgs = {
  godoCartNos?: InputMaybe<Array<Scalars['String']>>;
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrderNoWithoutReviewArgs = {
  godoGoodsNo: Scalars['String'];
};


export type QueryGetPaymentPointArgs = {
  godoGoodsNo?: InputMaybe<Array<Scalars['Int']>>;
  godoPrice?: InputMaybe<Array<Scalars['Int']>>;
  lang?: InputMaybe<Scalars['String']>;
};


export type QueryGetPhotoReviewArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPhotoReviewTotalCountArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPhotoReviewsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPointBenefitArgs = {
  id: Scalars['Int'];
};


export type QueryGetPointBenefitsArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPointBenefitsTotalCountArgs = {
  langId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetPointLimitPolicyArgs = {
  id: Scalars['Int'];
};


export type QueryGetPointLimitPolicyListArgs = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryGetPointListArgs = {
  endDate: Scalars['String'];
  lang: Scalars['String'];
  page: Scalars['Int'];
  startDate: Scalars['String'];
};


export type QueryGetPopupsHereArgs = {
  origin: Scalars['String'];
  url: Scalars['String'];
};


export type QueryGetProductLangArgs = {
  godoGoodsNo?: InputMaybe<Array<Scalars['Int']>>;
  lang: Scalars['String'];
};


export type QueryGetPromotionArgs = {
  id: Scalars['Int'];
};


export type QueryGetPromotionCountArgs = {
  brandName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetPromotionsArgs = {
  brandName?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetReceivePointByReviewArgs = {
  goodsNo: Scalars['Float'];
  isPhotoReview: Scalars['Boolean'];
  languageCode: Scalars['String'];
};


export type QueryGetSettleArgs = {
  brandCd: Scalars['String'];
  orderNo: Scalars['String'];
};


export type QueryGetSettleAllListArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettleAllListV2Args = {
  endDate?: InputMaybe<Scalars['String']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettleAllPriceArgs = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettleCountArgs = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettleListArgs = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettleListV2Args = {
  brandId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goodsNm?: InputMaybe<Scalars['String']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  paymentEndDate?: InputMaybe<Scalars['String']>;
  paymentStartDate?: InputMaybe<Scalars['String']>;
  penNm?: InputMaybe<Scalars['String']>;
  settleEndDate?: InputMaybe<Scalars['String']>;
  settleStartDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetSettleV2Args = {
  goodsNo: Scalars['Int'];
  orderNo: Scalars['String'];
};


export type QueryGetTermArgs = {
  type: Scalars['String'];
};


export type QueryGetTransGodoCategoryChildItemsArgs = {
  cateCds: Array<Scalars['String']>;
  lang: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetTransGodoCategoryItemsArgs = {
  cateCds: Array<Scalars['String']>;
  lang: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserSearchArgs = {
  keyword: Scalars['String'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  type: Scalars['String'];
};


export type QueryGetUsersArgs = {
  keyword: Scalars['String'];
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  type: Scalars['String'];
};


export type QueryGodoCometDealListArgs = {
  godoGoodsNo: Scalars['Int'];
};


export type QueryGodoCosmicDealListArgs = {
  godoGoodsNo: Scalars['Int'];
};


export type QueryGodoGoodsListCategoryResultArgs = {
  godoCateCd: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryGodoMyOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGodoUserInvitationCodeArgs = {
  langCode: Scalars['String'];
};


export type QueryHotProductsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};


export type QueryHubDownloadArgs = {
  id: Scalars['String'];
};


export type QueryInquiriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};


export type QueryInquiryArgs = {
  id: Scalars['Int'];
};


export type QueryInquiryCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoMemNo?: InputMaybe<Scalars['String']>;
  isClipped?: InputMaybe<Scalars['Boolean']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderCol?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};


export type QueryInvitationLangCodeArgs = {
  code: Scalars['String'];
};


export type QueryIsDuplicateBrandNameArgs = {
  lang: Scalars['String'];
  name: Scalars['String'];
};


export type QueryIsEqualPasswordArgs = {
  password: Scalars['String'];
};


export type QueryIsExistPromotionArgs = {
  godoGoodsNo: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type QueryIsFirstDownloadArgs = {
  goodsNo: Scalars['Int'];
  orderNo: Scalars['String'];
};


export type QueryIsParticipatedEventArgs = {
  eventId: Scalars['Float'];
};


export type QueryIsUpdateArgs = {
  goodsNo: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryIsValidInvitationCodeArgs = {
  code: Scalars['String'];
};


export type QueryMainProductListV2Args = {
  lang: Scalars['String'];
  sno: Scalars['Int'];
};


export type QueryMyProjectArgs = {
  id: Scalars['Float'];
};


export type QueryNoticeArgs = {
  id: Scalars['Int'];
};


export type QueryOngoingCometPromotionProductNosArgs = {
  languageCode: LanguageCodeEnum;
};


export type QueryOngoingCosmicPromotionProductNosArgs = {
  languageCode: LanguageCodeEnum;
};


export type QueryOrderGoodsWithExchangeRateArgs = {
  settlementEndDate: Scalars['String'];
  settlementStartDate: Scalars['String'];
};


export type QueryOrderLicenseArgs = {
  orderNo: Scalars['Float'];
};


export type QueryOrderProductArgs = {
  id: Scalars['String'];
};


export type QueryOrderProductsArgs = {
  lang: Scalars['String'];
  orderNo: Scalars['Float'];
};


export type QueryOrderViewV2Args = {
  lang: Scalars['String'];
  orderNo: Scalars['Float'];
};


export type QueryOrdersArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  godoMemId?: InputMaybe<Scalars['String']>;
  godoOrderEmail?: InputMaybe<Scalars['String']>;
  godoOrderNo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryOrdersCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']>;
  godoMemId?: InputMaybe<Scalars['String']>;
  godoOrderEmail?: InputMaybe<Scalars['String']>;
  godoOrderNo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryOtherProductListArgs = {
  godoGoodsNo: Scalars['Int'];
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};


export type QueryPackageArgs = {
  id: Scalars['Float'];
};


export type QueryParticipationPointResultArgs = {
  eventId: Scalars['Float'];
};


export type QueryPopupArgs = {
  id: Scalars['Int'];
};


export type QueryPopupCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<PopupLocationV2>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  orderCol?: InputMaybe<PopupOrderCol>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<PopupStatus>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryPopupsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<PopupLocationV2>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  orderCol?: InputMaybe<PopupOrderCol>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<PopupStatus>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryProductCardsArgs = {
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryProductCardsSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<ProductListSort>;
};


export type QueryProjectArgs = {
  id: Scalars['Float'];
};


export type QueryRefreshArgs = {
  account: Scalars['String'];
};


export type QueryReviewArgs = {
  reviewId: Scalars['Int'];
};


export type QueryReviewCountByGodoGoodsNoArgs = {
  godoGoodsNo: Scalars['String'];
};


export type QueryReviewDetailV2Args = {
  lang: Scalars['String'];
  sno: Scalars['Int'];
};


export type QueryReviewsArgs = {
  goodsNo: Scalars['String'];
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  rating?: InputMaybe<Array<Scalars['Int']>>;
  sortType?: InputMaybe<Scalars['String']>;
};


export type QueryReviewsV2Args = {
  goodsNo: Scalars['String'];
  isExperimental?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
  limit: Scalars['Int'];
  page: Scalars['Int'];
  rating?: InputMaybe<Array<Scalars['Int']>>;
  sortType?: InputMaybe<Scalars['String']>;
};


export type QuerySasUrlArgs = {
  inputs: SasUrlInput;
};


export type QuerySearchArgs = {
  keyword: Scalars['String'];
  languageCode: LanguageCodeEnum;
};


export type QuerySearchKeywordListArgs = {
  lang: Scalars['String'];
};


export type QuerySettlementArgs = {
  settlementEndDate: Scalars['String'];
  settlementStartDate: Scalars['String'];
};


export type QuerySignArgs = {
  account: Scalars['String'];
  password: Scalars['String'];
};


export type QuerySignInArgs = {
  authInfo: AuthInfoInput;
};


export type QuerySignInWidthTokenArgs = {
  token: Scalars['String'];
};


export type QuerySimilarProductListArgs = {
  godoGoodsNo: Scalars['Int'];
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};


export type QuerySyncCategoryArgs = {
  brandCd?: InputMaybe<Scalars['String']>;
  lang: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export type QueryTermAgreeArgs = {
  id?: InputMaybe<Scalars['Float']>;
};


export type QueryTogetherProductListRecommendationArgs = {
  godoGoodsNo: Scalars['Int'];
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
  lang: Scalars['String'];
};


export type QueryTwoDepthCategoriesArgs = {
  languageCode: Scalars['String'];
  oneDepthCategoryCodes: Array<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['Float'];
};


export type QueryUserAcceptStatusNumberArgs = {
  modifyStatus?: InputMaybe<Scalars['Float']>;
  status: Scalars['Float'];
};


export type QueryUserAdminArgs = {
  id: Scalars['Float'];
};


export type QueryUserEventPeriodTotalPaymentArgs = {
  eventId: Scalars['Float'];
};


export type QueryUserPlanArgs = {
  id?: InputMaybe<Scalars['Float']>;
};


export type QueryUserSettleArgs = {
  id: Scalars['Float'];
  password: Scalars['String'];
};


export type QueryUserV2Args = {
  id?: InputMaybe<Scalars['Float']>;
};


export type QueryUsersArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  isOversea?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderByEnum>;
  orderCol?: InputMaybe<UserOrderColEnum>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<UserSettleStatusEnum>;
  userEmail?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<UserTypeEnum>;
};


export type QueryUsersV2Args = {
  account?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type RecentOrder = {
  __typename?: 'RecentOrder';
  orderNo: Scalars['Float'];
  orderedAt: Scalars['DateTime'];
  orderedProducts: Array<RecentOrderedProduct>;
  settlePrice: Scalars['Float'];
};

export type RecentOrderedProduct = {
  __typename?: 'RecentOrderedProduct';
  acon3dProduct: Acon3dProduct;
  godoGoodsNo: Scalars['Float'];
  hasWrittenReview: Scalars['Boolean'];
  price: Scalars['Float'];
  product?: Maybe<Acon3dProduct>;
  reviewId?: Maybe<Scalars['Float']>;
  reviewSno?: Maybe<Scalars['Float']>;
  sno: Scalars['Float'];
  status: Scalars['String'];
  statusString: Scalars['String'];
  title: Scalars['String'];
};


export type RecentOrderedProductAcon3dProductArgs = {
  langCode?: InputMaybe<Scalars['String']>;
};


export type RecentOrderedProductProductArgs = {
  langCode?: InputMaybe<Scalars['String']>;
};

/** 결제-취소/환불(H1-H3) */
export type RefundAccountInput = {
  /** 예금주 */
  accountHolder: Scalars['String'];
  /** 계좌번호 */
  accountNumber: Scalars['String'];
  /** 은행명 */
  bankName: Scalars['String'];
};

export type RegisterReviewInput = {
  contents: Scalars['String'];
  godoGoodsNo: Scalars['Int'];
  godoOrderNo: Scalars['String'];
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  isSecret: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  rating: Scalars['Int'];
};

export type RelateProductListItem = {
  __typename?: 'RelateProductListItem';
  /** 브랜드 명 */
  brand: Scalars['String'];
  /** 브랜드 코드 */
  brandCd: Scalars['String'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float'];
  /** 이미지 URL */
  imageUrl: Scalars['String'];
  /** 가격 */
  price: Scalars['String'];
  /** 가격 */
  realPrice: Scalars['String'];
  /** 제목 */
  title: Scalars['String'];
};

export type RelateProductListOutput = {
  __typename?: 'RelateProductListOutput';
  /** 카테고리 코드 */
  cateCd?: Maybe<Scalars['String']>;
  /** 개수 */
  count?: Maybe<Scalars['Float']>;
  /** 비슷한 작품 항목들 */
  items?: Maybe<Array<RelateProductListItem>>;
};

export type RemoveClipInput = {
  godoGoodsNo: Scalars['Int'];
};

export type RemoveClipsInput = {
  godoGoodsNos: Array<Scalars['Int']>;
};

export type ResultWithUser = {
  __typename?: 'ResultWithUser';
  item?: Maybe<User>;
  result: Scalars['Boolean'];
};

export type Review = {
  __typename?: 'Review';
  adminUpdateHistories?: Maybe<Array<ReviewAdminUpdateHistory>>;
  author: Scalars['String'];
  commentsCount: Scalars['Int'];
  created: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  favoriteCount?: Maybe<Scalars['Int']>;
  godoGoodsNo: Scalars['Int'];
  godoMemNo: Scalars['Int'];
  godoOrderNo?: Maybe<Scalars['String']>;
  godoUserId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** 리뷰 대표이미지 */
  imageUrl?: Maybe<Scalars['String']>;
  isBestReview: Scalars['Boolean'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isImage: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isScrap?: Maybe<Scalars['Boolean']>;
  isSecret: Scalars['Boolean'];
  nonMaskedGodoUserId: Scalars['String'];
  product?: Maybe<Acon3dProduct>;
  /** 리뷰 별점 */
  rating: Scalars['Int'];
  reviewContentsItems: Array<ReviewContents>;
  reviewImages?: Maybe<Array<ReviewImage>>;
  reviewReplies?: Maybe<Array<ReviewReply>>;
  userUpdateHistories?: Maybe<Array<ReviewUpdateHistory>>;
};


export type ReviewProductArgs = {
  languageCode: LanguageCodeEnum;
};


export type ReviewReviewContentsItemsArgs = {
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<Scalars['String']>;
};

export type ReviewAdminUpdateHistory = {
  __typename?: 'ReviewAdminUpdateHistory';
  adminId: Scalars['String'];
  content: Scalars['String'];
  date?: Maybe<Scalars['String']>;
};

export type ReviewComment = {
  __typename?: 'ReviewComment';
  /** 내용 */
  contents: Scalars['String'];
  /** 비밀글 여부 */
  isSecret: Scalars['Boolean'];
  /** 원문 내용 */
  originalContents: Scalars['String'];
  /** 원문 언어 */
  originalLanguage?: Maybe<Scalars['String']>;
  /** 리뷰 id */
  reviewId: Scalars['Int'];
};

export type ReviewContents = {
  __typename?: 'ReviewContents';
  contents: Scalars['String'];
  /** 원본 리뷰 여부 */
  isOriginal: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  reviewId: Scalars['Int'];
  title: Scalars['String'];
};

export type ReviewDetail = {
  __typename?: 'ReviewDetail';
  /** 댓글 */
  comments: Array<ReviewComment>;
  /** 내용 */
  contents: Scalars['String'];
  /** 유저 정보 */
  memNo: Scalars['String'];
  /** 원문 내용 */
  originalContents: Scalars['String'];
  /** 원문 언어 */
  originalLanguage?: Maybe<Scalars['String']>;
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  cursor?: Maybe<Scalars['Int']>;
  node?: Maybe<Review>;
};

export type ReviewImage = {
  __typename?: 'ReviewImage';
  imageNumber: Scalars['Int'];
  imageUrl: Scalars['String'];
};

export type ReviewReply = {
  __typename?: 'ReviewReply';
  adminUser: AdminUser;
  created: Scalars['DateTime'];
  id: Scalars['Int'];
  reviewId: Scalars['Int'];
  reviewReplyContents?: Maybe<Array<ReviewReplyContent>>;
};

export type ReviewReplyContent = {
  __typename?: 'ReviewReplyContent';
  content: Scalars['String'];
  /** 원본 리뷰 여부 */
  isOriginal: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  reviewReplyId: Scalars['Int'];
};

export type ReviewUpdateHistory = {
  __typename?: 'ReviewUpdateHistory';
  date?: Maybe<Scalars['String']>;
};

export type ReviewV2 = {
  __typename?: 'ReviewV2';
  /** 작성자 */
  author: Scalars['String'];
  /** 답글여부 */
  commentsCount: Scalars['Int'];
  /** 작성일 */
  created: Scalars['DateTime'];
  /** id */
  id: Scalars['Int'];
  /** 후기 발굴단 베스트 리뷰 여부 */
  isBestReview: Scalars['Boolean'];
  /** 이미지 포함 여부 */
  isImage: Scalars['Boolean'];
  /** 새 리뷰 여부 */
  isNew: Scalars['Boolean'];
  /** 비밀글 여부 */
  isSecret: Scalars['Boolean'];
  /** 신상체험단 리뷰 여부(A/B테스트) */
  isTest: Scalars['Boolean'];
  /** 원문 제목 */
  originalTitle: Scalars['String'];
  /** 별점 */
  rating: Scalars['Int'];
  /** 제목 */
  title?: Maybe<Scalars['String']>;
};

export type SasUrlInput = {
  /** upload file id */
  id: Scalars['Int'];
};

export type SearchKeyword = {
  __typename?: 'SearchKeyword';
  creator?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  keyword: Scalars['String'];
  language?: Maybe<Language>;
  type: Scalars['String'];
  updater?: Maybe<Scalars['Float']>;
};

export type SearchKeywordListReturn = {
  __typename?: 'SearchKeywordListReturn';
  /** 키워드 */
  keyword: Scalars['String'];
  /** 언어 코드 */
  lang: Scalars['String'];
};

export type SearchSummary = {
  __typename?: 'SearchSummary';
  brandCode?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  products: Array<Acon3dProduct>;
  sort?: Maybe<ProductListSort>;
  /** 사용 여부 */
  toggle?: Maybe<Scalars['Boolean']>;
};

export type SentryTestResponse = {
  __typename?: 'SentryTestResponse';
  testMessage: Scalars['String'];
  testNumber?: Maybe<Scalars['Float']>;
};

export type SetCategoryFileInput = {
  /** 업로드 파일 key */
  key?: InputMaybe<Scalars['String']>;
};

export type SetCategoryI18nInput = {
  /** 카테고리 URL */
  anchor?: InputMaybe<Scalars['String']>;
  /** 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 색상 정보 */
  color?: InputMaybe<Scalars['String']>;
  /** 작성자 */
  creator?: InputMaybe<Scalars['Float']>;
  /** 파일 정보 */
  file?: InputMaybe<SetCategoryFileInput>;
  /** 새 창으로 열기 여부 */
  isBlankAnchor: Scalars['Float'];
  /** 검색 결과 창 표시 여부 */
  isShowResultCount: Scalars['Float'];
  /** 언어 코드 */
  lang: Scalars['String'];
  /** map */
  map?: InputMaybe<Scalars['String']>;
  /** 카테고리 이름 */
  name: Scalars['String'];
  /** 수정자 */
  updater?: InputMaybe<Scalars['Float']>;
};

export type Settle = {
  __typename?: 'Settle';
  /** 추가필드 */
  addField: Scalars['String'];
  /** 혜택 부담도 */
  benefitBrandBurden: Scalars['String'];
  /** 부담도 */
  brandBurden?: Maybe<Scalars['String']>;
  /** 브랜드코드 */
  brandCd: Scalars['String'];
  /** 쿠폰 상품 dc 가격 */
  couponGoodsDcPrice: Scalars['String'];
  /** 상품할인가격 */
  goodsDcPrice: Scalars['String'];
  /** 상품명 */
  goodsNm: Scalars['String'];
  goodsNo?: Maybe<Scalars['Int']>;
  /** 상품가격 */
  goodsPrice: Scalars['String'];
  /** 라이센스 유형 */
  licenseType?: Maybe<Scalars['String']>;
  /** 주문메모 */
  orderMemo: Scalars['String'];
  /** 주문자명 */
  orderName: Scalars['String'];
  /** 주문번호 */
  orderNo: Scalars['String'];
  /** 지불일자 */
  paymentDt: Scalars['DateTime'];
  /** 등록일 */
  regDt: Scalars['DateTime'];
  /** 정산일자 */
  settleDt: Scalars['DateTime'];
  /** sno */
  sno: Scalars['String'];
};

export type SettleCount = {
  __typename?: 'SettleCount';
  /** 검색 합계 */
  searchCount: Scalars['Int'];
  /** 총 합계 */
  totalCount: Scalars['Int'];
};

export type SettleDetail = {
  __typename?: 'SettleDetail';
  /** 고도몰 추가필드 */
  addField?: Maybe<Scalars['String']>;
  /** 혜택 브랜드 부담도 */
  benefitBrandBurden?: Maybe<Scalars['Int']>;
  /** 브랜드 부담도 */
  brandBurden?: Maybe<Scalars['Int']>;
  brandCd: Scalars['String'];
  /** 구매한 회원의 국가정보 */
  country?: Maybe<Scalars['String']>;
  couponGoodsDcPrice: Scalars['Int'];
  goodsDcPrice: Scalars['Int'];
  goodsNm: Scalars['String'];
  goodsPrice: Scalars['Int'];
  /** 주문한 쇼핑몰 국가코드 */
  languageCode: Scalars['String'];
  /** 개인/공동 사용권명 */
  licenseName: Scalars['String'];
  /** 사용권 유형 personal: 개인사용권, company: 공동사용권 */
  licenseType: Scalars['String'];
  orderName: Scalars['String'];
  orderNo: Scalars['String'];
  paymentDt: Scalars['DateTime'];
  /** 공동사용권 상품사용예정작품명 */
  projectName?: Maybe<Scalars['String']>;
  regDt: Scalars['DateTime'];
  settleDt: Scalars['DateTime'];
};

export type SettleInput = {
  bank: Scalars['Float'];
  bankAccountNumber: Scalars['String'];
  bankAccountOwner: Scalars['String'];
  bankName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  nationalIdNumber: Scalars['String'];
  signLang: Scalars['String'];
};

export type SettlementExcelDownloadHistory = {
  __typename?: 'SettlementExcelDownloadHistory';
  /** 문서 분류 */
  documentClass: Scalars['String'];
  /** 엑셀파일 다운로드 일자 */
  downloadDate: Scalars['DateTime'];
  /** 정산구간: 종료일자 */
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  /** 정산구간: 시작일자 */
  startDate: Scalars['DateTime'];
  /** 다운로드 유저 이름(ex. 미우) */
  userName: Scalars['String'];
};

export type ShowAssetsInput = {
  /** asset id를 배열로 */
  assetIds: Array<Scalars['Int']>;
  /** 메일 발송하지 않는 경우라면 null, 발송하는 경우 메모 내용을 그대로, 없다면 ""을 전송 */
  mailContent?: InputMaybe<Scalars['String']>;
  /** 즉시 예약인 경우 현재 일자(new Date())를 넣어주시면 됩니다 */
  showingDate: Scalars['DateTime'];
};

export type SingleDocumentBrandName = {
  __typename?: 'SingleDocumentBrandName';
  language?: Maybe<Language_Code>;
  name?: Maybe<Scalars['String']>;
};

export type SingleDocumentCategoriesInput = {
  genre?: InputMaybe<SingleDocumentCategoryInfoInput>;
  theme?: InputMaybe<SingleDocumentCategoryInfoInput>;
};

export type SingleDocumentCategoriesOutput = {
  __typename?: 'SingleDocumentCategoriesOutput';
  genre?: Maybe<SingleDocumentCategoryInfoOutput>;
  theme?: Maybe<SingleDocumentCategoryInfoOutput>;
};

export type SingleDocumentCategoryInfoInput = {
  primary: Scalars['String'];
  secondary: Scalars['String'];
};

export type SingleDocumentCategoryInfoOutput = {
  __typename?: 'SingleDocumentCategoryInfoOutput';
  primary: Scalars['String'];
  secondary: Scalars['String'];
};

export type SingleDocumentInput = {
  /** 고도몰상품번호 */
  assetId?: InputMaybe<Scalars['Int']>;
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<SingleDocumentCategoriesInput>;
  contentBody?: InputMaybe<Scalars['String']>;
  contentHead?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<DocumentCopyrightInput>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  keywords?: InputMaybe<Array<Scalars['String']>>;
  language?: InputMaybe<Language_Code>;
  mainImage?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  priceChanged?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Document_Status>;
  subImage?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SingleDocumentOutput = {
  __typename?: 'SingleDocumentOutput';
  /** 고도몰상품번호 */
  assetId?: Maybe<Scalars['Int']>;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Int']>;
  brandNames?: Maybe<Array<Maybe<SingleDocumentBrandName>>>;
  categories?: Maybe<SingleDocumentCategoriesOutput>;
  contentBody?: Maybe<Scalars['String']>;
  contentHead?: Maybe<Scalars['String']>;
  copyright?: Maybe<DocumentCopyrightOutput>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  /** 다큐먼트 ID */
  id: Scalars['String'];
  keywords?: Maybe<Array<Scalars['String']>>;
  language?: Maybe<Language_Code>;
  mainImage?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceChanged?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Document_Status>;
  subImage?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum SnsTypeEnum {
  Google = 'google',
  Naver = 'naver'
}

/** 세금계산서(B2) */
export type TaxesInput = {
  /** 에이콘 캐시 결제금액 */
  aconCashPaymentAmountText?: InputMaybe<Scalars['String']>;
  /** 에이콘 캐시 결제일 */
  aconCashPaymentDateText?: InputMaybe<Scalars['String']>;
  /** 주문번호 */
  orderNoText?: InputMaybe<Scalars['String']>;
  /** 사용자 ID */
  userId: Scalars['String'];
};

export type TempAconProduct = {
  __typename?: 'TempAconProduct';
  goodsNo: Scalars['Int'];
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type Term = {
  __typename?: 'Term';
  contents: Scalars['String'];
  created: Scalars['DateTime'];
  type: Scalars['String'];
};

export type TwoDepthCategory = {
  __typename?: 'TwoDepthCategory';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type Type = {
  __typename?: 'Type';
  /** 혜택 유형 코드 */
  code?: Maybe<Scalars['String']>;
  /** 혜택 유형 코드 ID */
  id?: Maybe<Scalars['Float']>;
  /** 혜택 유형 코드 명 */
  name?: Maybe<Scalars['String']>;
};

export type UpdateApplicationInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateBrandInput = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  godoBrandCd?: InputMaybe<Scalars['String']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<ProductType>;
};

export type UpdateExtensionConfigInput = {
  id: Scalars['Int'];
  /** 바꾸려는 이름 */
  name: Scalars['String'];
};

export type UpdateInquiryAnswerInput = {
  /** Inquiry answer content to update with */
  answer: Scalars['String'];
  /** Inquiry ID */
  inquiryId: Scalars['Int'];
};

export type UpdateLicenseInput = {
  licenses: Array<LicenseInput>;
  orderId: Scalars['String'];
};

export type UpdatePopupInput = {
  /** 버튼 문구 */
  buttonText?: InputMaybe<Scalars['String']>;
  /** 연결 URL */
  connectedUrl?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  exposeImmediately?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** 팝업 이미지 아이디 */
  image?: InputMaybe<PopupUploadFileInput>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<LanguageCodeEnum>;
  location?: InputMaybe<PopupLocationV2>;
  noEndDate?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** 하위 페이지 url */
  subLocations?: InputMaybe<Array<PopupSubLocationInput>>;
  subText?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  urls?: InputMaybe<Array<CreatePopupUrlInput>>;
};

export type UpdateReviewInput = {
  contents: Scalars['String'];
  godoGoodsNo: Scalars['Int'];
  godoOrderNo: Scalars['String'];
  id: Scalars['Int'];
  imageUrls?: InputMaybe<Array<Scalars['String']>>;
  isSecret: Scalars['Boolean'];
  languageCode: LanguageCodeEnum;
  rating: Scalars['Int'];
};

export type UpdateUserPlanInput = {
  commissionRate?: InputMaybe<Scalars['Float']>;
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserSettleInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['Float']>;
  bankName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  nationalIdNumber?: InputMaybe<Scalars['String']>;
};

export type UploadBlobStorageInput = {
  /** 파일 이름 */
  fileName: Scalars['String'];
  /** 저장될 폴더 이름 */
  targetDirectory: Scalars['String'];
  /** 파일 경로 ex) tmp/file_name */
  temporaryFilePath: Scalars['String'];
};

export type UploadFile = {
  __typename?: 'UploadFile';
  azureStoragePath: Scalars['String'];
  creator?: Maybe<Scalars['Float']>;
  fileName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
};

export type UploadFileClone = {
  __typename?: 'UploadFileClone';
  azureStoragePath: Scalars['String'];
  creator?: Maybe<Scalars['Int']>;
  fileName: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  updater?: Maybe<Scalars['Int']>;
};

export type UploadFileInput = {
  fileName?: InputMaybe<Scalars['String']>;
  temporaryFilePath: Scalars['String'];
};

export type UrlsInput = {
  isSubPageIncluded: Scalars['Boolean'];
  url: Scalars['String'];
};

export type UrlsOutput = {
  __typename?: 'UrlsOutput';
  isSubPageIncluded: Scalars['Boolean'];
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  accept: UserAccept;
  /** 아이디 */
  account: Scalars['String'];
  birth?: Maybe<Scalars['DateTime']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['Float']>;
  changeLog?: Maybe<Scalars['String']>;
  /** 상호 */
  companyName?: Maybe<Scalars['String']>;
  /** 연락처 */
  contact: Scalars['String'];
  /** 추가 연락처 */
  contactAdditional?: Maybe<Scalars['String']>;
  /** 일반 컨택용 이메일 */
  contactEmails: Array<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  firstLoginDate?: Maybe<Scalars['DateTime']>;
  group: Group;
  histories: Array<UserSettleStatusHistoryModel>;
  id: Scalars['Float'];
  isAdult: Scalars['Boolean'];
  /** 회원가입 언어, 현재는 무조건 ko 또는 en */
  joinLanguage: Scalars['String'];
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  memos: Array<UserMemoModel>;
  /** 이름 */
  name: Scalars['String'];
  notifications?: Maybe<Array<Get>>;
  plan?: Maybe<UserPlan>;
  reference: Array<UserAcceptReference>;
  settle: UserSettle;
  /** 정산용 이메일 */
  settlementEmails: Array<Scalars['String']>;
  snsUrl?: Maybe<Scalars['String']>;
  /** 사업자 구분 */
  subType?: Maybe<Scalars['String']>;
  subTypeString?: Maybe<Scalars['String']>;
  subTypeStringList: Array<Scalars['String']>;
  termAgree?: Maybe<Array<UserTermAgree>>;
  /** 일반/사업자 */
  type?: Maybe<Scalars['String']>;
  typeString?: Maybe<Scalars['String']>;
  typeStringList: Array<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type UserAccept = {
  __typename?: 'UserAccept';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  /** 정산 정보 수정 여부 */
  isModifyPayment?: Maybe<Scalars['Boolean']>;
  /** 수정 - 승인 사유 */
  modifyApproveComment?: Maybe<Scalars['String']>;
  /** 수정 - 반려 사유 */
  modifyRejectComment?: Maybe<Scalars['String']>;
  modifyStatus: Scalars['Float'];
  modifyStatusString: Scalars['String'];
  status: Scalars['Float'];
  statusString: Scalars['String'];
  statusStringList: Array<Scalars['String']>;
};

export type UserAcceptReference = {
  __typename?: 'UserAcceptReference';
  id: Scalars['Float'];
  url: Scalars['String'];
};

export type UserAcceptUpdateInput = {
  comment?: InputMaybe<Scalars['String']>;
  firstAcceptedDate?: InputMaybe<Scalars['DateTime']>;
  isFirstAccepted?: InputMaybe<Scalars['Boolean']>;
  isModifyPayment?: InputMaybe<Scalars['Boolean']>;
  isPass?: InputMaybe<Scalars['Boolean']>;
  modifyApproveComment?: InputMaybe<Scalars['String']>;
  modifyRejectComment?: InputMaybe<Scalars['String']>;
  modifyStatus?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Float']>;
};

export type UserBrandUpdateInput = {
  cnName?: InputMaybe<Scalars['String']>;
  enName?: InputMaybe<Scalars['String']>;
  godoBrandCd?: InputMaybe<Scalars['String']>;
  isExistBrand?: InputMaybe<Scalars['Boolean']>;
  jpName?: InputMaybe<Scalars['String']>;
  koName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UserFileInput = {
  bankBook?: InputMaybe<FileInput>;
  idCard?: InputMaybe<FileInput>;
  limitedTaxApl?: InputMaybe<FileInput>;
  sign?: InputMaybe<FileInput>;
  signedTerm: FileInput;
};

export type UserFileUpdateInput = {
  bankBook?: InputMaybe<FileUpdateInput>;
  idCard?: InputMaybe<FileUpdateInput>;
  limitedTaxApl?: InputMaybe<FileUpdateInput>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  /** 주소 */
  address: Scalars['String'];
  /** 상세 주소 */
  addressSub: Scalars['String'];
  /** 사업자 - 번호 */
  cashBusiNo: Scalars['String'];
  /** 사업자 - 휴대폰 번호 */
  cashCellPhone: Scalars['String'];
  /** 핸드폰 번호 */
  cellPhone: Scalars['String'];
  /** 주문자 예치금 액수 */
  deposit: Scalars['Float'];
  /** 주문자 이메일 */
  email: Scalars['String'];
  /** 주문자 명 */
  name: Scalars['String'];
  /** 주문자 명 */
  orderName: Scalars['String'];
  /** 필명 */
  penName: Scalars['String'];
  /** 주문자 포인트 액수 */
  point: Scalars['Float'];
  /** 세금 계산서 - 사업장 주소 */
  taxAddress: Scalars['String'];
  /** 세금 계산서 - 사업장 상세 주소 */
  taxAddressSub: Scalars['String'];
  /** 세금 계산서 - 사업자 번호 */
  taxBusiNo: Scalars['String'];
  /** 세금 계산서 - 대표자 명 */
  taxCeoNm: Scalars['String'];
  /** 세금 계산서 - 회사 명 */
  taxCompany: Scalars['String'];
  /** 세금 계산서 - 이메일 */
  taxEmail: Scalars['String'];
  /** 세금 계산서 - 종목 */
  taxItem: Scalars['String'];
  /** 세금 계산서 - 업태 */
  taxService: Scalars['String'];
  /** 세금 계산서 - 사업장 우편 코드 */
  taxZipcode: Scalars['String'];
  /** 세금 계산서 - 사업장 지역 코드 */
  taxZonecode: Scalars['String'];
  /** 우편 코드 */
  zipCode: Scalars['String'];
  /** 지역 코드 */
  zoneCode: Scalars['String'];
};

export type UserInput = {
  account: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  contact: Scalars['String'];
  contactAdditional?: InputMaybe<Scalars['String']>;
  joinLanguage: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  reference?: InputMaybe<Array<Scalars['String']>>;
  settle?: InputMaybe<SettleInput>;
  snsUrl?: InputMaybe<Scalars['String']>;
  subType: Scalars['String'];
  type: Scalars['String'];
};

export type UserMemoModel = {
  __typename?: 'UserMemoModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 메모 */
  memo: Scalars['String'];
  /** 수정 일자 */
  updated: Scalars['DateTime'];
  /** 주문 사용권 ID */
  userId: Scalars['Float'];
};

export enum UserOrderByEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum UserOrderColEnum {
  Created = 'created',
  RequestDate = 'requestDate'
}

export type UserPaginatedResponse = {
  __typename?: 'UserPaginatedResponse';
  count: Scalars['Int'];
  rows: Array<User>;
};

export type UserPlan = {
  __typename?: 'UserPlan';
  commissionRate?: Maybe<Scalars['Float']>;
  isExclusive: Scalars['Boolean'];
  type: Scalars['Float'];
  userId: Scalars['Float'];
};

export type UserPlanResponse = {
  __typename?: 'UserPlanResponse';
  commissionRate?: Maybe<Scalars['Float']>;
  isExclusive: Scalars['Boolean'];
  type: Scalars['Float'];
};

export type UserPlanUpdateInput = {
  commissionRate?: InputMaybe<Scalars['Float']>;
  isExclusive?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['Float']>;
};

export type UserReferenceInput = {
  url: Scalars['String'];
};

export type UserRequestFileUpdateInput = {
  sign?: InputMaybe<FileUpdateInput>;
  signedTerm?: InputMaybe<FileUpdateInput>;
};

export type UserRevisionInput = {
  brand?: InputMaybe<UserBrandUpdateInput>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<UserRequestFileUpdateInput>;
  name?: InputMaybe<Scalars['String']>;
  nationalIdNumber?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Array<UserReferenceInput>>;
};

export type UserSettle = {
  __typename?: 'UserSettle';
  /** 승인일자 */
  approveDate?: Maybe<Scalars['DateTime']>;
  bank?: Maybe<Bank>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountOwner?: Maybe<Scalars['String']>;
  bankBook?: Maybe<UploadFile>;
  bankId: Scalars['Float'];
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  idCard?: Maybe<UploadFile>;
  imageSign: Scalars['Float'];
  limitedTaxApl?: Maybe<UploadFile>;
  nationalIdNumber?: Maybe<Scalars['String']>;
  /** 반려일자 */
  rejectDate?: Maybe<Scalars['DateTime']>;
  /** 요청일자 */
  requestDate?: Maybe<Scalars['DateTime']>;
  sign?: Maybe<UploadFile>;
  signLang?: Maybe<Scalars['String']>;
  /** 상태 */
  status: UserSettleStatusEnum;
};

export enum UserSettleStatusEnum {
  Approve = 'approve',
  None = 'none',
  Reject = 'reject',
  Request = 'request',
  Rerequest = 'rerequest'
}

export type UserSettleStatusHistoryModel = {
  __typename?: 'UserSettleStatusHistoryModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 생성 일자 */
  created: Scalars['DateTime'];
  /** 고유 ID */
  id: Scalars['Float'];
  /** 메모 */
  memo?: Maybe<Scalars['String']>;
  /** 상태 */
  status: UserSettleStatusEnum;
  /** 업데이트 일자 */
  updated: Scalars['DateTime'];
  /** 사용자 ID */
  userId: Scalars['Float'];
};

export type UserSettleUpdateInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountOwner?: InputMaybe<Scalars['String']>;
  bankId?: InputMaybe<Scalars['Float']>;
  bankName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  nationalIdNumber?: InputMaybe<Scalars['String']>;
};

export type UserTermAgree = {
  __typename?: 'UserTermAgree';
  agreeAt: Scalars['DateTime'];
  file?: Maybe<UploadFile>;
  id: Scalars['Float'];
  uploadFileId?: Maybe<Scalars['Float']>;
};

export enum UserTypeEnum {
  Business = 'Business',
  Personal = 'Personal'
}

export type UserUpdateInput = {
  accept?: InputMaybe<UserAcceptUpdateInput>;
  account?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<UserBrandUpdateInput>;
  brandId?: InputMaybe<Scalars['Float']>;
  companyName?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  contactAdditional?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<UserFileUpdateInput>;
  isExistBrand?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<UserPlanUpdateInput>;
  reference?: InputMaybe<Array<UserReferenceInput>>;
  settle?: InputMaybe<UserSettleUpdateInput>;
  snsUrl?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type VBank = {
  __typename?: 'VBank';
  /** 예금주 명 */
  bankHolder?: Maybe<Scalars['String']>;
  /** 입금 은행 명 */
  bankName?: Maybe<Scalars['String']>;
  /** 가상계좌 번호 */
  bankNumber?: Maybe<Scalars['String']>;
  /** 송금 기한 */
  remittanceDate?: Maybe<Scalars['DateTime']>;
};

export type CosmicDealsStatistics = {
  __typename?: 'cosmicDealsStatistics';
  /** 탑승자 수 */
  totalBuyer?: Maybe<Scalars['String']>;
  /** 누적 금액 */
  totalPrice?: Maybe<Scalars['String']>;
};

export type GodoOrderGoodsNo = {
  __typename?: 'godoOrderGoodsNo';
  /** 고도몰 상품번호 */
  goodsNo: Scalars['String'];
  /** 가상계좌 여부 */
  isVirtualAccount: Scalars['Boolean'];
  /** 고도몰 주문번호 */
  orderNo: Scalars['String'];
  /** 주문 상태 */
  orderStatus: OrderStatusEnum;
  /** 주문 생성날짜 */
  regDt: Scalars['String'];
};

export const BrandFragmentDoc = gql`
    fragment brand on Acon3dBrand {
  code
  name
}
    `;
export const CategoriesFragmentDoc = gql`
    fragment categories on Acon3dCategory {
  code
  name
}
    `;
export const ModelConfigsFragmentDoc = gql`
    fragment modelConfigs on Acon3dModelConfig {
  id
  name
}
    `;
export const BannersFragmentDoc = gql`
    fragment banners on Acon3dBanner {
  id
  text
  href
  imageUrl
  type
  created
}
    `;
export const PromotionsBadgeFragmentDoc = gql`
    fragment promotionsBadge on Acon3dPromotionBadge {
  id
  type
  languageCode
  content
}
    `;
export const PromotionsFragmentDoc = gql`
    fragment promotions on Acon3dPromotion {
  id
  startDate
  currentRound
  remainingDays
  rounds {
    roundNo
    salePrice
    startDate
    endDate
  }
  badges {
    ...promotionsBadge
  }
}
    ${PromotionsBadgeFragmentDoc}`;
export const LinkedProductsFragmentDoc = gql`
    fragment linkedProducts on Acon3dLinkedProduct {
  id
  extensions
}
    `;
export const PackagesFragmentDoc = gql`
    fragment packages on Acon3dPackage {
  type
  parent {
    id
  }
  children {
    id
  }
}
    `;
export const FundingFragmentDoc = gql`
    fragment funding on Acon3dFunding {
  deliveryDate
  endDate
  goalAmount
  soldAmount
  soldVolume
  startDate
  status
}
    `;
export const ProductCardsFragmentDoc = gql`
    fragment productCards on Acon3dProduct {
  id
  languageCode
  title
  price
  salePrice
  onSale
  onDisplay
  promotionEndDate
  mainImageUrl
  subImageUrl
  isAconOnly
  orderCount
  viewCount
  reviewCount
  created
  updated
  badgeNames
  extensions
}
    `;
export const HotProductsDocument = gql`
    query HotProducts($count: Int) {
  hotProducts(count: $count)
}
    `;

/**
 * __useHotProductsQuery__
 *
 * To run a query within a React component, call `useHotProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotProductsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useHotProductsQuery(baseOptions?: Apollo.QueryHookOptions<HotProductsQuery, HotProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotProductsQuery, HotProductsQueryVariables>(HotProductsDocument, options);
      }
export function useHotProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotProductsQuery, HotProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotProductsQuery, HotProductsQueryVariables>(HotProductsDocument, options);
        }
export type HotProductsQueryHookResult = ReturnType<typeof useHotProductsQuery>;
export type HotProductsLazyQueryHookResult = ReturnType<typeof useHotProductsLazyQuery>;
export type HotProductsQueryResult = Apollo.QueryResult<HotProductsQuery, HotProductsQueryVariables>;
export const AprilAttendanceEventDocument = gql`
    mutation AprilAttendanceEvent($languageCode: String!) {
  aprilAttendanceEvent(languageCode: $languageCode)
}
    `;
export type AprilAttendanceEventMutationFn = Apollo.MutationFunction<AprilAttendanceEventMutation, AprilAttendanceEventMutationVariables>;

/**
 * __useAprilAttendanceEventMutation__
 *
 * To run a mutation, you first call `useAprilAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAprilAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aprilAttendanceEventMutation, { data, loading, error }] = useAprilAttendanceEventMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useAprilAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<AprilAttendanceEventMutation, AprilAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AprilAttendanceEventMutation, AprilAttendanceEventMutationVariables>(AprilAttendanceEventDocument, options);
}
export type AprilAttendanceEventMutationHookResult = ReturnType<typeof useAprilAttendanceEventMutation>;
export type AprilAttendanceEventMutationResult = Apollo.MutationResult<AprilAttendanceEventMutation>;
export type AprilAttendanceEventMutationOptions = Apollo.BaseMutationOptions<AprilAttendanceEventMutation, AprilAttendanceEventMutationVariables>;
export const GetAprilAttendanceEventDatesDocument = gql`
    query GetAprilAttendanceEventDates {
  getAprilAttendanceEventDates
}
    `;

/**
 * __useGetAprilAttendanceEventDatesQuery__
 *
 * To run a query within a React component, call `useGetAprilAttendanceEventDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAprilAttendanceEventDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAprilAttendanceEventDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAprilAttendanceEventDatesQuery(baseOptions?: Apollo.QueryHookOptions<GetAprilAttendanceEventDatesQuery, GetAprilAttendanceEventDatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAprilAttendanceEventDatesQuery, GetAprilAttendanceEventDatesQueryVariables>(GetAprilAttendanceEventDatesDocument, options);
}
export function useGetAprilAttendanceEventDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAprilAttendanceEventDatesQuery, GetAprilAttendanceEventDatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAprilAttendanceEventDatesQuery, GetAprilAttendanceEventDatesQueryVariables>(GetAprilAttendanceEventDatesDocument, options);
}
export type GetAprilAttendanceEventDatesQueryHookResult = ReturnType<typeof useGetAprilAttendanceEventDatesQuery>;
export type GetAprilAttendanceEventDatesLazyQueryHookResult = ReturnType<typeof useGetAprilAttendanceEventDatesLazyQuery>;
export type GetAprilAttendanceEventDatesQueryResult = Apollo.QueryResult<GetAprilAttendanceEventDatesQuery, GetAprilAttendanceEventDatesQueryVariables>;
export const IsParticipatedEventDocument = gql`
    query IsParticipatedEvent($eventId: Float!) {
  isParticipatedEvent(eventId: $eventId)
}
    `;

/**
 * __useIsParticipatedEventQuery__
 *
 * To run a query within a React component, call `useIsParticipatedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsParticipatedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsParticipatedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useIsParticipatedEventQuery(baseOptions: Apollo.QueryHookOptions<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>(IsParticipatedEventDocument, options);
}
export function useIsParticipatedEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>(IsParticipatedEventDocument, options);
}
export type IsParticipatedEventQueryHookResult = ReturnType<typeof useIsParticipatedEventQuery>;
export type IsParticipatedEventLazyQueryHookResult = ReturnType<typeof useIsParticipatedEventLazyQuery>;
export type IsParticipatedEventQueryResult = Apollo.QueryResult<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>;
export const IssueEventCouponDocument = gql`
    mutation IssueEventCoupon($issueEventCouponInput: IssueEventCouponInput!) {
  issueEventCoupon(issueEventCouponInput: $issueEventCouponInput)
}
    `;
export type IssueEventCouponMutationFn = Apollo.MutationFunction<IssueEventCouponMutation, IssueEventCouponMutationVariables>;

/**
 * __useIssueEventCouponMutation__
 *
 * To run a mutation, you first call `useIssueEventCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueEventCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueEventCouponMutation, { data, loading, error }] = useIssueEventCouponMutation({
 *   variables: {
 *      issueEventCouponInput: // value for 'issueEventCouponInput'
 *   },
 * });
 */
export function useIssueEventCouponMutation(baseOptions?: Apollo.MutationHookOptions<IssueEventCouponMutation, IssueEventCouponMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueEventCouponMutation, IssueEventCouponMutationVariables>(IssueEventCouponDocument, options);
}
export type IssueEventCouponMutationHookResult = ReturnType<typeof useIssueEventCouponMutation>;
export type IssueEventCouponMutationResult = Apollo.MutationResult<IssueEventCouponMutation>;
export type IssueEventCouponMutationOptions = Apollo.BaseMutationOptions<IssueEventCouponMutation, IssueEventCouponMutationVariables>;
export const UserEventPeriodTotalPaymentDocument = gql`
    query UserEventPeriodTotalPayment($eventId: Float!) {
  userEventPeriodTotalPayment(eventId: $eventId)
}
    `;

/**
 * __useUserEventPeriodTotalPaymentQuery__
 *
 * To run a query within a React component, call `useUserEventPeriodTotalPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEventPeriodTotalPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventPeriodTotalPaymentQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUserEventPeriodTotalPaymentQuery(baseOptions: Apollo.QueryHookOptions<UserEventPeriodTotalPaymentQuery, UserEventPeriodTotalPaymentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserEventPeriodTotalPaymentQuery, UserEventPeriodTotalPaymentQueryVariables>(UserEventPeriodTotalPaymentDocument, options);
}
export function useUserEventPeriodTotalPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEventPeriodTotalPaymentQuery, UserEventPeriodTotalPaymentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserEventPeriodTotalPaymentQuery, UserEventPeriodTotalPaymentQueryVariables>(UserEventPeriodTotalPaymentDocument, options);
}
export type UserEventPeriodTotalPaymentQueryHookResult = ReturnType<typeof useUserEventPeriodTotalPaymentQuery>;
export type UserEventPeriodTotalPaymentLazyQueryHookResult = ReturnType<typeof useUserEventPeriodTotalPaymentLazyQuery>;
export type UserEventPeriodTotalPaymentQueryResult = Apollo.QueryResult<UserEventPeriodTotalPaymentQuery, UserEventPeriodTotalPaymentQueryVariables>;
export const GetAttendanceEventDatesDocument = gql`
    query getAttendanceEventDates($eventId: Float!) {
  getAttendanceEventDates(eventId: $eventId)
}
    `;

/**
 * __useGetAttendanceEventDatesQuery__
 *
 * To run a query within a React component, call `useGetAttendanceEventDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceEventDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceEventDatesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetAttendanceEventDatesQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>(GetAttendanceEventDatesDocument, options);
      }
export function useGetAttendanceEventDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>(GetAttendanceEventDatesDocument, options);
        }
export type GetAttendanceEventDatesQueryHookResult = ReturnType<typeof useGetAttendanceEventDatesQuery>;
export type GetAttendanceEventDatesLazyQueryHookResult = ReturnType<typeof useGetAttendanceEventDatesLazyQuery>;
export type GetAttendanceEventDatesQueryResult = Apollo.QueryResult<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>;
export const AttendanceEventDocument = gql`
    mutation AttendanceEvent($eventId: Float!, $languageCode: String!) {
  attendanceEvent(eventId: $eventId, languageCode: $languageCode)
}
    `;
export type AttendanceEventMutationFn = Apollo.MutationFunction<AttendanceEventMutation, AttendanceEventMutationVariables>;

/**
 * __useAttendanceEventMutation__
 *
 * To run a mutation, you first call `useAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceEventMutation, { data, loading, error }] = useAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<AttendanceEventMutation, AttendanceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttendanceEventMutation, AttendanceEventMutationVariables>(AttendanceEventDocument, options);
      }
export type AttendanceEventMutationHookResult = ReturnType<typeof useAttendanceEventMutation>;
export type AttendanceEventMutationResult = Apollo.MutationResult<AttendanceEventMutation>;
export type AttendanceEventMutationOptions = Apollo.BaseMutationOptions<AttendanceEventMutation, AttendanceEventMutationVariables>;
export const BestProductsDocument = gql`
    query BestProducts($type: BestProductType!, $categoryCode: String, $isFreeProductInclude: Boolean) {
  bestProducts(
    type: $type
    categoryCode: $categoryCode
    isFreeProductInclude: $isFreeProductInclude
  )
}
    `;

/**
 * __useBestProductsQuery__
 *
 * To run a query within a React component, call `useBestProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestProductsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      categoryCode: // value for 'categoryCode'
 *      isFreeProductInclude: // value for 'isFreeProductInclude'
 *   },
 * });
 */
export function useBestProductsQuery(baseOptions: Apollo.QueryHookOptions<BestProductsQuery, BestProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestProductsQuery, BestProductsQueryVariables>(BestProductsDocument, options);
}
export function useBestProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BestProductsQuery, BestProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestProductsQuery, BestProductsQueryVariables>(BestProductsDocument, options);
}
export type BestProductsQueryHookResult = ReturnType<typeof useBestProductsQuery>;
export type BestProductsLazyQueryHookResult = ReturnType<typeof useBestProductsLazyQuery>;
export type BestProductsQueryResult = Apollo.QueryResult<BestProductsQuery, BestProductsQueryVariables>;
export const BrandProductsWithCategoriesDocument = gql`
    query BrandProductsWithCategories($languageCode: LanguageCodeEnum!, $brandCode: String!) {
  brandProductsWithCategories(languageCode: $languageCode, brandCode: $brandCode) {
    id
    categories {
      code
    }
  }
}
    `;

/**
 * __useBrandProductsWithCategoriesQuery__
 *
 * To run a query within a React component, call `useBrandProductsWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandProductsWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandProductsWithCategoriesQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useBrandProductsWithCategoriesQuery(baseOptions: Apollo.QueryHookOptions<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>(BrandProductsWithCategoriesDocument, options);
}
export function useBrandProductsWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>(BrandProductsWithCategoriesDocument, options);
}
export type BrandProductsWithCategoriesQueryHookResult = ReturnType<typeof useBrandProductsWithCategoriesQuery>;
export type BrandProductsWithCategoriesLazyQueryHookResult = ReturnType<typeof useBrandProductsWithCategoriesLazyQuery>;
export type BrandProductsWithCategoriesQueryResult = Apollo.QueryResult<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>;
export const CancelInquiryDocument = gql`
    mutation CancelInquiry($CancelInquiryInput: CancelInquiryInput!) {
  cancelInquiry(input: $CancelInquiryInput) {
    id
  }
}
    `;
export type CancelInquiryMutationFn = Apollo.MutationFunction<CancelInquiryMutation, CancelInquiryMutationVariables>;

/**
 * __useCancelInquiryMutation__
 *
 * To run a mutation, you first call `useCancelInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInquiryMutation, { data, loading, error }] = useCancelInquiryMutation({
 *   variables: {
 *      CancelInquiryInput: // value for 'CancelInquiryInput'
 *   },
 * });
 */
export function useCancelInquiryMutation(baseOptions?: Apollo.MutationHookOptions<CancelInquiryMutation, CancelInquiryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelInquiryMutation, CancelInquiryMutationVariables>(CancelInquiryDocument, options);
}
export type CancelInquiryMutationHookResult = ReturnType<typeof useCancelInquiryMutation>;
export type CancelInquiryMutationResult = Apollo.MutationResult<CancelInquiryMutation>;
export type CancelInquiryMutationOptions = Apollo.BaseMutationOptions<CancelInquiryMutation, CancelInquiryMutationVariables>;
export const CartDocument = gql`
    query Cart {
  cart {
    productNo
  }
}
    `;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CheckExistMemberDocument = gql`
    query CheckExistMember($accessToken: String!, $snsType: SnsTypeEnum!) {
  checkExistMember(accessToken: $accessToken, snsType: $snsType)
}
    `;

/**
 * __useCheckExistMemberQuery__
 *
 * To run a query within a React component, call `useCheckExistMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistMemberQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      snsType: // value for 'snsType'
 *   },
 * });
 */
export function useCheckExistMemberQuery(baseOptions: Apollo.QueryHookOptions<CheckExistMemberQuery, CheckExistMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckExistMemberQuery, CheckExistMemberQueryVariables>(CheckExistMemberDocument, options);
}
export function useCheckExistMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckExistMemberQuery, CheckExistMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckExistMemberQuery, CheckExistMemberQueryVariables>(CheckExistMemberDocument, options);
}
export type CheckExistMemberQueryHookResult = ReturnType<typeof useCheckExistMemberQuery>;
export type CheckExistMemberLazyQueryHookResult = ReturnType<typeof useCheckExistMemberLazyQuery>;
export type CheckExistMemberQueryResult = Apollo.QueryResult<CheckExistMemberQuery, CheckExistMemberQueryVariables>;
export const CheckIssuanceTempPasswordDocument = gql`
    query CheckIssuanceTempPassword($snsType: SnsTypeEnum!, $aconMemberId: Int!) {
  checkIssuanceTempPassword(snsType: $snsType, aconMemberId: $aconMemberId)
}
    `;

/**
 * __useCheckIssuanceTempPasswordQuery__
 *
 * To run a query within a React component, call `useCheckIssuanceTempPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIssuanceTempPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIssuanceTempPasswordQuery({
 *   variables: {
 *      snsType: // value for 'snsType'
 *      aconMemberId: // value for 'aconMemberId'
 *   },
 * });
 */
export function useCheckIssuanceTempPasswordQuery(baseOptions: Apollo.QueryHookOptions<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>(CheckIssuanceTempPasswordDocument, options);
}
export function useCheckIssuanceTempPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>(CheckIssuanceTempPasswordDocument, options);
}
export type CheckIssuanceTempPasswordQueryHookResult = ReturnType<typeof useCheckIssuanceTempPasswordQuery>;
export type CheckIssuanceTempPasswordLazyQueryHookResult = ReturnType<typeof useCheckIssuanceTempPasswordLazyQuery>;
export type CheckIssuanceTempPasswordQueryResult = Apollo.QueryResult<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>;
export const CreateInquiryDocument = gql`
    mutation CreateInquiry($email: String, $languageCode: LanguageCodeEnum!, $type: InquiryTypeEnum!, $subType: InquirySubTypeEnum, $content: String, $taxes: TaxesInput, $changePenName: ChangePenNameInput, $inquiryProduct: InquiryProductInput, $inquiryOrders: [InquiryOrderInput], $refundAccount: RefundAccountInput, $uploadFiles: [Int!]!) {
  createInquiry(
    input: {email: $email, languageCode: $languageCode, type: $type, subType: $subType, content: $content, taxes: $taxes, changePenName: $changePenName, inquiryProduct: $inquiryProduct, inquiryOrders: $inquiryOrders, refundAccount: $refundAccount, uploadFiles: $uploadFiles}
  ) {
    id
  }
}
    `;
export type CreateInquiryMutationFn = Apollo.MutationFunction<CreateInquiryMutation, CreateInquiryMutationVariables>;

/**
 * __useCreateInquiryMutation__
 *
 * To run a mutation, you first call `useCreateInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInquiryMutation, { data, loading, error }] = useCreateInquiryMutation({
 *   variables: {
 *      email: // value for 'email'
 *      languageCode: // value for 'languageCode'
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      content: // value for 'content'
 *      taxes: // value for 'taxes'
 *      changePenName: // value for 'changePenName'
 *      inquiryProduct: // value for 'inquiryProduct'
 *      inquiryOrders: // value for 'inquiryOrders'
 *      refundAccount: // value for 'refundAccount'
 *      uploadFiles: // value for 'uploadFiles'
 *   },
 * });
 */
export function useCreateInquiryMutation(baseOptions?: Apollo.MutationHookOptions<CreateInquiryMutation, CreateInquiryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInquiryMutation, CreateInquiryMutationVariables>(CreateInquiryDocument, options);
}
export type CreateInquiryMutationHookResult = ReturnType<typeof useCreateInquiryMutation>;
export type CreateInquiryMutationResult = Apollo.MutationResult<CreateInquiryMutation>;
export type CreateInquiryMutationOptions = Apollo.BaseMutationOptions<CreateInquiryMutation, CreateInquiryMutationVariables>;
export const DeleteCartCacheDocument = gql`
    mutation DeleteCartCache {
  deleteCartCache
}
    `;
export type DeleteCartCacheMutationFn = Apollo.MutationFunction<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>;

/**
 * __useDeleteCartCacheMutation__
 *
 * To run a mutation, you first call `useDeleteCartCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartCacheMutation, { data, loading, error }] = useDeleteCartCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCartCacheMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>(DeleteCartCacheDocument, options);
}
export type DeleteCartCacheMutationHookResult = ReturnType<typeof useDeleteCartCacheMutation>;
export type DeleteCartCacheMutationResult = Apollo.MutationResult<DeleteCartCacheMutation>;
export type DeleteCartCacheMutationOptions = Apollo.BaseMutationOptions<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>;
export const DeleteMyReviewDocument = gql`
    mutation DeleteMyReview($id: Int!, $languageCode: String!) {
  deleteMyReview(id: $id, languageCode: $languageCode)
}
    `;
export type DeleteMyReviewMutationFn = Apollo.MutationFunction<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>;

/**
 * __useDeleteMyReviewMutation__
 *
 * To run a mutation, you first call `useDeleteMyReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyReviewMutation, { data, loading, error }] = useDeleteMyReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useDeleteMyReviewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>(DeleteMyReviewDocument, options);
}
export type DeleteMyReviewMutationHookResult = ReturnType<typeof useDeleteMyReviewMutation>;
export type DeleteMyReviewMutationResult = Apollo.MutationResult<DeleteMyReviewMutation>;
export type DeleteMyReviewMutationOptions = Apollo.BaseMutationOptions<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>;
export const DocumentDocument = gql`
    query Document($documentId: String!) {
  document(id: $documentId) {
    id
    assetId
    brandId
    brandNames {
      language
      name
    }
    language
    status
    title
    contentHead
    contentBody
    price
    mainImage
    subImage
    fileName
    filePath
    categories {
      genre {
        primary
        secondary
      }
      theme {
        primary
        secondary
      }
    }
    copyright {
      isOriginal
      warehouseSources
      commercialSources
      hasRealLogo
    }
    keywords
  }
}
    `;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
}
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
}
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = Apollo.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const GetAconCashListDocument = gql`
    query getAconCashList($page: Int!, $type: String!, $startDate: String!, $endDate: String!) {
  getCashList(page: $page, type: $type, startDate: $startDate, endDate: $endDate) {
    totalCount
    items {
      deposit
      contents
      regDate
      afterDeposit
      payMethod
      status
      vBank {
        bankName
        bankHolder
        bankNumber
        remittanceDate
      }
    }
  }
}
    `;

/**
 * __useGetAconCashListQuery__
 *
 * To run a query within a React component, call `useGetAconCashListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAconCashListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAconCashListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      type: // value for 'type'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAconCashListQuery(baseOptions: Apollo.QueryHookOptions<GetAconCashListQuery, GetAconCashListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAconCashListQuery, GetAconCashListQueryVariables>(GetAconCashListDocument, options);
}
export function useGetAconCashListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAconCashListQuery, GetAconCashListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAconCashListQuery, GetAconCashListQueryVariables>(GetAconCashListDocument, options);
}
export type GetAconCashListQueryHookResult = ReturnType<typeof useGetAconCashListQuery>;
export type GetAconCashListLazyQueryHookResult = ReturnType<typeof useGetAconCashListLazyQuery>;
export type GetAconCashListQueryResult = Apollo.QueryResult<GetAconCashListQuery, GetAconCashListQueryVariables>;
export const GetApplicationsDocument = gql`
    query GetApplications {
  getApplications {
    id
    name
  }
}
    `;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a React component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(GetApplicationsDocument, options);
}
export function useGetApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(GetApplicationsDocument, options);
}
export type GetApplicationsQueryHookResult = ReturnType<typeof useGetApplicationsQuery>;
export type GetApplicationsLazyQueryHookResult = ReturnType<typeof useGetApplicationsLazyQuery>;
export type GetApplicationsQueryResult = Apollo.QueryResult<GetApplicationsQuery, GetApplicationsQueryVariables>;
export const GetBrandsDocument = gql`
    query GetBrands {
  getBrands {
    id
    name
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
}
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
}
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetCartsDocument = gql`
    query GetCarts($lang: String!) {
  carts(lang: $lang) {
    sno
    goodsNo
    point
  }
}
    `;

/**
 * __useGetCartsQuery__
 *
 * To run a query within a React component, call `useGetCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCartsQuery(baseOptions: Apollo.QueryHookOptions<GetCartsQuery, GetCartsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCartsQuery, GetCartsQueryVariables>(GetCartsDocument, options);
}
export function useGetCartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartsQuery, GetCartsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCartsQuery, GetCartsQueryVariables>(GetCartsDocument, options);
}
export type GetCartsQueryHookResult = ReturnType<typeof useGetCartsQuery>;
export type GetCartsLazyQueryHookResult = ReturnType<typeof useGetCartsLazyQuery>;
export type GetCartsQueryResult = Apollo.QueryResult<GetCartsQuery, GetCartsQueryVariables>;
export const GetCategoriesV3Document = gql`
    query GetCategoriesV3($languageCode: LanguageCodeEnum!) {
  categoriesV3(languageCode: $languageCode) {
    type
    categories {
      primary {
        code
        name
      }
      secondaries {
        code
        name
      }
    }
  }
}
    `;

/**
 * __useGetCategoriesV3Query__
 *
 * To run a query within a React component, call `useGetCategoriesV3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesV3Query({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetCategoriesV3Query(baseOptions: Apollo.QueryHookOptions<GetCategoriesV3Query, GetCategoriesV3QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoriesV3Query, GetCategoriesV3QueryVariables>(GetCategoriesV3Document, options);
}
export function useGetCategoriesV3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesV3Query, GetCategoriesV3QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoriesV3Query, GetCategoriesV3QueryVariables>(GetCategoriesV3Document, options);
}
export type GetCategoriesV3QueryHookResult = ReturnType<typeof useGetCategoriesV3Query>;
export type GetCategoriesV3LazyQueryHookResult = ReturnType<typeof useGetCategoriesV3LazyQuery>;
export type GetCategoriesV3QueryResult = Apollo.QueryResult<GetCategoriesV3Query, GetCategoriesV3QueryVariables>;
export const GetCategoryDocument = gql`
    query GetCategory($godoCateCd: String!) {
  getCategory(godoCateCd: $godoCateCd) {
    type
    trans {
      lang
      visibleResultStats
      name
      banner {
        image
        anchor
        anchorTarget
        map
        color
      }
    }
  }
}
    `;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      godoCateCd: // value for 'godoCateCd'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
}
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
}
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>;
export const GetCosmicDealsStatisticsDocument = gql`
    query GetCosmicDealsStatistics($lang: String!) {
  cosmicDealsStatisticsV2(lang: $lang) {
    totalPrice
    totalBuyer
  }
}
    `;

/**
 * __useGetCosmicDealsStatisticsQuery__
 *
 * To run a query within a React component, call `useGetCosmicDealsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCosmicDealsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCosmicDealsStatisticsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCosmicDealsStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetCosmicDealsStatisticsQuery, GetCosmicDealsStatisticsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCosmicDealsStatisticsQuery, GetCosmicDealsStatisticsQueryVariables>(GetCosmicDealsStatisticsDocument, options);
}
export function useGetCosmicDealsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCosmicDealsStatisticsQuery, GetCosmicDealsStatisticsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCosmicDealsStatisticsQuery, GetCosmicDealsStatisticsQueryVariables>(GetCosmicDealsStatisticsDocument, options);
}
export type GetCosmicDealsStatisticsQueryHookResult = ReturnType<typeof useGetCosmicDealsStatisticsQuery>;
export type GetCosmicDealsStatisticsLazyQueryHookResult = ReturnType<typeof useGetCosmicDealsStatisticsLazyQuery>;
export type GetCosmicDealsStatisticsQueryResult = Apollo.QueryResult<GetCosmicDealsStatisticsQuery, GetCosmicDealsStatisticsQueryVariables>;
export const GetCouponItemsDocument = gql`
    query GetCouponItems($couponNos: [String!]) {
  getCouponItems(couponNos: $couponNos) {
    godoCouponNo
    remaining
  }
}
    `;

/**
 * __useGetCouponItemsQuery__
 *
 * To run a query within a React component, call `useGetCouponItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponItemsQuery({
 *   variables: {
 *      couponNos: // value for 'couponNos'
 *   },
 * });
 */
export function useGetCouponItemsQuery(baseOptions?: Apollo.QueryHookOptions<GetCouponItemsQuery, GetCouponItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponItemsQuery, GetCouponItemsQueryVariables>(GetCouponItemsDocument, options);
}
export function useGetCouponItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponItemsQuery, GetCouponItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponItemsQuery, GetCouponItemsQueryVariables>(GetCouponItemsDocument, options);
}
export type GetCouponItemsQueryHookResult = ReturnType<typeof useGetCouponItemsQuery>;
export type GetCouponItemsLazyQueryHookResult = ReturnType<typeof useGetCouponItemsLazyQuery>;
export type GetCouponItemsQueryResult = Apollo.QueryResult<GetCouponItemsQuery, GetCouponItemsQueryVariables>;
export const GetCouponListDocument = gql`
    query GetCouponList($lang: String!, $page: Int!, $startDate: String, $endDate: String, $state: String) {
  getCouponList(
    lang: $lang
    page: $page
    startDate: $startDate
    endDate: $endDate
    state: $state
  ) {
    totalCount
    items {
      name
      couponKindType
      couponBenefit
      couponBenefitType
      couponMaxBenefit
      isCouponMaxBenefit
      couponApplyProductType
      couponApplyGoods {
        goodsNm
        goodsNo
      }
      couponApplyCategory {
        no
        name
      }
      couponMinOrderPrice
      couponMinOrderType
      isCouponApplyDuplicate
      memberCouponUsable
      startDate
      endDate
      regDt
    }
  }
}
    `;

/**
 * __useGetCouponListQuery__
 *
 * To run a query within a React component, call `useGetCouponListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponListQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      page: // value for 'page'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetCouponListQuery(baseOptions: Apollo.QueryHookOptions<GetCouponListQuery, GetCouponListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponListQuery, GetCouponListQueryVariables>(GetCouponListDocument, options);
}
export function useGetCouponListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponListQuery, GetCouponListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponListQuery, GetCouponListQueryVariables>(GetCouponListDocument, options);
}
export type GetCouponListQueryHookResult = ReturnType<typeof useGetCouponListQuery>;
export type GetCouponListLazyQueryHookResult = ReturnType<typeof useGetCouponListLazyQuery>;
export type GetCouponListQueryResult = Apollo.QueryResult<GetCouponListQuery, GetCouponListQueryVariables>;
export const GetExchangeRateDocument = gql`
    query GetExchangeRate($languageCode: LanguageCodeEnum!) {
  exchangeRate(languageCode: $languageCode)
}
    `;

/**
 * __useGetExchangeRateQuery__
 *
 * To run a query within a React component, call `useGetExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeRateQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<GetExchangeRateQuery, GetExchangeRateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeRateQuery, GetExchangeRateQueryVariables>(GetExchangeRateDocument, options);
}
export function useGetExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeRateQuery, GetExchangeRateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeRateQuery, GetExchangeRateQueryVariables>(GetExchangeRateDocument, options);
}
export type GetExchangeRateQueryHookResult = ReturnType<typeof useGetExchangeRateQuery>;
export type GetExchangeRateLazyQueryHookResult = ReturnType<typeof useGetExchangeRateLazyQuery>;
export type GetExchangeRateQueryResult = Apollo.QueryResult<GetExchangeRateQuery, GetExchangeRateQueryVariables>;
export const GetExtensionConfigsDocument = gql`
    query GetExtensionConfigs {
  getExtensionConfigs {
    id
    name
  }
}
    `;

/**
 * __useGetExtensionConfigsQuery__
 *
 * To run a query within a React component, call `useGetExtensionConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtensionConfigsQuery(baseOptions?: Apollo.QueryHookOptions<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>(GetExtensionConfigsDocument, options);
}
export function useGetExtensionConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>(GetExtensionConfigsDocument, options);
}
export type GetExtensionConfigsQueryHookResult = ReturnType<typeof useGetExtensionConfigsQuery>;
export type GetExtensionConfigsLazyQueryHookResult = ReturnType<typeof useGetExtensionConfigsLazyQuery>;
export type GetExtensionConfigsQueryResult = Apollo.QueryResult<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>;
export const GetGodoBrandNameDocument = gql`
    query GetGodoBrandName($lang: String, $brandCode: String) {
  getGodoBrandName(brandCode: $brandCode, lang: $lang)
}
    `;

/**
 * __useGetGodoBrandNameQuery__
 *
 * To run a query within a React component, call `useGetGodoBrandNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGodoBrandNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGodoBrandNameQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetGodoBrandNameQuery(baseOptions?: Apollo.QueryHookOptions<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>(GetGodoBrandNameDocument, options);
}
export function useGetGodoBrandNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>(GetGodoBrandNameDocument, options);
}
export type GetGodoBrandNameQueryHookResult = ReturnType<typeof useGetGodoBrandNameQuery>;
export type GetGodoBrandNameLazyQueryHookResult = ReturnType<typeof useGetGodoBrandNameLazyQuery>;
export type GetGodoBrandNameQueryResult = Apollo.QueryResult<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>;
export const GetGodoCategoriesDocument = gql`
    query GetGodoCategories($lang: String!, $cateCds: [String!]!) {
  getTransGodoCategoryItems(lang: $lang, cateCds: $cateCds) {
    name
    code
  }
}
    `;

/**
 * __useGetGodoCategoriesQuery__
 *
 * To run a query within a React component, call `useGetGodoCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGodoCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGodoCategoriesQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      cateCds: // value for 'cateCds'
 *   },
 * });
 */
export function useGetGodoCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>(GetGodoCategoriesDocument, options);
}
export function useGetGodoCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>(GetGodoCategoriesDocument, options);
}
export type GetGodoCategoriesQueryHookResult = ReturnType<typeof useGetGodoCategoriesQuery>;
export type GetGodoCategoriesLazyQueryHookResult = ReturnType<typeof useGetGodoCategoriesLazyQuery>;
export type GetGodoCategoriesQueryResult = Apollo.QueryResult<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>;
export const GetGodoMyOrdersDocument = gql`
    query GetGodoMyOrders($limit: Int, $offset: Int) {
  godoMyOrders(limit: $limit, offset: $offset) {
    orderNo
    goodsNo
    regDt
    orderStatus
    isVirtualAccount
  }
}
    `;

/**
 * __useGetGodoMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetGodoMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGodoMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGodoMyOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetGodoMyOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>(GetGodoMyOrdersDocument, options);
}
export function useGetGodoMyOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>(GetGodoMyOrdersDocument, options);
}
export type GetGodoMyOrdersQueryHookResult = ReturnType<typeof useGetGodoMyOrdersQuery>;
export type GetGodoMyOrdersLazyQueryHookResult = ReturnType<typeof useGetGodoMyOrdersLazyQuery>;
export type GetGodoMyOrdersQueryResult = Apollo.QueryResult<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>;
export const GetInquiriesDocument = gql`
    query GetInquiries($limit: Int, $offset: Int) {
  inquiries(limit: $limit, offset: $offset) {
    id
    type
    content
    status
    answer {
      answer
    }
    created
  }
}
    `;

/**
 * __useGetInquiriesQuery__
 *
 * To run a query within a React component, call `useGetInquiriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInquiriesQuery(baseOptions?: Apollo.QueryHookOptions<GetInquiriesQuery, GetInquiriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInquiriesQuery, GetInquiriesQueryVariables>(GetInquiriesDocument, options);
}
export function useGetInquiriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInquiriesQuery, GetInquiriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInquiriesQuery, GetInquiriesQueryVariables>(GetInquiriesDocument, options);
}
export type GetInquiriesQueryHookResult = ReturnType<typeof useGetInquiriesQuery>;
export type GetInquiriesLazyQueryHookResult = ReturnType<typeof useGetInquiriesLazyQuery>;
export type GetInquiriesQueryResult = Apollo.QueryResult<GetInquiriesQuery, GetInquiriesQueryVariables>;
export const GetInquiryDocument = gql`
    query GetInquiry($id: Int!) {
  inquiry(id: $id) {
    id
    godoMemNo
    godoUserId
    languageCode
    type
    status
    inquiryAcon3dProduct {
      id
      title
    }
    inquiryOrders {
      godoOrderNo
      godoGoodsDetails {
        godoGoodsNo
        orderStatus
      }
    }
    subType
    content
    answer {
      answer
      created
    }
    uploadFiles {
      azureStoragePath
      fileName
    }
    created
    updated
  }
}
    `;

/**
 * __useGetInquiryQuery__
 *
 * To run a query within a React component, call `useGetInquiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInquiryQuery(baseOptions: Apollo.QueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
}
export function useGetInquiryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
}
export type GetInquiryQueryHookResult = ReturnType<typeof useGetInquiryQuery>;
export type GetInquiryLazyQueryHookResult = ReturnType<typeof useGetInquiryLazyQuery>;
export type GetInquiryQueryResult = Apollo.QueryResult<GetInquiryQuery, GetInquiryQueryVariables>;
export const GetInquiryListDocument = gql`
    query GetInquiryList($type: InquiryTypeEnum, $limit: Int, $offset: Int) {
  inquiries(type: $type, limit: $limit, offset: $offset) {
    id
    type
    content
    status
    answer {
      answer
    }
    created
  }
  inquiryCount(type: $type)
}
    `;

/**
 * __useGetInquiryListQuery__
 *
 * To run a query within a React component, call `useGetInquiryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiryListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInquiryListQuery(baseOptions?: Apollo.QueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
}
export function useGetInquiryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
}
export type GetInquiryListQueryHookResult = ReturnType<typeof useGetInquiryListQuery>;
export type GetInquiryListLazyQueryHookResult = ReturnType<typeof useGetInquiryListLazyQuery>;
export type GetInquiryListQueryResult = Apollo.QueryResult<GetInquiryListQuery, GetInquiryListQueryVariables>;
export const GetIsFirstDownloadDocument = gql`
    query GetIsFirstDownload($goodsNo: Int!, $orderNo: String!) {
  isFirstDownload(goodsNo: $goodsNo, orderNo: $orderNo)
}
    `;

/**
 * __useGetIsFirstDownloadQuery__
 *
 * To run a query within a React component, call `useGetIsFirstDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsFirstDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsFirstDownloadQuery({
 *   variables: {
 *      goodsNo: // value for 'goodsNo'
 *      orderNo: // value for 'orderNo'
 *   },
 * });
 */
export function useGetIsFirstDownloadQuery(baseOptions: Apollo.QueryHookOptions<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>(GetIsFirstDownloadDocument, options);
}
export function useGetIsFirstDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>(GetIsFirstDownloadDocument, options);
}
export type GetIsFirstDownloadQueryHookResult = ReturnType<typeof useGetIsFirstDownloadQuery>;
export type GetIsFirstDownloadLazyQueryHookResult = ReturnType<typeof useGetIsFirstDownloadLazyQuery>;
export type GetIsFirstDownloadQueryResult = Apollo.QueryResult<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>;
export const GetMyOrdersDocument = gql`
    query GetMyOrders($languageCode: LanguageCodeEnum!) {
  getMyOrders(languageCode: $languageCode) {
    godoOrderNo
    godoGoodsNo
    price
    orderGoodsStatus
    regDt
    product {
      title
      mainImageUrl
      subImageUrl
      extensions
      salePrice
      price
      brand {
        code
        name
      }
      funding {
        ...funding
      }
    }
    reviewId
    downloadCountInfo {
      limit
      remained
    }
  }
}
    ${FundingFragmentDoc}`;

/**
 * __useGetMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrdersQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetMyOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export function useGetMyOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export type GetMyOrdersQueryHookResult = ReturnType<typeof useGetMyOrdersQuery>;
export type GetMyOrdersLazyQueryHookResult = ReturnType<typeof useGetMyOrdersLazyQuery>;
export type GetMyOrdersQueryResult = Apollo.QueryResult<GetMyOrdersQuery, GetMyOrdersQueryVariables>;
export const GetMyReviewDocument = gql`
    query GetMyReview($getMyReviewId: Int!) {
  getMyReview(id: $getMyReviewId) {
    id
    reviewContentsItems {
      title
      contents
      languageCode
      isOriginal
    }
    rating
    imageUrl
  }
}
    `;

/**
 * __useGetMyReviewQuery__
 *
 * To run a query within a React component, call `useGetMyReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReviewQuery({
 *   variables: {
 *      getMyReviewId: // value for 'getMyReviewId'
 *   },
 * });
 */
export function useGetMyReviewQuery(baseOptions: Apollo.QueryHookOptions<GetMyReviewQuery, GetMyReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyReviewQuery, GetMyReviewQueryVariables>(GetMyReviewDocument, options);
}
export function useGetMyReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyReviewQuery, GetMyReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyReviewQuery, GetMyReviewQueryVariables>(GetMyReviewDocument, options);
}
export type GetMyReviewQueryHookResult = ReturnType<typeof useGetMyReviewQuery>;
export type GetMyReviewLazyQueryHookResult = ReturnType<typeof useGetMyReviewLazyQuery>;
export type GetMyReviewQueryResult = Apollo.QueryResult<GetMyReviewQuery, GetMyReviewQueryVariables>;
export const GetMyReviewsByGodoGoodsNoDocument = gql`
    query GetMyReviewsByGodoGoodsNo($godoGoodsNo: String!) {
  getMyReviewsByGodoGoodsNo(godoGoodsNo: $godoGoodsNo) {
    id
  }
}
    `;

/**
 * __useGetMyReviewsByGodoGoodsNoQuery__
 *
 * To run a query within a React component, call `useGetMyReviewsByGodoGoodsNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReviewsByGodoGoodsNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReviewsByGodoGoodsNoQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *   },
 * });
 */
export function useGetMyReviewsByGodoGoodsNoQuery(baseOptions: Apollo.QueryHookOptions<GetMyReviewsByGodoGoodsNoQuery, GetMyReviewsByGodoGoodsNoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyReviewsByGodoGoodsNoQuery, GetMyReviewsByGodoGoodsNoQueryVariables>(GetMyReviewsByGodoGoodsNoDocument, options);
}
export function useGetMyReviewsByGodoGoodsNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyReviewsByGodoGoodsNoQuery, GetMyReviewsByGodoGoodsNoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyReviewsByGodoGoodsNoQuery, GetMyReviewsByGodoGoodsNoQueryVariables>(GetMyReviewsByGodoGoodsNoDocument, options);
}
export type GetMyReviewsByGodoGoodsNoQueryHookResult = ReturnType<typeof useGetMyReviewsByGodoGoodsNoQuery>;
export type GetMyReviewsByGodoGoodsNoLazyQueryHookResult = ReturnType<typeof useGetMyReviewsByGodoGoodsNoLazyQuery>;
export type GetMyReviewsByGodoGoodsNoQueryResult = Apollo.QueryResult<GetMyReviewsByGodoGoodsNoQuery, GetMyReviewsByGodoGoodsNoQueryVariables>;
export const GetMyReviewsDocument = gql`
    query GetMyReviews {
  getMyReviews {
    godoOrderNo
    godoGoodsNo
    created
  }
}
    `;

/**
 * __useGetMyReviewsQuery__
 *
 * To run a query within a React component, call `useGetMyReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyReviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyReviewsQuery, GetMyReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyReviewsQuery, GetMyReviewsQueryVariables>(GetMyReviewsDocument, options);
}
export function useGetMyReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyReviewsQuery, GetMyReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyReviewsQuery, GetMyReviewsQueryVariables>(GetMyReviewsDocument, options);
}
export type GetMyReviewsQueryHookResult = ReturnType<typeof useGetMyReviewsQuery>;
export type GetMyReviewsLazyQueryHookResult = ReturnType<typeof useGetMyReviewsLazyQuery>;
export type GetMyReviewsQueryResult = Apollo.QueryResult<GetMyReviewsQuery, GetMyReviewsQueryVariables>;
export const GetMypageUserDataDocument = gql`
    query GetMypageUserData {
  hasNewPoint
  hasNewCoupon
  inquiryCount
}
    `;

/**
 * __useGetMypageUserDataQuery__
 *
 * To run a query within a React component, call `useGetMypageUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMypageUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMypageUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMypageUserDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>(GetMypageUserDataDocument, options);
}
export function useGetMypageUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>(GetMypageUserDataDocument, options);
}
export type GetMypageUserDataQueryHookResult = ReturnType<typeof useGetMypageUserDataQuery>;
export type GetMypageUserDataLazyQueryHookResult = ReturnType<typeof useGetMypageUserDataLazyQuery>;
export type GetMypageUserDataQueryResult = Apollo.QueryResult<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>;
export const GetOrderNoWithoutReviewDocument = gql`
    query GetOrderNoWithoutReview($godoGoodsNo: String!) {
  getOrderNoWithoutReview(godoGoodsNo: $godoGoodsNo)
}
    `;

/**
 * __useGetOrderNoWithoutReviewQuery__
 *
 * To run a query within a React component, call `useGetOrderNoWithoutReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderNoWithoutReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderNoWithoutReviewQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *   },
 * });
 */
export function useGetOrderNoWithoutReviewQuery(baseOptions: Apollo.QueryHookOptions<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>(GetOrderNoWithoutReviewDocument, options);
}
export function useGetOrderNoWithoutReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>(GetOrderNoWithoutReviewDocument, options);
}
export type GetOrderNoWithoutReviewQueryHookResult = ReturnType<typeof useGetOrderNoWithoutReviewQuery>;
export type GetOrderNoWithoutReviewLazyQueryHookResult = ReturnType<typeof useGetOrderNoWithoutReviewLazyQuery>;
export type GetOrderNoWithoutReviewQueryResult = Apollo.QueryResult<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>;
export const GetOrderViewV2Document = gql`
    query GetOrderViewV2($lang: String!, $orderNo: Float!) {
  orderViewV2(lang: $lang, orderNo: $orderNo) {
    receipt {
      cashFl
      limitDateFl
      particularFl
      periodDay
      periodFl
      taxFl
      taxInvoiceLimitDate
    }
    user {
      cellPhone
      email
      name
    }
    license {
      orderNo
      type
      names {
        name
      }
      projects {
        name
        goods {
          goodsNo
        }
      }
    }
    orderProducts {
      goodsNo
      price
      salePrice
      status
      statusStr
      imageUrl
      product {
        title
        brand {
          id
          name
          code
        }
        extensions
      }
    }
    order {
      id
      status
      payment {
        payMethod
        receiptUrl
      }
    }
    orderDate
    settleReceipt
    settleName
    settleMethod
    status
    bank
    bankAccount
    bankSender
    bankHolder
    bankHolder
    pgFailReason
    settlePrice
    totalGoodsDcPrice
    totalCouponDeliveryDcPrice
    totalGoodsPrice
    totalCouponGoodsDcPrice
    totalCouponOrderDcPrice
    useDeposit
    useMileage
    receiptFl
    pgSettleCd
    pgSettleNm
    settleKind
    cash
    tax
  }
}
    `;

/**
 * __useGetOrderViewV2Query__
 *
 * To run a query within a React component, call `useGetOrderViewV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderViewV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderViewV2Query({
 *   variables: {
 *      lang: // value for 'lang'
 *      orderNo: // value for 'orderNo'
 *   },
 * });
 */
export function useGetOrderViewV2Query(baseOptions: Apollo.QueryHookOptions<GetOrderViewV2Query, GetOrderViewV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderViewV2Query, GetOrderViewV2QueryVariables>(GetOrderViewV2Document, options);
}
export function useGetOrderViewV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderViewV2Query, GetOrderViewV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderViewV2Query, GetOrderViewV2QueryVariables>(GetOrderViewV2Document, options);
}
export type GetOrderViewV2QueryHookResult = ReturnType<typeof useGetOrderViewV2Query>;
export type GetOrderViewV2LazyQueryHookResult = ReturnType<typeof useGetOrderViewV2LazyQuery>;
export type GetOrderViewV2QueryResult = Apollo.QueryResult<GetOrderViewV2Query, GetOrderViewV2QueryVariables>;
export const GetOtherProductListDocument = gql`
    query GetOtherProductList($godoGoodsNo: Int!, $lang: String!, $isCount: Boolean!) {
  otherProductList(godoGoodsNo: $godoGoodsNo, lang: $lang, isCount: $isCount) {
    cateCd
    items {
      godoGoodsNo
      realPrice
      price
      imageUrl
      title
      brand
      brandCd
    }
    count
  }
}
    `;

/**
 * __useGetOtherProductListQuery__
 *
 * To run a query within a React component, call `useGetOtherProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherProductListQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *      lang: // value for 'lang'
 *      isCount: // value for 'isCount'
 *   },
 * });
 */
export function useGetOtherProductListQuery(baseOptions: Apollo.QueryHookOptions<GetOtherProductListQuery, GetOtherProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOtherProductListQuery, GetOtherProductListQueryVariables>(GetOtherProductListDocument, options);
}
export function useGetOtherProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherProductListQuery, GetOtherProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOtherProductListQuery, GetOtherProductListQueryVariables>(GetOtherProductListDocument, options);
}
export type GetOtherProductListQueryHookResult = ReturnType<typeof useGetOtherProductListQuery>;
export type GetOtherProductListLazyQueryHookResult = ReturnType<typeof useGetOtherProductListLazyQuery>;
export type GetOtherProductListQueryResult = Apollo.QueryResult<GetOtherProductListQuery, GetOtherProductListQueryVariables>;
export const PhotoReviewDocument = gql`
    query PhotoReview($id: Int, $isBestReview: Boolean, $languageCode: LanguageCodeEnum!, $godoCateCd: Int) {
  getPhotoReview(id: $id, isBestReview: $isBestReview, godoCateCd: $godoCateCd) {
    nextCursor
    prevCursor
    nth
    node {
      imageUrl
      rating
      product(languageCode: $languageCode) {
        id
        title
        brand {
          code
          name
        }
      }
      godoUserId
      reviewContentsItems {
        isOriginal
        languageCode
        title
        contents
      }
      created
    }
  }
}
    `;

/**
 * __usePhotoReviewQuery__
 *
 * To run a query within a React component, call `usePhotoReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isBestReview: // value for 'isBestReview'
 *      languageCode: // value for 'languageCode'
 *      godoCateCd: // value for 'godoCateCd'
 *   },
 * });
 */
export function usePhotoReviewQuery(baseOptions: Apollo.QueryHookOptions<PhotoReviewQuery, PhotoReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhotoReviewQuery, PhotoReviewQueryVariables>(PhotoReviewDocument, options);
}
export function usePhotoReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhotoReviewQuery, PhotoReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhotoReviewQuery, PhotoReviewQueryVariables>(PhotoReviewDocument, options);
}
export type PhotoReviewQueryHookResult = ReturnType<typeof usePhotoReviewQuery>;
export type PhotoReviewLazyQueryHookResult = ReturnType<typeof usePhotoReviewLazyQuery>;
export type PhotoReviewQueryResult = Apollo.QueryResult<PhotoReviewQuery, PhotoReviewQueryVariables>;
export const GetPhotoReviewTotalCountDocument = gql`
    query GetPhotoReviewTotalCount($isBestReview: Boolean, $godoCateCd: Int) {
  getPhotoReviewTotalCount(isBestReview: $isBestReview, godoCateCd: $godoCateCd)
}
    `;

/**
 * __useGetPhotoReviewTotalCountQuery__
 *
 * To run a query within a React component, call `useGetPhotoReviewTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotoReviewTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotoReviewTotalCountQuery({
 *   variables: {
 *      isBestReview: // value for 'isBestReview'
 *      godoCateCd: // value for 'godoCateCd'
 *   },
 * });
 */
export function useGetPhotoReviewTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>(GetPhotoReviewTotalCountDocument, options);
}
export function useGetPhotoReviewTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>(GetPhotoReviewTotalCountDocument, options);
}
export type GetPhotoReviewTotalCountQueryHookResult = ReturnType<typeof useGetPhotoReviewTotalCountQuery>;
export type GetPhotoReviewTotalCountLazyQueryHookResult = ReturnType<typeof useGetPhotoReviewTotalCountLazyQuery>;
export type GetPhotoReviewTotalCountQueryResult = Apollo.QueryResult<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>;
export const PhotoReviewsDocument = gql`
    query PhotoReviews($isBestReview: Boolean, $take: Int, $cursor: Int, $godoCateCd: Int, $languageCode: String) {
  getPhotoReviews(
    isBestReview: $isBestReview
    take: $take
    cursor: $cursor
    godoCateCd: $godoCateCd
  ) {
    nodes {
      id
      godoUserId
      rating
      reviewContentsItems(languageCode: $languageCode) {
        title
        contents
        isOriginal
        languageCode
      }
      imageUrl
      created
    }
    edge {
      cursor
      node {
        id
      }
    }
  }
}
    `;

/**
 * __usePhotoReviewsQuery__
 *
 * To run a query within a React component, call `usePhotoReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoReviewsQuery({
 *   variables: {
 *      isBestReview: // value for 'isBestReview'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      godoCateCd: // value for 'godoCateCd'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function usePhotoReviewsQuery(baseOptions?: Apollo.QueryHookOptions<PhotoReviewsQuery, PhotoReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhotoReviewsQuery, PhotoReviewsQueryVariables>(PhotoReviewsDocument, options);
}
export function usePhotoReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhotoReviewsQuery, PhotoReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhotoReviewsQuery, PhotoReviewsQueryVariables>(PhotoReviewsDocument, options);
}
export type PhotoReviewsQueryHookResult = ReturnType<typeof usePhotoReviewsQuery>;
export type PhotoReviewsLazyQueryHookResult = ReturnType<typeof usePhotoReviewsLazyQuery>;
export type PhotoReviewsQueryResult = Apollo.QueryResult<PhotoReviewsQuery, PhotoReviewsQueryVariables>;
export const GetPopupsHereDocument = gql`
    query GetPopupsHere($url: String!, $origin: String!) {
  getPopupsHere(url: $url, origin: $origin) {
    id
    title
    image {
      azureStoragePath
    }
    text
    subText
    buttonText
    connectedUrl
  }
}
    `;

/**
 * __useGetPopupsHereQuery__
 *
 * To run a query within a React component, call `useGetPopupsHereQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopupsHereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopupsHereQuery({
 *   variables: {
 *      url: // value for 'url'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useGetPopupsHereQuery(baseOptions: Apollo.QueryHookOptions<GetPopupsHereQuery, GetPopupsHereQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPopupsHereQuery, GetPopupsHereQueryVariables>(GetPopupsHereDocument, options);
}
export function useGetPopupsHereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopupsHereQuery, GetPopupsHereQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPopupsHereQuery, GetPopupsHereQueryVariables>(GetPopupsHereDocument, options);
}
export type GetPopupsHereQueryHookResult = ReturnType<typeof useGetPopupsHereQuery>;
export type GetPopupsHereLazyQueryHookResult = ReturnType<typeof useGetPopupsHereLazyQuery>;
export type GetPopupsHereQueryResult = Apollo.QueryResult<GetPopupsHereQuery, GetPopupsHereQueryVariables>;
export const GetReceivePointByReviewDocument = gql`
    query GetReceivePointByReview($goodsNo: Float!, $languageCode: String!, $isPhotoReview: Boolean!) {
  getReceivePointByReview(
    goodsNo: $goodsNo
    languageCode: $languageCode
    isPhotoReview: $isPhotoReview
  )
}
    `;

/**
 * __useGetReceivePointByReviewQuery__
 *
 * To run a query within a React component, call `useGetReceivePointByReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivePointByReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivePointByReviewQuery({
 *   variables: {
 *      goodsNo: // value for 'goodsNo'
 *      languageCode: // value for 'languageCode'
 *      isPhotoReview: // value for 'isPhotoReview'
 *   },
 * });
 */
export function useGetReceivePointByReviewQuery(baseOptions: Apollo.QueryHookOptions<GetReceivePointByReviewQuery, GetReceivePointByReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReceivePointByReviewQuery, GetReceivePointByReviewQueryVariables>(GetReceivePointByReviewDocument, options);
}
export function useGetReceivePointByReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivePointByReviewQuery, GetReceivePointByReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReceivePointByReviewQuery, GetReceivePointByReviewQueryVariables>(GetReceivePointByReviewDocument, options);
}
export type GetReceivePointByReviewQueryHookResult = ReturnType<typeof useGetReceivePointByReviewQuery>;
export type GetReceivePointByReviewLazyQueryHookResult = ReturnType<typeof useGetReceivePointByReviewLazyQuery>;
export type GetReceivePointByReviewQueryResult = Apollo.QueryResult<GetReceivePointByReviewQuery, GetReceivePointByReviewQueryVariables>;
export const GetRecentOrdersV2Document = gql`
    query GetRecentOrdersV2($langCode: String!) {
  recentOrdersV2 {
    orderNo
    orderedAt
    orderedProducts {
      sno
      title
      godoGoodsNo
      price
      status
      statusString
      hasWrittenReview
      reviewSno
      reviewId
      product(langCode: $langCode) {
        title
        price
        salePrice
        extensions
        brand {
          code
          name
        }
        mainImageUrl
        subImageUrl
      }
    }
  }
}
    `;

/**
 * __useGetRecentOrdersV2Query__
 *
 * To run a query within a React component, call `useGetRecentOrdersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentOrdersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentOrdersV2Query({
 *   variables: {
 *      langCode: // value for 'langCode'
 *   },
 * });
 */
export function useGetRecentOrdersV2Query(baseOptions: Apollo.QueryHookOptions<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>(GetRecentOrdersV2Document, options);
}
export function useGetRecentOrdersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>(GetRecentOrdersV2Document, options);
}
export type GetRecentOrdersV2QueryHookResult = ReturnType<typeof useGetRecentOrdersV2Query>;
export type GetRecentOrdersV2LazyQueryHookResult = ReturnType<typeof useGetRecentOrdersV2LazyQuery>;
export type GetRecentOrdersV2QueryResult = Apollo.QueryResult<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>;
export const GetSimilarProductListDocument = gql`
    query GetSimilarProductList($godoGoodsNo: Int!, $lang: String!, $isCount: Boolean!) {
  similarProductList(godoGoodsNo: $godoGoodsNo, lang: $lang, isCount: $isCount) {
    cateCd
    items {
      godoGoodsNo
      title
      realPrice
      price
      imageUrl
      brand
      brandCd
    }
    count
  }
}
    `;

/**
 * __useGetSimilarProductListQuery__
 *
 * To run a query within a React component, call `useGetSimilarProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarProductListQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *      lang: // value for 'lang'
 *      isCount: // value for 'isCount'
 *   },
 * });
 */
export function useGetSimilarProductListQuery(baseOptions: Apollo.QueryHookOptions<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>(GetSimilarProductListDocument, options);
}
export function useGetSimilarProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>(GetSimilarProductListDocument, options);
}
export type GetSimilarProductListQueryHookResult = ReturnType<typeof useGetSimilarProductListQuery>;
export type GetSimilarProductListLazyQueryHookResult = ReturnType<typeof useGetSimilarProductListLazyQuery>;
export type GetSimilarProductListQueryResult = Apollo.QueryResult<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>;
export const GetTogetherProductListDocument = gql`
    query GetTogetherProductList($lang: String!, $godoGoodsNo: Int!, $isCount: Boolean!, $isFresh: Boolean) {
  togetherProductListRecommendation(
    lang: $lang
    godoGoodsNo: $godoGoodsNo
    isCount: $isCount
    isFresh: $isFresh
  ) {
    items {
      godoGoodsNo
      title
      realPrice
      price
      imageUrl
      brand
      brandCd
    }
    count
  }
}
    `;

/**
 * __useGetTogetherProductListQuery__
 *
 * To run a query within a React component, call `useGetTogetherProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTogetherProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTogetherProductListQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *      isCount: // value for 'isCount'
 *      isFresh: // value for 'isFresh'
 *   },
 * });
 */
export function useGetTogetherProductListQuery(baseOptions: Apollo.QueryHookOptions<GetTogetherProductListQuery, GetTogetherProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTogetherProductListQuery, GetTogetherProductListQueryVariables>(GetTogetherProductListDocument, options);
}
export function useGetTogetherProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTogetherProductListQuery, GetTogetherProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTogetherProductListQuery, GetTogetherProductListQueryVariables>(GetTogetherProductListDocument, options);
}
export type GetTogetherProductListQueryHookResult = ReturnType<typeof useGetTogetherProductListQuery>;
export type GetTogetherProductListLazyQueryHookResult = ReturnType<typeof useGetTogetherProductListLazyQuery>;
export type GetTogetherProductListQueryResult = Apollo.QueryResult<GetTogetherProductListQuery, GetTogetherProductListQueryVariables>;
export const GetTransGodoCategoryChildItemsDocument = gql`
    query GetTransGodoCategoryChildItems($lang: String!, $cateCds: [String!]!, $type: String) {
  getTransGodoCategoryChildItems(lang: $lang, cateCds: $cateCds, type: $type) {
    name
    code
    isLink
    isVisible
  }
}
    `;

/**
 * __useGetTransGodoCategoryChildItemsQuery__
 *
 * To run a query within a React component, call `useGetTransGodoCategoryChildItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransGodoCategoryChildItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransGodoCategoryChildItemsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      cateCds: // value for 'cateCds'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetTransGodoCategoryChildItemsQuery(baseOptions: Apollo.QueryHookOptions<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>(GetTransGodoCategoryChildItemsDocument, options);
}
export function useGetTransGodoCategoryChildItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>(GetTransGodoCategoryChildItemsDocument, options);
}
export type GetTransGodoCategoryChildItemsQueryHookResult = ReturnType<typeof useGetTransGodoCategoryChildItemsQuery>;
export type GetTransGodoCategoryChildItemsLazyQueryHookResult = ReturnType<typeof useGetTransGodoCategoryChildItemsLazyQuery>;
export type GetTransGodoCategoryChildItemsQueryResult = Apollo.QueryResult<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>;
export const GetUserApprovalDtDocument = gql`
    query GetUserApprovalDt {
  getUserApprovalDt
}
    `;

/**
 * __useGetUserApprovalDtQuery__
 *
 * To run a query within a React component, call `useGetUserApprovalDtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserApprovalDtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserApprovalDtQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserApprovalDtQuery(baseOptions?: Apollo.QueryHookOptions<GetUserApprovalDtQuery, GetUserApprovalDtQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserApprovalDtQuery, GetUserApprovalDtQueryVariables>(GetUserApprovalDtDocument, options);
}
export function useGetUserApprovalDtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserApprovalDtQuery, GetUserApprovalDtQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserApprovalDtQuery, GetUserApprovalDtQueryVariables>(GetUserApprovalDtDocument, options);
}
export type GetUserApprovalDtQueryHookResult = ReturnType<typeof useGetUserApprovalDtQuery>;
export type GetUserApprovalDtLazyQueryHookResult = ReturnType<typeof useGetUserApprovalDtLazyQuery>;
export type GetUserApprovalDtQueryResult = Apollo.QueryResult<GetUserApprovalDtQuery, GetUserApprovalDtQueryVariables>;
export const IsCouponNotificationDocument = gql`
    query IsCouponNotification {
  isCouponNotification
}
    `;

/**
 * __useIsCouponNotificationQuery__
 *
 * To run a query within a React component, call `useIsCouponNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCouponNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCouponNotificationQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsCouponNotificationQuery(baseOptions?: Apollo.QueryHookOptions<IsCouponNotificationQuery, IsCouponNotificationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsCouponNotificationQuery, IsCouponNotificationQueryVariables>(IsCouponNotificationDocument, options);
}
export function useIsCouponNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsCouponNotificationQuery, IsCouponNotificationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsCouponNotificationQuery, IsCouponNotificationQueryVariables>(IsCouponNotificationDocument, options);
}
export type IsCouponNotificationQueryHookResult = ReturnType<typeof useIsCouponNotificationQuery>;
export type IsCouponNotificationLazyQueryHookResult = ReturnType<typeof useIsCouponNotificationLazyQuery>;
export type IsCouponNotificationQueryResult = Apollo.QueryResult<IsCouponNotificationQuery, IsCouponNotificationQueryVariables>;
export const MainProductListV2Document = gql`
    query MainProductListV2($sno: Int!, $lang: String!) {
  mainProductListV2(sno: $sno, lang: $lang) {
    sno
    title
    source
    productList
  }
}
    `;

/**
 * __useMainProductListV2Query__
 *
 * To run a query within a React component, call `useMainProductListV2Query` and pass it any options that fit your needs.
 * When your component renders, `useMainProductListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainProductListV2Query({
 *   variables: {
 *      sno: // value for 'sno'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useMainProductListV2Query(baseOptions: Apollo.QueryHookOptions<MainProductListV2Query, MainProductListV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MainProductListV2Query, MainProductListV2QueryVariables>(MainProductListV2Document, options);
}
export function useMainProductListV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainProductListV2Query, MainProductListV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MainProductListV2Query, MainProductListV2QueryVariables>(MainProductListV2Document, options);
}
export type MainProductListV2QueryHookResult = ReturnType<typeof useMainProductListV2Query>;
export type MainProductListV2LazyQueryHookResult = ReturnType<typeof useMainProductListV2LazyQuery>;
export type MainProductListV2QueryResult = Apollo.QueryResult<MainProductListV2Query, MainProductListV2QueryVariables>;
export const FundingProductNosDocument = gql`
    query FundingProductNos {
  fundingProductNos
}
    `;

/**
 * __useFundingProductNosQuery__
 *
 * To run a query within a React component, call `useFundingProductNosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingProductNosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingProductNosQuery({
 *   variables: {
 *   },
 * });
 */
export function useFundingProductNosQuery(baseOptions?: Apollo.QueryHookOptions<FundingProductNosQuery, FundingProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FundingProductNosQuery, FundingProductNosQueryVariables>(FundingProductNosDocument, options);
}
export function useFundingProductNosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundingProductNosQuery, FundingProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FundingProductNosQuery, FundingProductNosQueryVariables>(FundingProductNosDocument, options);
}
export type FundingProductNosQueryHookResult = ReturnType<typeof useFundingProductNosQuery>;
export type FundingProductNosLazyQueryHookResult = ReturnType<typeof useFundingProductNosLazyQuery>;
export type FundingProductNosQueryResult = Apollo.QueryResult<FundingProductNosQuery, FundingProductNosQueryVariables>;
export const ProductCardsDocument = gql`
    query ProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
  }
}
    ${ProductCardsFragmentDoc}`;

/**
 * __useProductCardsQuery__
 *
 * To run a query within a React component, call `useProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsQuery, ProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsQuery, ProductCardsQueryVariables>(ProductCardsDocument, options);
}
export function useProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsQuery, ProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsQuery, ProductCardsQueryVariables>(ProductCardsDocument, options);
}
export type ProductCardsQueryHookResult = ReturnType<typeof useProductCardsQuery>;
export type ProductCardsLazyQueryHookResult = ReturnType<typeof useProductCardsLazyQuery>;
export type ProductCardsQueryResult = Apollo.QueryResult<ProductCardsQuery, ProductCardsQueryVariables>;
export const ProductCardsWithBrandDocument = gql`
    query ProductCardsWithBrand($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    brand {
      ...brand
    }
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}`;

/**
 * __useProductCardsWithBrandQuery__
 *
 * To run a query within a React component, call `useProductCardsWithBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsWithBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsWithBrandQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsWithBrandQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>(ProductCardsWithBrandDocument, options);
}
export function useProductCardsWithBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>(ProductCardsWithBrandDocument, options);
}
export type ProductCardsWithBrandQueryHookResult = ReturnType<typeof useProductCardsWithBrandQuery>;
export type ProductCardsWithBrandLazyQueryHookResult = ReturnType<typeof useProductCardsWithBrandLazyQuery>;
export type ProductCardsWithBrandQueryResult = Apollo.QueryResult<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>;
export const ProductCardsWithCategoriesDocument = gql`
    query ProductCardsWithCategories($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    categories {
      ...categories
    }
  }
}
    ${ProductCardsFragmentDoc}
${CategoriesFragmentDoc}`;

/**
 * __useProductCardsWithCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCardsWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsWithCategoriesQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsWithCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>(ProductCardsWithCategoriesDocument, options);
}
export function useProductCardsWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>(ProductCardsWithCategoriesDocument, options);
}
export type ProductCardsWithCategoriesQueryHookResult = ReturnType<typeof useProductCardsWithCategoriesQuery>;
export type ProductCardsWithCategoriesLazyQueryHookResult = ReturnType<typeof useProductCardsWithCategoriesLazyQuery>;
export type ProductCardsWithCategoriesQueryResult = Apollo.QueryResult<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>;
export const ProductCardsWithBrandAndCategoriesDocument = gql`
    query ProductCardsWithBrandAndCategories($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    brand {
      ...brand
    }
    categories {
      ...categories
    }
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}
${CategoriesFragmentDoc}`;

/**
 * __useProductCardsWithBrandAndCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCardsWithBrandAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsWithBrandAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsWithBrandAndCategoriesQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsWithBrandAndCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>(ProductCardsWithBrandAndCategoriesDocument, options);
}
export function useProductCardsWithBrandAndCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>(ProductCardsWithBrandAndCategoriesDocument, options);
}
export type ProductCardsWithBrandAndCategoriesQueryHookResult = ReturnType<typeof useProductCardsWithBrandAndCategoriesQuery>;
export type ProductCardsWithBrandAndCategoriesLazyQueryHookResult = ReturnType<typeof useProductCardsWithBrandAndCategoriesLazyQuery>;
export type ProductCardsWithBrandAndCategoriesQueryResult = Apollo.QueryResult<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>;
export const ProductCardsAllDocument = gql`
    query ProductCardsAll($languageCode: LanguageCodeEnum!, $productNos: [Int!], $isExperimental: Boolean) {
  productCards(
    languageCode: $languageCode
    productNos: $productNos
    isExperimental: $isExperimental
  ) {
    ...productCards
    content
    brand {
      ...brand
    }
    applications {
      name
    }
    linkedProducts {
      ...linkedProducts
    }
    categories {
      ...categories
    }
    modelConfigs {
      ...modelConfigs
    }
    banners {
      ...banners
    }
    packages {
      ...packages
    }
    funding {
      ...funding
    }
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}
${LinkedProductsFragmentDoc}
${CategoriesFragmentDoc}
${ModelConfigsFragmentDoc}
${BannersFragmentDoc}
${PackagesFragmentDoc}
${FundingFragmentDoc}`;

/**
 * __useProductCardsAllQuery__
 *
 * To run a query within a React component, call `useProductCardsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsAllQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *      isExperimental: // value for 'isExperimental'
 *   },
 * });
 */
export function useProductCardsAllQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsAllQuery, ProductCardsAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsAllQuery, ProductCardsAllQueryVariables>(ProductCardsAllDocument, options);
}
export function useProductCardsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsAllQuery, ProductCardsAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsAllQuery, ProductCardsAllQueryVariables>(ProductCardsAllDocument, options);
}
export type ProductCardsAllQueryHookResult = ReturnType<typeof useProductCardsAllQuery>;
export type ProductCardsAllLazyQueryHookResult = ReturnType<typeof useProductCardsAllLazyQuery>;
export type ProductCardsAllQueryResult = Apollo.QueryResult<ProductCardsAllQuery, ProductCardsAllQueryVariables>;
export const ProductCardsGa4EcommerceDocument = gql`
    query ProductCardsGA4Ecommerce($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    id
    title
    price
    salePrice
    brand {
      ...brand
    }
  }
}
    ${BrandFragmentDoc}`;

/**
 * __useProductCardsGa4EcommerceQuery__
 *
 * To run a query within a React component, call `useProductCardsGa4EcommerceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsGa4EcommerceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsGa4EcommerceQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsGa4EcommerceQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>(ProductCardsGa4EcommerceDocument, options);
}
export function useProductCardsGa4EcommerceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>(ProductCardsGa4EcommerceDocument, options);
}
export type ProductCardsGa4EcommerceQueryHookResult = ReturnType<typeof useProductCardsGa4EcommerceQuery>;
export type ProductCardsGa4EcommerceLazyQueryHookResult = ReturnType<typeof useProductCardsGa4EcommerceLazyQuery>;
export type ProductCardsGa4EcommerceQueryResult = Apollo.QueryResult<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>;
export const DetailViewProductCardsDocument = gql`
    query DetailViewProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    detailImageUrl
    brand {
      ...brand
    }
    applications {
      name
    }
    linkedProducts {
      ...linkedProducts
    }
    packages {
      ...packages
    }
    funding {
      ...funding
    }
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}
${LinkedProductsFragmentDoc}
${PackagesFragmentDoc}
${FundingFragmentDoc}`;

/**
 * __useDetailViewProductCardsQuery__
 *
 * To run a query within a React component, call `useDetailViewProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailViewProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailViewProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useDetailViewProductCardsQuery(baseOptions: Apollo.QueryHookOptions<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>(DetailViewProductCardsDocument, options);
}
export function useDetailViewProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>(DetailViewProductCardsDocument, options);
}
export type DetailViewProductCardsQueryHookResult = ReturnType<typeof useDetailViewProductCardsQuery>;
export type DetailViewProductCardsLazyQueryHookResult = ReturnType<typeof useDetailViewProductCardsLazyQuery>;
export type DetailViewProductCardsQueryResult = Apollo.QueryResult<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>;
export const OrderProductCardsDocument = gql`
    query OrderProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    brand {
      ...brand
    }
    hasOrdered
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}`;

/**
 * __useOrderProductCardsQuery__
 *
 * To run a query within a React component, call `useOrderProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useOrderProductCardsQuery(baseOptions: Apollo.QueryHookOptions<OrderProductCardsQuery, OrderProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderProductCardsQuery, OrderProductCardsQueryVariables>(OrderProductCardsDocument, options);
}
export function useOrderProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderProductCardsQuery, OrderProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderProductCardsQuery, OrderProductCardsQueryVariables>(OrderProductCardsDocument, options);
}
export type OrderProductCardsQueryHookResult = ReturnType<typeof useOrderProductCardsQuery>;
export type OrderProductCardsLazyQueryHookResult = ReturnType<typeof useOrderProductCardsLazyQuery>;
export type OrderProductCardsQueryResult = Apollo.QueryResult<OrderProductCardsQuery, OrderProductCardsQueryVariables>;
export const PromotionProductCardsDocument = gql`
    query PromotionProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    brand {
      ...brand
    }
    promotions {
      ...promotions
      badges {
        ...promotionsBadge
      }
    }
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}
${PromotionsFragmentDoc}
${PromotionsBadgeFragmentDoc}`;

/**
 * __usePromotionProductCardsQuery__
 *
 * To run a query within a React component, call `usePromotionProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function usePromotionProductCardsQuery(baseOptions: Apollo.QueryHookOptions<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>(PromotionProductCardsDocument, options);
}
export function usePromotionProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>(PromotionProductCardsDocument, options);
}
export type PromotionProductCardsQueryHookResult = ReturnType<typeof usePromotionProductCardsQuery>;
export type PromotionProductCardsLazyQueryHookResult = ReturnType<typeof usePromotionProductCardsLazyQuery>;
export type PromotionProductCardsQueryResult = Apollo.QueryResult<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>;
export const ProductCardsSummaryDocument = gql`
    query ProductCardsSummary($sort: ProductListSort, $categoryCode: String, $brandCode: String, $isNew: Boolean) {
  productCardsSummary(
    sort: $sort
    categoryCode: $categoryCode
    brandCode: $brandCode
    isNew: $isNew
  ) {
    sort
    products {
      id
      extensions
      salePrice
      orderCount
      applications {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProductCardsSummaryQuery__
 *
 * To run a query within a React component, call `useProductCardsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsSummaryQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      categoryCode: // value for 'categoryCode'
 *      brandCode: // value for 'brandCode'
 *      isNew: // value for 'isNew'
 *   },
 * });
 */
export function useProductCardsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>(ProductCardsSummaryDocument, options);
}
export function useProductCardsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>(ProductCardsSummaryDocument, options);
}
export type ProductCardsSummaryQueryHookResult = ReturnType<typeof useProductCardsSummaryQuery>;
export type ProductCardsSummaryLazyQueryHookResult = ReturnType<typeof useProductCardsSummaryLazyQuery>;
export type ProductCardsSummaryQueryResult = Apollo.QueryResult<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>;
export const OngoingCosmicPromotionProductNosDocument = gql`
    query OngoingCosmicPromotionProductNos($languageCode: LanguageCodeEnum!) {
  ongoingCosmicPromotionProductNos(languageCode: $languageCode)
}
    `;

/**
 * __useOngoingCosmicPromotionProductNosQuery__
 *
 * To run a query within a React component, call `useOngoingCosmicPromotionProductNosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingCosmicPromotionProductNosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingCosmicPromotionProductNosQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useOngoingCosmicPromotionProductNosQuery(baseOptions: Apollo.QueryHookOptions<OngoingCosmicPromotionProductNosQuery, OngoingCosmicPromotionProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OngoingCosmicPromotionProductNosQuery, OngoingCosmicPromotionProductNosQueryVariables>(OngoingCosmicPromotionProductNosDocument, options);
}
export function useOngoingCosmicPromotionProductNosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OngoingCosmicPromotionProductNosQuery, OngoingCosmicPromotionProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OngoingCosmicPromotionProductNosQuery, OngoingCosmicPromotionProductNosQueryVariables>(OngoingCosmicPromotionProductNosDocument, options);
}
export type OngoingCosmicPromotionProductNosQueryHookResult = ReturnType<typeof useOngoingCosmicPromotionProductNosQuery>;
export type OngoingCosmicPromotionProductNosLazyQueryHookResult = ReturnType<typeof useOngoingCosmicPromotionProductNosLazyQuery>;
export type OngoingCosmicPromotionProductNosQueryResult = Apollo.QueryResult<OngoingCosmicPromotionProductNosQuery, OngoingCosmicPromotionProductNosQueryVariables>;
export const OngoingCometPromotionProductNosDocument = gql`
    query OngoingCometPromotionProductNos($languageCode: LanguageCodeEnum!) {
  ongoingCometPromotionProductNos(languageCode: $languageCode)
}
    `;

/**
 * __useOngoingCometPromotionProductNosQuery__
 *
 * To run a query within a React component, call `useOngoingCometPromotionProductNosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingCometPromotionProductNosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingCometPromotionProductNosQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useOngoingCometPromotionProductNosQuery(baseOptions: Apollo.QueryHookOptions<OngoingCometPromotionProductNosQuery, OngoingCometPromotionProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OngoingCometPromotionProductNosQuery, OngoingCometPromotionProductNosQueryVariables>(OngoingCometPromotionProductNosDocument, options);
}
export function useOngoingCometPromotionProductNosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OngoingCometPromotionProductNosQuery, OngoingCometPromotionProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OngoingCometPromotionProductNosQuery, OngoingCometPromotionProductNosQueryVariables>(OngoingCometPromotionProductNosDocument, options);
}
export type OngoingCometPromotionProductNosQueryHookResult = ReturnType<typeof useOngoingCometPromotionProductNosQuery>;
export type OngoingCometPromotionProductNosLazyQueryHookResult = ReturnType<typeof useOngoingCometPromotionProductNosLazyQuery>;
export type OngoingCometPromotionProductNosQueryResult = Apollo.QueryResult<OngoingCometPromotionProductNosQuery, OngoingCometPromotionProductNosQueryVariables>;
export const RegisterSnsMemberDocument = gql`
    mutation RegisterSnsMember($input: AconSnsMemberInput!) {
  registerSnsMember(input: $input)
}
    `;
export type RegisterSnsMemberMutationFn = Apollo.MutationFunction<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>;

/**
 * __useRegisterSnsMemberMutation__
 *
 * To run a mutation, you first call `useRegisterSnsMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSnsMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSnsMemberMutation, { data, loading, error }] = useRegisterSnsMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterSnsMemberMutation(baseOptions?: Apollo.MutationHookOptions<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>(RegisterSnsMemberDocument, options);
}
export type RegisterSnsMemberMutationHookResult = ReturnType<typeof useRegisterSnsMemberMutation>;
export type RegisterSnsMemberMutationResult = Apollo.MutationResult<RegisterSnsMemberMutation>;
export type RegisterSnsMemberMutationOptions = Apollo.BaseMutationOptions<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>;
export const ReviewCountByGodoGoodsNoDocument = gql`
    query ReviewCountByGodoGoodsNo($godoGoodsNo: String!) {
  reviewCountByGodoGoodsNo(godoGoodsNo: $godoGoodsNo)
}
    `;

/**
 * __useReviewCountByGodoGoodsNoQuery__
 *
 * To run a query within a React component, call `useReviewCountByGodoGoodsNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewCountByGodoGoodsNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewCountByGodoGoodsNoQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *   },
 * });
 */
export function useReviewCountByGodoGoodsNoQuery(baseOptions: Apollo.QueryHookOptions<ReviewCountByGodoGoodsNoQuery, ReviewCountByGodoGoodsNoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewCountByGodoGoodsNoQuery, ReviewCountByGodoGoodsNoQueryVariables>(ReviewCountByGodoGoodsNoDocument, options);
}
export function useReviewCountByGodoGoodsNoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewCountByGodoGoodsNoQuery, ReviewCountByGodoGoodsNoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewCountByGodoGoodsNoQuery, ReviewCountByGodoGoodsNoQueryVariables>(ReviewCountByGodoGoodsNoDocument, options);
}
export type ReviewCountByGodoGoodsNoQueryHookResult = ReturnType<typeof useReviewCountByGodoGoodsNoQuery>;
export type ReviewCountByGodoGoodsNoLazyQueryHookResult = ReturnType<typeof useReviewCountByGodoGoodsNoLazyQuery>;
export type ReviewCountByGodoGoodsNoQueryResult = Apollo.QueryResult<ReviewCountByGodoGoodsNoQuery, ReviewCountByGodoGoodsNoQueryVariables>;
export const SearchDocument = gql`
    query Search($keyword: String!, $languageCode: LanguageCodeEnum!) {
  search(keyword: $keyword, languageCode: $languageCode) {
    sort
    brandCode
    brandName
    products {
      score
      id
      extensions
      salePrice
      orderCount
      applications {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const FundingProductCardsDocument = gql`
    query FundingProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
  productCards(languageCode: $languageCode, productNos: $productNos) {
    ...productCards
    brand {
      ...brand
    }
    funding {
      ...funding
    }
  }
}
    ${ProductCardsFragmentDoc}
${BrandFragmentDoc}
${FundingFragmentDoc}`;

/**
 * __useFundingProductCardsQuery__
 *
 * To run a query within a React component, call `useFundingProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useFundingProductCardsQuery(baseOptions: Apollo.QueryHookOptions<FundingProductCardsQuery, FundingProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FundingProductCardsQuery, FundingProductCardsQueryVariables>(FundingProductCardsDocument, options);
}
export function useFundingProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundingProductCardsQuery, FundingProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FundingProductCardsQuery, FundingProductCardsQueryVariables>(FundingProductCardsDocument, options);
}
export type FundingProductCardsQueryHookResult = ReturnType<typeof useFundingProductCardsQuery>;
export type FundingProductCardsLazyQueryHookResult = ReturnType<typeof useFundingProductCardsLazyQuery>;
export type FundingProductCardsQueryResult = Apollo.QueryResult<FundingProductCardsQuery, FundingProductCardsQueryVariables>;
export type HotProductsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']>;
}>;


export type HotProductsQuery = { __typename?: 'Query', hotProducts: Array<number> };

export type AprilAttendanceEventMutationVariables = Exact<{
  languageCode: Scalars['String'];
}>;


export type AprilAttendanceEventMutation = { __typename?: 'Mutation'; aprilAttendanceEvent: boolean };

export type GetAprilAttendanceEventDatesQueryVariables = Exact<{ [key: string]: never }>;


export type GetAprilAttendanceEventDatesQuery = { __typename?: 'Query'; getAprilAttendanceEventDates: Array<number> };

export type IsParticipatedEventQueryVariables = Exact<{
  eventId: Scalars['Float'];
}>;


export type IsParticipatedEventQuery = { __typename?: 'Query'; isParticipatedEvent: boolean };

export type IssueEventCouponMutationVariables = Exact<{
  issueEventCouponInput: IssueEventCouponInput;
}>;


export type IssueEventCouponMutation = { __typename?: 'Mutation'; issueEventCoupon: boolean };

export type UserEventPeriodTotalPaymentQueryVariables = Exact<{
  eventId: Scalars['Float'];
}>;


export type UserEventPeriodTotalPaymentQuery = { __typename?: 'Query'; userEventPeriodTotalPayment: number };

export type GetAttendanceEventDatesQueryVariables = Exact<{
  eventId: Scalars['Float'];
}>;


export type GetAttendanceEventDatesQuery = { __typename?: 'Query', getAttendanceEventDates: Array<number> };

export type AttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float'];
  languageCode: Scalars['String'];
}>;


export type AttendanceEventMutation = { __typename?: 'Mutation', attendanceEvent: boolean };

export type BestProductsQueryVariables = Exact<{
  type: BestProductType;
  categoryCode?: InputMaybe<Scalars['String']>;
  isFreeProductInclude?: InputMaybe<Scalars['Boolean']>;
}>;


export type BestProductsQuery = { __typename?: 'Query'; bestProducts: Array<number> };

export type BrandProductsWithCategoriesQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  brandCode: Scalars['String'];
}>;


export type BrandProductsWithCategoriesQuery = { __typename?: 'Query'; brandProductsWithCategories: Array<{ __typename?: 'BrandProductsWithCategoriesOutput'; id: number; categories: Array<{ __typename?: 'Acon3dCategory'; code?: string | null }> }> };

export type CancelInquiryMutationVariables = Exact<{
  CancelInquiryInput: CancelInquiryInput;
}>;


export type CancelInquiryMutation = { __typename?: 'Mutation'; cancelInquiry: { __typename?: 'Inquiry'; id: number } };

export type CartQueryVariables = Exact<{ [key: string]: never }>;


export type CartQuery = { __typename?: 'Query'; cart: Array<{ __typename?: 'CartOutput'; productNo: number }> };

export type CheckExistMemberQueryVariables = Exact<{
  accessToken: Scalars['String'];
  snsType: SnsTypeEnum;
}>;


export type CheckExistMemberQuery = { __typename?: 'Query'; checkExistMember: boolean };

export type CheckIssuanceTempPasswordQueryVariables = Exact<{
  snsType: SnsTypeEnum;
  aconMemberId: Scalars['Int'];
}>;


export type CheckIssuanceTempPasswordQuery = { __typename?: 'Query'; checkIssuanceTempPassword: boolean };

export type CreateInquiryMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  languageCode: LanguageCodeEnum;
  type: InquiryTypeEnum;
  subType?: InputMaybe<InquirySubTypeEnum>;
  content?: InputMaybe<Scalars['String']>;
  taxes?: InputMaybe<TaxesInput>;
  changePenName?: InputMaybe<ChangePenNameInput>;
  inquiryProduct?: InputMaybe<InquiryProductInput>;
  inquiryOrders?: InputMaybe<Array<InputMaybe<InquiryOrderInput>> | InputMaybe<InquiryOrderInput>>;
  refundAccount?: InputMaybe<RefundAccountInput>;
  uploadFiles: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateInquiryMutation = { __typename?: 'Mutation'; createInquiry: { __typename?: 'Inquiry'; id: number } };

export type DeleteCartCacheMutationVariables = Exact<{ [key: string]: never }>;


export type DeleteCartCacheMutation = { __typename?: 'Mutation'; deleteCartCache: boolean };

export type DeleteMyReviewMutationVariables = Exact<{
  id: Scalars['Int'];
  languageCode: Scalars['String'];
}>;


export type DeleteMyReviewMutation = { __typename?: 'Mutation'; deleteMyReview: boolean };

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['String'];
}>;


export type DocumentQuery = { __typename?: 'Query'; document: { __typename?: 'SingleDocumentOutput'; id: string; assetId?: number | null; brandId?: number | null; language?: Language_Code | null; status?: Document_Status | null; title?: string | null; contentHead?: string | null; contentBody?: string | null; price?: number | null; mainImage?: string | null; subImage?: string | null; fileName?: string | null; filePath?: string | null; keywords?: Array<string> | null; brandNames?: Array<{ __typename?: 'SingleDocumentBrandName'; language?: Language_Code | null; name?: string | null } | null> | null; categories?: { __typename?: 'SingleDocumentCategoriesOutput'; genre?: { __typename?: 'SingleDocumentCategoryInfoOutput'; primary: string; secondary: string } | null; theme?: { __typename?: 'SingleDocumentCategoryInfoOutput'; primary: string; secondary: string } | null } | null; copyright?: { __typename?: 'DocumentCopyrightOutput'; isOriginal?: boolean | null; warehouseSources?: string | null; commercialSources?: string | null; hasRealLogo?: string | null } | null } };

export type GetAconCashListQueryVariables = Exact<{
  page: Scalars['Int'];
  type: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetAconCashListQuery = { __typename?: 'Query'; getCashList?: { __typename?: 'CashList'; totalCount: number; items: Array<{ __typename?: 'CashItem'; deposit: number; contents: string; regDate: string; afterDeposit: number; payMethod?: string | null; status?: string | null; vBank?: { __typename?: 'VBank'; bankName?: string | null; bankHolder?: string | null; bankNumber?: string | null; remittanceDate?: any | null } | null }> } | null };

export type GetApplicationsQueryVariables = Exact<{ [key: string]: never }>;


export type GetApplicationsQuery = { __typename?: 'Query'; getApplications: Array<{ __typename?: 'Application'; id: number; name: string }> };

export type GetBrandsQueryVariables = Exact<{ [key: string]: never }>;


export type GetBrandsQuery = { __typename?: 'Query'; getBrands: Array<{ __typename?: 'Brand'; id: number; name: string }> };

export type GetCartsQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type GetCartsQuery = { __typename?: 'Query'; carts: Array<{ __typename?: 'CartItem'; sno: number; goodsNo: number; point: number }> };

export type GetCategoriesV3QueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;


export type GetCategoriesV3Query = { __typename?: 'Query'; categoriesV3: Array<{ __typename?: 'CategoryOutputV2'; type: string; categories: Array<{ __typename?: 'CategoryMolecule'; primary: { __typename?: 'CategoryAtom'; code: string; name: string }; secondaries: Array<{ __typename?: 'CategoryAtom'; code: string; name: string }> }> }> };

export type GetCategoryQueryVariables = Exact<{
  godoCateCd: Scalars['String'];
}>;


export type GetCategoryQuery = { __typename?: 'Query'; getCategory?: { __typename?: 'GodoCategory'; type?: string | null; trans: Array<{ __typename?: 'GodoTrans'; lang: string; visibleResultStats: boolean; name: string; banner: { __typename?: 'GodoBanner'; image?: string | null; anchor?: string | null; anchorTarget: boolean; map?: string | null; color?: string | null } }> } | null };

export type GetCosmicDealsStatisticsQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type GetCosmicDealsStatisticsQuery = { __typename?: 'Query'; cosmicDealsStatisticsV2: { __typename?: 'cosmicDealsStatistics'; totalPrice?: string | null; totalBuyer?: string | null } };

export type GetCouponItemsQueryVariables = Exact<{
  couponNos?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCouponItemsQuery = { __typename?: 'Query'; getCouponItems?: Array<{ __typename?: 'CouponItems'; godoCouponNo?: string | null; remaining?: number | null }> | null };

export type GetCouponListQueryVariables = Exact<{
  lang: Scalars['String'];
  page: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
}>;


export type GetCouponListQuery = { __typename?: 'Query'; getCouponList: { __typename?: 'CouponList'; totalCount: number; items: Array<{ __typename?: 'CouponItem'; name: string; couponKindType: string; couponBenefit: number; couponBenefitType: string; couponMaxBenefit: number; isCouponMaxBenefit: boolean; couponApplyProductType: string; couponMinOrderPrice: number; couponMinOrderType?: string | null; isCouponApplyDuplicate: boolean; memberCouponUsable?: string | null; startDate: any; endDate: any; regDt: any; couponApplyGoods?: Array<{ __typename?: 'CouponApplyGoods'; goodsNm: string; goodsNo: string }> | null; couponApplyCategory?: Array<{ __typename?: 'CouponApplyCategory'; no: string; name: string }> | null }> } };

export type GetExchangeRateQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;


export type GetExchangeRateQuery = { __typename?: 'Query'; exchangeRate: number };

export type GetExtensionConfigsQueryVariables = Exact<{ [key: string]: never }>;


export type GetExtensionConfigsQuery = { __typename?: 'Query'; getExtensionConfigs?: Array<{ __typename?: 'ExtensionConfig'; id: number; name: string }> | null };

export type GetGodoBrandNameQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
}>;


export type GetGodoBrandNameQuery = { __typename?: 'Query'; getGodoBrandName: string };

export type GetGodoCategoriesQueryVariables = Exact<{
  lang: Scalars['String'];
  cateCds: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetGodoCategoriesQuery = { __typename?: 'Query'; getTransGodoCategoryItems: Array<{ __typename?: 'GodoDisplayCategory'; name: string; code: string }> };

export type GetGodoMyOrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetGodoMyOrdersQuery = { __typename?: 'Query'; godoMyOrders: Array<{ __typename?: 'godoOrderGoodsNo'; orderNo: string; goodsNo: string; regDt: string; orderStatus: OrderStatusEnum; isVirtualAccount: boolean }> };

export type GetInquiriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetInquiriesQuery = { __typename?: 'Query'; inquiries: Array<{ __typename?: 'Inquiry'; id: number; type: InquiryTypeEnum; content: string; status: InquiryStatusEnum; created?: any | null; answer?: { __typename?: 'InquiryAnswer'; answer: string } | null }> };

export type GetInquiryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInquiryQuery = { __typename?: 'Query'; inquiry: { __typename?: 'Inquiry'; id: number; godoMemNo?: string | null; godoUserId?: string | null; languageCode: LanguageCodeEnum; type: InquiryTypeEnum; status: InquiryStatusEnum; subType?: InquirySubTypeEnum | null; content: string; created?: any | null; updated?: any | null; inquiryAcon3dProduct?: { __typename?: 'Acon3dProduct'; id: number; title: string } | null; inquiryOrders?: Array<{ __typename?: 'InquiryOrder'; godoOrderNo: string; godoGoodsDetails: Array<{ __typename?: 'InquiryOrderGoodsDetail'; godoGoodsNo: string; orderStatus: number }> } | null> | null; answer?: { __typename?: 'InquiryAnswer'; answer: string; created?: any | null } | null; uploadFiles: Array<{ __typename?: 'UploadFile'; azureStoragePath: string; fileName: string }> } };

export type GetInquiryListQueryVariables = Exact<{
  type?: InputMaybe<InquiryTypeEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetInquiryListQuery = { __typename?: 'Query'; inquiryCount: number; inquiries: Array<{ __typename?: 'Inquiry'; id: number; type: InquiryTypeEnum; content: string; status: InquiryStatusEnum; created?: any | null; answer?: { __typename?: 'InquiryAnswer'; answer: string } | null }> };

export type GetIsFirstDownloadQueryVariables = Exact<{
  goodsNo: Scalars['Int'];
  orderNo: Scalars['String'];
}>;


export type GetIsFirstDownloadQuery = { __typename?: 'Query'; isFirstDownload: boolean };

export type GetMyOrdersQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;


export type GetMyOrdersQuery = { __typename?: 'Query'; getMyOrders: Array<{ __typename?: 'MyOrderReturn'; godoOrderNo: number; godoGoodsNo: number; price: number; orderGoodsStatus: OrderDisplayStatusEnum; regDt: string; reviewId?: number | null; product?: { __typename?: 'Acon3dProduct'; title: string; mainImageUrl: string; subImageUrl: string; extensions: Array<string>; salePrice: number; price: number; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null }; funding?: { __typename?: 'Acon3dFunding'; deliveryDate: any; endDate: any; goalAmount: number; soldAmount?: number | null; soldVolume?: number | null; startDate: any; status: FundingStatus } | null } | null; downloadCountInfo?: { __typename?: 'DownloadCountInfo'; limit: number; remained: number } | null }> };

export type GetMyReviewQueryVariables = Exact<{
  getMyReviewId: Scalars['Int'];
}>;


export type GetMyReviewQuery = { __typename?: 'Query'; getMyReview: { __typename?: 'Review'; id: number; rating: number; imageUrl?: string | null; reviewContentsItems: Array<{ __typename?: 'ReviewContents'; title: string; contents: string; languageCode: LanguageCodeEnum; isOriginal: boolean }> } };

export type GetMyReviewsByGodoGoodsNoQueryVariables = Exact<{
  godoGoodsNo: Scalars['String'];
}>;


export type GetMyReviewsByGodoGoodsNoQuery = { __typename?: 'Query'; getMyReviewsByGodoGoodsNo?: Array<{ __typename?: 'Review'; id: number }> | null };

export type GetMyReviewsQueryVariables = Exact<{ [key: string]: never }>;


export type GetMyReviewsQuery = { __typename?: 'Query'; getMyReviews?: Array<{ __typename?: 'Review'; godoOrderNo?: string | null; godoGoodsNo: number; created: any }> | null };

export type GetMypageUserDataQueryVariables = Exact<{ [key: string]: never }>;


export type GetMypageUserDataQuery = { __typename?: 'Query'; hasNewPoint: boolean; hasNewCoupon: boolean; inquiryCount: number };

export type GetOrderNoWithoutReviewQueryVariables = Exact<{
  godoGoodsNo: Scalars['String'];
}>;


export type GetOrderNoWithoutReviewQuery = { __typename?: 'Query'; getOrderNoWithoutReview?: string | null };

export type GetOrderViewV2QueryVariables = Exact<{
  lang: Scalars['String'];
  orderNo: Scalars['Float'];
}>;


export type GetOrderViewV2Query = { __typename?: 'Query'; orderViewV2: { __typename?: 'OrderViewDto'; orderDate: any; settleReceipt: string; settleName: string; settleMethod: string; status: string; bank: string; bankAccount: string; bankSender: string; bankHolder: string; pgFailReason: string; settlePrice: number; totalGoodsDcPrice: number; totalCouponDeliveryDcPrice: number; totalGoodsPrice: number; totalCouponGoodsDcPrice: number; totalCouponOrderDcPrice: number; useDeposit: number; useMileage: number; receiptFl: string; pgSettleCd: Array<string>; pgSettleNm: Array<string>; settleKind: string; cash?: string | null; tax?: string | null; receipt?: { __typename?: 'OrderViewReceiptDto'; cashFl?: string | null; limitDateFl?: string | null; particularFl?: string | null; periodDay?: string | null; periodFl?: string | null; taxFl?: string | null; taxInvoiceLimitDate?: string | null } | null; user: { __typename?: 'OrderViewUserInfoDto'; cellPhone: string; email: string; name: string }; license?: { __typename?: 'OrderLicenseModel'; orderNo: string; type: string; names?: Array<{ __typename?: 'OrderLicenseNameModel'; name: string }> | null; projects?: Array<{ __typename?: 'OrderLicenseProjectModel'; name: string; goods?: Array<{ __typename?: 'OrderLicenseProjectGoodsModel'; goodsNo: number }> | null }> | null } | null; orderProducts: Array<{ __typename?: 'OrderViewProductDto'; goodsNo: number; price: number; salePrice: number; status: string; statusStr: string; imageUrl: string; product?: { __typename?: 'Acon3dProduct'; title: string; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; id?: number | null; name?: string | null; code?: string | null } } | null }>; order?: { __typename?: 'OrderDto'; id: string; status?: string | null; payment?: { __typename?: 'OrderPaymentDto'; payMethod: string; receiptUrl?: string | null } | null } | null } };

export type GetOtherProductListQueryVariables = Exact<{
  godoGoodsNo: Scalars['Int'];
  lang: Scalars['String'];
  isCount: Scalars['Boolean'];
}>;


export type GetOtherProductListQuery = { __typename?: 'Query'; otherProductList: { __typename?: 'RelateProductListOutput'; cateCd?: string | null; count?: number | null; items?: Array<{ __typename?: 'RelateProductListItem'; godoGoodsNo: number; realPrice: string; price: string; imageUrl: string; title: string; brand: string; brandCd: string }> | null } };

export type PhotoReviewQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  languageCode: LanguageCodeEnum;
  godoCateCd?: InputMaybe<Scalars['Int']>;
}>;


export type PhotoReviewQuery = { __typename?: 'Query'; getPhotoReview: { __typename?: 'PaginatedDetailReview'; nextCursor?: number | null; prevCursor?: number | null; nth?: number | null; node?: { __typename?: 'Review'; imageUrl?: string | null; rating: number; godoUserId?: string | null; created: any; product?: { __typename?: 'Acon3dProduct'; id: number; title: string; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null } } | null; reviewContentsItems: Array<{ __typename?: 'ReviewContents'; isOriginal: boolean; languageCode: LanguageCodeEnum; title: string; contents: string }> } | null } };

export type GetPhotoReviewTotalCountQueryVariables = Exact<{
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
}>;


export type GetPhotoReviewTotalCountQuery = { __typename?: 'Query'; getPhotoReviewTotalCount: number };

export type PhotoReviewsQueryVariables = Exact<{
  isBestReview?: InputMaybe<Scalars['Boolean']>;
  take?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['Int']>;
  godoCateCd?: InputMaybe<Scalars['Int']>;
  languageCode?: InputMaybe<Scalars['String']>;
}>;


export type PhotoReviewsQuery = { __typename?: 'Query'; getPhotoReviews: { __typename?: 'PaginatedReview'; nodes?: Array<{ __typename?: 'Review'; id: number; godoUserId?: string | null; rating: number; imageUrl?: string | null; created: any; reviewContentsItems: Array<{ __typename?: 'ReviewContents'; title: string; contents: string; isOriginal: boolean; languageCode: LanguageCodeEnum }> }> | null; edge?: { __typename?: 'ReviewEdge'; cursor?: number | null; node?: { __typename?: 'Review'; id: number } | null } | null } };

export type GetPopupsHereQueryVariables = Exact<{
  url: Scalars['String'];
  origin: Scalars['String'];
}>;


export type GetPopupsHereQuery = { __typename?: 'Query'; getPopupsHere: Array<{ __typename?: 'PopupV2'; id: number; title: string; text?: string | null; subText?: string | null; buttonText?: string | null; connectedUrl?: string | null; image?: { __typename?: 'UploadFile'; azureStoragePath: string } | null }> };

export type GetReceivePointByReviewQueryVariables = Exact<{
  goodsNo: Scalars['Float'];
  languageCode: Scalars['String'];
  isPhotoReview: Scalars['Boolean'];
}>;


export type GetReceivePointByReviewQuery = { __typename?: 'Query'; getReceivePointByReview?: number | null };

export type GetRecentOrdersV2QueryVariables = Exact<{
  langCode: Scalars['String'];
}>;


export type GetRecentOrdersV2Query = { __typename?: 'Query'; recentOrdersV2: Array<{ __typename?: 'RecentOrder'; orderNo: number; orderedAt: any; orderedProducts: Array<{ __typename?: 'RecentOrderedProduct'; sno: number; title: string; godoGoodsNo: number; price: number; status: string; statusString: string; hasWrittenReview: boolean; reviewSno?: number | null; reviewId?: number | null; product?: { __typename?: 'Acon3dProduct'; title: string; price: number; salePrice: number; extensions: Array<string>; mainImageUrl: string; subImageUrl: string; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null } } | null }> }> };

export type GetSimilarProductListQueryVariables = Exact<{
  godoGoodsNo: Scalars['Int'];
  lang: Scalars['String'];
  isCount: Scalars['Boolean'];
}>;


export type GetSimilarProductListQuery = { __typename?: 'Query'; similarProductList: { __typename?: 'RelateProductListOutput'; cateCd?: string | null; count?: number | null; items?: Array<{ __typename?: 'RelateProductListItem'; godoGoodsNo: number; title: string; realPrice: string; price: string; imageUrl: string; brand: string; brandCd: string }> | null } };

export type GetTogetherProductListQueryVariables = Exact<{
  lang: Scalars['String'];
  godoGoodsNo: Scalars['Int'];
  isCount: Scalars['Boolean'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetTogetherProductListQuery = { __typename?: 'Query'; togetherProductListRecommendation: { __typename?: 'ProductListReturn'; count?: number | null; items?: Array<{ __typename?: 'ProductViewCardList'; godoGoodsNo: number; title: string; realPrice: string; price: string; imageUrl: string; brand: string; brandCd: string }> | null } };

export type GetTransGodoCategoryChildItemsQueryVariables = Exact<{
  lang: Scalars['String'];
  cateCds: Array<Scalars['String']> | Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
}>;


export type GetTransGodoCategoryChildItemsQuery = { __typename?: 'Query'; getTransGodoCategoryChildItems: Array<{ __typename?: 'GodoDisplayCategory'; name: string; code: string; isLink: boolean; isVisible: boolean }> };

export type GetUserApprovalDtQueryVariables = Exact<{ [key: string]: never }>;


export type GetUserApprovalDtQuery = { __typename?: 'Query'; getUserApprovalDt: any };

export type IsCouponNotificationQueryVariables = Exact<{ [key: string]: never }>;


export type IsCouponNotificationQuery = { __typename?: 'Query'; isCouponNotification: boolean };

export type MainProductListV2QueryVariables = Exact<{
  sno: Scalars['Int'];
  lang: Scalars['String'];
}>;


export type MainProductListV2Query = { __typename?: 'Query'; mainProductListV2: { __typename?: 'MainProductListV2Return'; sno: string; title: string; source: string; productList: Array<number> } };

export type FundingProductNosQueryVariables = Exact<{ [key: string]: never }>;


export type FundingProductNosQuery = { __typename?: 'Query'; fundingProductNos: Array<number> };

export type BrandFragment = { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };

export type CategoriesFragment = { __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null };

export type ModelConfigsFragment = { __typename?: 'Acon3dModelConfig'; id?: number | null; name?: string | null };

export type BannersFragment = { __typename?: 'Acon3dBanner'; id?: number | null; text?: string | null; href?: string | null; imageUrl?: string | null; type?: string | null; created?: any | null };

export type PromotionsBadgeFragment = { __typename?: 'Acon3dPromotionBadge'; id?: number | null; type?: string | null; languageCode?: LanguageCodeEnum | null; content?: string | null };

export type PromotionsFragment = { __typename?: 'Acon3dPromotion'; id: number; startDate?: any | null; currentRound?: number | null; remainingDays?: number | null; rounds?: Array<{ __typename?: 'Acon3dPromotionRound'; roundNo?: number | null; salePrice?: number | null; startDate?: any | null; endDate?: any | null }> | null; badges?: Array<{ __typename?: 'Acon3dPromotionBadge'; id?: number | null; type?: string | null; languageCode?: LanguageCodeEnum | null; content?: string | null }> | null };

export type LinkedProductsFragment = { __typename?: 'Acon3dLinkedProduct'; id: number; extensions: Array<string> };

export type PackagesFragment = { __typename?: 'Acon3dPackage'; type: string; parent?: { __typename?: 'Acon3dProduct'; id: number } | null; children: Array<{ __typename?: 'Acon3dProduct'; id: number }> };

export type FundingFragment = { __typename?: 'Acon3dFunding'; deliveryDate: any; endDate: any; goalAmount: number; soldAmount?: number | null; soldVolume?: number | null; startDate: any; status: FundingStatus };

export type ProductCardsFragment = { __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string> };

export type ProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ProductCardsQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string> } | null> };

export type ProductCardsWithBrandQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ProductCardsWithBrandQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null } } | null> };

export type ProductCardsWithCategoriesQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ProductCardsWithCategoriesQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; categories?: Array<{ __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null }> | null } | null> };

export type ProductCardsWithBrandAndCategoriesQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ProductCardsWithBrandAndCategoriesQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null }; categories?: Array<{ __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null }> | null } | null> };

export type ProductCardsAllQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  isExperimental?: InputMaybe<Scalars['Boolean']>;
}>;


export type ProductCardsAllQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; content?: string | null; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null }; applications?: Array<{ __typename?: 'Application'; name: string }> | null; linkedProducts?: Array<{ __typename?: 'Acon3dLinkedProduct'; id: number; extensions: Array<string> }> | null; categories?: Array<{ __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null }> | null; modelConfigs?: Array<{ __typename?: 'Acon3dModelConfig'; id?: number | null; name?: string | null }> | null; banners?: Array<{ __typename?: 'Acon3dBanner'; id?: number | null; text?: string | null; href?: string | null; imageUrl?: string | null; type?: string | null; created?: any | null }> | null; packages?: Array<{ __typename?: 'Acon3dPackage'; type: string; parent?: { __typename?: 'Acon3dProduct'; id: number } | null; children: Array<{ __typename?: 'Acon3dProduct'; id: number }> } | null> | null; funding?: { __typename?: 'Acon3dFunding'; deliveryDate: any; endDate: any; goalAmount: number; soldAmount?: number | null; soldVolume?: number | null; startDate: any; status: FundingStatus } | null } | null> };

export type ProductCardsGa4EcommerceQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ProductCardsGa4EcommerceQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; title: string; price: number; salePrice: number; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null } } | null> };

export type DetailViewProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DetailViewProductCardsQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; detailImageUrl?: string | null; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null }; applications?: Array<{ __typename?: 'Application'; name: string }> | null; linkedProducts?: Array<{ __typename?: 'Acon3dLinkedProduct'; id: number; extensions: Array<string> }> | null; packages?: Array<{ __typename?: 'Acon3dPackage'; type: string; parent?: { __typename?: 'Acon3dProduct'; id: number } | null; children: Array<{ __typename?: 'Acon3dProduct'; id: number }> } | null> | null; funding?: { __typename?: 'Acon3dFunding'; deliveryDate: any; endDate: any; goalAmount: number; soldAmount?: number | null; soldVolume?: number | null; startDate: any; status: FundingStatus } | null } | null> };

export type OrderProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type OrderProductCardsQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; hasOrdered?: boolean | null; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null } } | null> };

export type PromotionProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type PromotionProductCardsQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null }; promotions?: Array<{ __typename?: 'Acon3dPromotion'; id: number; startDate?: any | null; currentRound?: number | null; remainingDays?: number | null; badges?: Array<{ __typename?: 'Acon3dPromotionBadge'; id?: number | null; type?: string | null; languageCode?: LanguageCodeEnum | null; content?: string | null }> | null; rounds?: Array<{ __typename?: 'Acon3dPromotionRound'; roundNo?: number | null; salePrice?: number | null; startDate?: any | null; endDate?: any | null }> | null }> | null } | null> };

export type ProductCardsSummaryQueryVariables = Exact<{
  sort?: InputMaybe<ProductListSort>;
  categoryCode?: InputMaybe<Scalars['String']>;
  brandCode?: InputMaybe<Scalars['String']>;
  isNew?: InputMaybe<Scalars['Boolean']>;
}>;


export type ProductCardsSummaryQuery = { __typename?: 'Query'; productCardsSummary: { __typename?: 'ProductCardsSummary'; sort?: ProductListSort | null; products: Array<{ __typename?: 'Acon3dProduct'; id: number; extensions: Array<string>; salePrice: number; orderCount: number; applications?: Array<{ __typename?: 'Application'; id: number; name: string }> | null }> } };

export type OngoingCosmicPromotionProductNosQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;


export type OngoingCosmicPromotionProductNosQuery = { __typename?: 'Query'; ongoingCosmicPromotionProductNos: Array<number> };

export type OngoingCometPromotionProductNosQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;


export type OngoingCometPromotionProductNosQuery = { __typename?: 'Query'; ongoingCometPromotionProductNos: Array<number> };

export type RegisterSnsMemberMutationVariables = Exact<{
  input: AconSnsMemberInput;
}>;


export type RegisterSnsMemberMutation = { __typename?: 'Mutation'; registerSnsMember: boolean };

export type ReviewCountByGodoGoodsNoQueryVariables = Exact<{
  godoGoodsNo: Scalars['String'];
}>;


export type ReviewCountByGodoGoodsNoQuery = { __typename?: 'Query'; reviewCountByGodoGoodsNo: number };

export type SearchQueryVariables = Exact<{
  keyword: Scalars['String'];
  languageCode: LanguageCodeEnum;
}>;


export type SearchQuery = { __typename?: 'Query'; search: { __typename?: 'SearchSummary'; sort?: ProductListSort | null; brandCode?: string | null; brandName?: string | null; products: Array<{ __typename?: 'Acon3dProduct'; score?: number | null; id: number; extensions: Array<string>; salePrice: number; orderCount: number; applications?: Array<{ __typename?: 'Application'; id: number; name: string }> | null }> } };

export type FundingProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type FundingProductCardsQuery = { __typename?: 'Query'; productCards: Array<{ __typename?: 'Acon3dProduct'; id: number; languageCode: LanguageCodeEnum; title: string; price: number; salePrice: number; onSale: boolean; onDisplay: boolean; promotionEndDate?: any | null; mainImageUrl: string; subImageUrl: string; isAconOnly: boolean; orderCount: number; viewCount: number; reviewCount: number; created: any; updated?: any | null; badgeNames: Array<string | null>; extensions: Array<string>; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null }; funding?: { __typename?: 'Acon3dFunding'; deliveryDate: any; endDate: any; goalAmount: number; soldAmount?: number | null; soldVolume?: number | null; startDate: any; status: FundingStatus } | null } | null> };
