import React from 'react';
import { IGridItemContainerProps } from '@components/layout/Grid/Grid.types';
import { GridContainer, GridItemContainer } from './Grid.styles';

interface IGridItemContainer extends IGridItemContainerProps{
  children: React.ReactNode;
  onClick?: any;
  className?: string;
}

const Grid = ({ children, ...props }): React.ReactElement => {
  return <GridContainer {...props}>{children}</GridContainer>;
};

Grid.Item = ({ children, ...props }: IGridItemContainer): React.ReactElement<IGridItemContainer> => {
  return <GridItemContainer {...props}>{children}</GridItemContainer>;
};

export default Grid;
