import React, { ReactNode } from 'react';
import { Size } from '@components/type';
import { CustomText } from '@components/ui';
import { Wrapper } from './styled';

type Props = {
  children: ReactNode;
  size?: Size;
  isShadow?: boolean;
  className?: string;
  background?: string;
  border?: string;
};

export default function DefaultBadge({ size = 'medium', className, isShadow = true, children, background, border }: Props) {
  return (
    <Wrapper size={size} isShadow={isShadow} className={className} background={background} border={border}>
      {children}
    </Wrapper>
  );
}
